<template>
  <IonApp>
    <Logout id="content" v-if="isLogoutScreen" />
    <IonRouterOutlet id="content" v-else />
    <Tabs />
  </IonApp>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent } from 'vue'
import {
  addBackgroundStyling
} from '@/dom/background'
import Tabs from '@/views/Tabs.vue'
import Logout from '@/views/Logout.vue'
import { isLogoutScreen } from './router/state'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Tabs,
    Logout
  },
  mounted() {
    addBackgroundStyling()
  },
  setup() {
    return {
      isLogoutScreen
    }
  }
})
</script>
