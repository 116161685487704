<template>
  <PlayIcon ref="playIcon" :playMode="isAudioPlaying" @click="togglePlaying" />
</template>
<script lang="ts">
import { monitorAsync } from '@/logic/patterns/async-vue-ref'
import { combine, debounce } from '@/logic/patterns/functions'
import { refAssign } from '@/logic/patterns/vue'
import { getAudioPlayer, getAudioPlayerFromFile } from '@/services/media.service'
import { Flashcard } from '@/types/models/flashcard.model'
import { Possible } from '@/types/patterns'
import { defined } from 'big-m/dist/types/utils'
import {defineComponent, Ref, ref, watch, onUnmounted, onMounted, ComponentPublicInstance, onBeforeUnmount, onUpdated} from 'vue'
import PlayIcon from './PlayIcon.vue'
import { sideEffect, Deferred, defer } from '@/logic/patterns/async'
import { last } from '@/logic/patterns/iterables'
import { none, some } from 'fp-ts/lib/Option'
import { getSome } from '@/logic/patterns/option'
import { getMainContentScrollableElement, isWithinViewport } from '@/dom/scroll'
import { listenUntilCancel } from '@/dom/events'

let globalAudioLock: Possible<Deferred<void>> = undefined
const getGlobalAudioLock = () => {
  if (globalAudioLock) {
    return none
  } else {
    globalAudioLock = defer()
    globalAudioLock.promise = globalAudioLock.promise.then(() => globalAudioLock = undefined)
    return some(globalAudioLock)
  }
}

const VISIBILITY_DURATION_MS = 1500
const VISIBILITY_CHECK_DEBOUNCE = 50
function beginVisibilityCountdown(el: HTMLElement, visibilityDurationElapsed: () => void) {
  const scrollableElement = getMainContentScrollableElement()
  if (scrollableElement) {
    let cancelableTimeout: number | null = null
    let cleanup: Possible<() => void> = undefined

    const checkVisibility = debounce(
      () => {
        const elementIsInViewport = isWithinViewport(el)
        
        if (elementIsInViewport && cancelableTimeout === null) {
          cancelableTimeout = setTimeout(() => {
            visibilityDurationElapsed()
            cleanup && cleanup()
          }, VISIBILITY_DURATION_MS) as any as number
        } else if (!elementIsInViewport && cancelableTimeout !== null) {
          clearTimeout(cancelableTimeout)
          cancelableTimeout = null
        }
      },
      VISIBILITY_CHECK_DEBOUNCE
    )

    checkVisibility()

    cleanup = listenUntilCancel(
      scrollableElement,
      'scroll',
      checkVisibility
    )

    return cleanup
  } else {
    console.error('Could not listen for scroll events, could not find main content scrollable element')
  }
}

export default defineComponent({
  name: 'PlayAudio',
  components: { PlayIcon },
  props: {
    audioFile: [Number, Object] as any as () => NonNullable<Flashcard["audio"]>,
    rawFile: File
  },
  setup(props, ctx) {
    const getAudioPlayerNormalized = async () => {
      if (props.audioFile) {
        return getAudioPlayer(props.audioFile)
      } else if (props.rawFile) {
        return getAudioPlayerFromFile(props.rawFile)
      } else {
        throw new Error("No suitable file prop, needed 'audioFile' or 'rawFile'")
      }
    }

    const isAudioPlaying = ref(false)
    const pauseFn: Ref<Possible<() => void>> = ref(undefined)
    
    const waitToFetchPromise = defer()
    const waitToFetchPromiseResolved = ref(false)
    waitToFetchPromise.promise.then(() => waitToFetchPromiseResolved.value = true)

    const playIcon = ref<ComponentPublicInstance>(null as any)
    let cleanup: Possible<() => void> = undefined
    onMounted(
      () => cleanup = beginVisibilityCountdown(
        playIcon.value.$el,
        waitToFetchPromise.resolve
      )
    )

    onUpdated(
      () => {
        if (waitToFetchPromiseResolved.value && isWithinViewport(playIcon.value.$el)) {
          cleanup && cleanup()
          waitToFetchPromise.resolve()
        }
      }
    )

    onBeforeUnmount(
      () => cleanup && cleanup()
    )

    const audioPlayerMonitored = monitorAsync(
      sideEffect(
        waitToFetchPromise.promise.then(getAudioPlayerNormalized),
        obj => {
          if ("path" in obj) {
            const filename = last(obj.path.split("/"))
            ctx.emit("filename", filename)
          }
        }
      )
    )

    const stopPlayingIfPlaying = () => {
      isAudioPlaying.value = false
      pauseFn.value && pauseFn.value()
      pauseFn.value = undefined
      globalAudioLock?.resolve()
    }

    watch(
      props,
      () => sideEffect(
        getAudioPlayerNormalized(),
        obj => {
          const filename = last(obj.path.split("/"))
          ctx.emit("filename", filename)
        }
      )
    )

    const togglePlaying = async () => {
      if (isAudioPlaying.value) {
        stopPlayingIfPlaying()
      } else {
        if (globalAudioLock) {
          globalAudioLock.resolve()
          await globalAudioLock.promise
        }

        const lock = getSome(
        getGlobalAudioLock(),
          () => 'Expected global lock to be freed'
        )
        lock.promise.then(stopPlayingIfPlaying)

        waitToFetchPromise.resolve()
        isAudioPlaying.value = true
        await audioPlayerMonitored.promise
        const player = defined(audioPlayerMonitored.result)
        const {
          playBegun,
          playEnded,
          pause
        } = player.play()

        const playerUnchanged = () => player === audioPlayerMonitored.result

        pauseFn.value = () => playBegun.then(() => void (playerUnchanged() && pause()))
        playEnded.then(() => playerUnchanged() && combine(
          refAssign(isAudioPlaying, false),
          refAssign(pauseFn, undefined)
        )())
      }
    }

    onUnmounted(stopPlayingIfPlaying)

    return {
      isAudioPlaying,
      togglePlaying,
      playIcon
    }
  }
})
</script>
