<template>
  <IonToggle aria-label="$props['aria-label']" v-model="innerValue" ref="refToggle" />
</template>
<script lang="ts">
  import {
    defineComponent, Ref, ref, watch
  } from 'vue'
  import {
    IonToggle
  } from '@ionic/vue'
import { requiredPrimitive, requiredType } from '@/logic/patterns/vue'

  export default defineComponent({
    props: {
      'aria-label': requiredPrimitive(String),
      checked: requiredPrimitive(Boolean),
      toggle: requiredType<() => void>(Function)
    },
    components: {
      IonToggle
    },
    setup(props) {
      const refToggle: Ref<any> = ref(null as any)
      // The card's value needs to be delegated to a local ref, and that local ref
      // kept in sync with the card's value because of some internal detail of IonToggle
      // that requires it to manage its own boolean. So we give it a boolean it can manage
      // and feel like it is in control.
      const innerValue = ref(props.checked)
      watch(
        () => props.checked,
        val => innerValue.value === val || refToggle.value.$el.click()
      )
      watch(
        innerValue,
        val => val === props.checked || props.toggle()
      )

      return { innerValue, refToggle }
    }
  })
</script>
