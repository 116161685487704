export function listenOnce<EventType extends string, EventObject extends Event, T extends {
  addEventListener: (event: EventType, fn: (event: EventObject) => void) => void,
  removeEventListener: (event: EventType, fn: (event: EventObject) => void) => void,
}>(
  source: T,
  event: EventType,
  listener: (event: EventObject) => void
) {
  const onEventTriggered = (e: EventObject) => {
    listener(e)
    source.removeEventListener(event, onEventTriggered)
  }

  source.addEventListener(event, onEventTriggered)

  return () => source.removeEventListener(event, onEventTriggered)
}

export function listenUntilCancel<EventType extends string, EventObject extends Event, T extends {
  addEventListener: (event: EventType, fn: (event: EventObject) => void) => void,
  removeEventListener: (event: EventType, fn: (event: EventObject) => void) => void,
}>(
  source: T,
  event: EventType,
  listener: (event: EventObject) => void
) {
  source.addEventListener(event, listener)

  return () => source.removeEventListener(event, listener)
}

export function promisedEvent<EventType extends string, EventObject extends Event, T extends {
  addEventListener: (event: EventType, fn: (event: EventObject) => void) => void,
  removeEventListener: (event: EventType, fn: (event: EventObject) => void) => void,
}>(
  source: T,
  event: EventType
) {
  return new Promise<EventObject>(
    (resolve) => {
      listenOnce<EventType, EventObject, T>(
        source,
        event,
        resolve
      )
    }
  )
}
