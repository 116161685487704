import { queryMatch } from "@/logic/language"
import { ImageDefinition } from "./image.model"

export interface Deck {
  id: number,
  ordinality?: number,
  author: string,
  authorName: string,
  title: string,
  public: boolean,
  tags?: string[],
  likes: Record<string, true>,
  cards?: number[],
  img: ImageDefinition,
}

export function queryMatchDeck(filter: string) {
  return (deck: Deck) => deck.authorName === filter || queryMatch(
    filter,
    [
      deck.title,
      ...(deck.tags || [])
    ]
  )
}

export function hasCards(d: Deck) {
  return !!(d.cards && d.cards.length)
}

// Deck that has not been saved yet.
export type NormalizedDeck = {
  likes: Record<string, true>,
  title: string,
  tags: string[],
  cards: number[],
  public: boolean,
  img: ImageDefinition,
}
