import { Possible } from "@/types/patterns"
import { foldingGet } from "big-m"
import {
  none,
  Option,
  some
} from "fp-ts/Option"
import { identity } from "./functions"

export function optionOnMap<K, T>(map: Map<K, T>, key: K): Option<T> {
  if (map.has(key)) {
    return some(map.get(key) as T)
  } else {
    return none
  }
}

export const reconcileAddToSet = <T>() => (colliding: Possible<Set<T>>, incoming: T) => {
  if (colliding === undefined) {
    return new Set([incoming])
  } else {
    colliding.add(incoming)
    return colliding
  }
}

export function consume<K, V>(map: Map<K, V>, key: K): Option<V>
export function consume<K, V, O>(map: Map<K, V>, key: K, fn: (v: V, k: K) => O): Option<O>
export function consume<K, V, O>(map: Map<K, V>, key: K, fn?: (v: V, k: K) => O) {
  return foldingGet(
    map,
    key,
    (val) => {
      const ret = (fn || identity)(val, key)
      map.delete(key)
      return some(ret)
    },
    () => none
  )
}
