import { Either, Right, Left } from "fp-ts/lib/Either"
import { ErrorBuilder, buildError } from "./errors"

export type RightOf<T> = T extends Right<infer R> ? R : never
export type LeftOf<T> = T extends Left<infer R> ? R : never

export function getRight<E, A>(either: Either<E, A>, errorBuilder: ErrorBuilder<[E]> = "Expected right") {
  if (either._tag === "Right") {
    return either.right
  } else {
    throw buildError(errorBuilder, either.left)
  }
}

export function getLeft<E, A>(either: Either<E, A>, errorBuilder: ErrorBuilder<[A]> = "Expected left") {
  if (either._tag === "Left") {
    return either.left
  } else {
    throw buildError(errorBuilder, either.right)
  }
}
