export function canonize(kaskaText: string) {
  return kaskaText.normalize("NFD").replace(/[\u0300-\u036fʼ]/g, "")
}

export function queryMatch(filter: string, targets: string[]) {
  const filterCanonized = canonize(filter).toLowerCase()
  const targetsCanonized = targets.map(
    t => canonize(t).toLowerCase()
  )

  return !!targetsCanonized.find(t => t.includes(filterCanonized))
}

const praises: [string, string][] = [
  ["Łą̄́ ejénʼīn", "You are skillful!"],
  ["Łą̄́ ejéjénʼīn lî!", "You are doing amazing work!"],
  ["Łą̄́ egūtīe!", "Very good!"],
  ["Łą̄́ gūnyān", "You are smart!"]
]

const encouragements: [string, string][] = [
  ["Síkʼādéʼ entin!", "Keep going!"],
  ["Dūłą̄́ eméʼ endē!", "Donʼt give up!"],
  ["Dūłą̄́ meghąh dedenzets.", "Donʼt worry about it."],
  ["Deyḗnzen!", "Be kind to yourself!"]
]

function choose<T>(arr: T[]) {
  return arr[Math.floor(Math.random() * arr.length)]
}

export function getPraise() {
  return choose(praises)
}

export function getEncouragement() {
  return choose(encouragements)
}
