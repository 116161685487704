<template>
  <h3><strong>Reordering flashcards in deck</strong> <IonIcon :icon="arrowBackCircleOutline" class="quick-back" @click="dismiss" alt="back" /></h3>
  <br />
  <div>
    <form>
      <IonList radio-group>
        <IonReorderGroup :disabled="false" @ionItemReorder="handleMoveCard">
          <div v-for="card of viewableCards" :key="card.id">
            <IonReorder>
              <IonItem>
                <IonLabel>{{card.kaska}} - {{card.english}}</IonLabel>
              </IonItem>
            </IonReorder>
          </div>
        </IonReorderGroup>
      </IonList>
    </form>
    <IonButton class="reorder-image--return" color="secondary" fill="outline" @click="dismiss()">
      <IonIcon :icon="returnDownBackOutline" />&nbsp;Done
    </IonButton>
  </div>
</template>
<script lang='ts'>
import { defineComponent, ref } from 'vue'
import {
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonList,
  IonReorderGroup,
  IonReorder
} from '@ionic/vue'
import { CardFetcher } from '@/services/db.service'
import { pickAndEnrolToDispose, dbJoinArray, IdOnly } from '@/logic/firebase/wrapper'
import { Flashcard } from '@/types/models/flashcard.model'
import {
  returnDownBackOutline,
  arrowBackCircleOutline
} from 'ionicons/icons'
import { disposeArray } from '@/logic/patterns/vue'
import { pipe } from 'fp-ts/lib/function'

  const getText = (filter: "All" | "Mine" | "Liked" | "LFNLD") => {
    if (filter === "All") {
      return "All cards"
    } else if (filter === "Mine") {
      return "Only my cards"
    } else if (filter === "Liked") {
      return "Only cards I liked"
    } else {
      return "LFN Language Department cards"
    }
  }

export default defineComponent({
  components: {
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonList,
    IonReorderGroup,
    IonReorder
  },
  props: {
    cards: {
      type: Array as () => number[],
      required: true
    },
    moveCard: {
      type: Function as any as () => ((from: number, to: number) => undefined),
      required: true
    },
    dismiss: {
      type: Function as any as () => (() => undefined),
      required: true
    }
  },
  setup(props) {
    const dispose = disposeArray()
    const receivedArrayFn = pickAndEnrolToDispose<IdOnly<Flashcard>>(dispose)
    const cardFetcher = CardFetcher()

    const cardInput = ref("")
    const filterOptions = ["All" as const, "Mine" as const, "Liked" as const, "LFNLD" as const].map(
      filter => ({
        value: filter,
        text: getText(filter)
      })
    )

    const viewableCards = pipe(
      dbJoinArray(
        ref(props.cards),
        cardFetcher.observeIndividualCard
      ),
      receivedArrayFn
    )

    const handleMoveCard = (event: { detail: {
      from: number,
      to: number,
      complete: () => void,
    }, }) => {
      const {from, to, complete} = event.detail
      props.moveCard(from, to)
      complete()
    }

    return {
      handleMoveCard,
      filterOptions,
      viewableCards,
      cardInput,
      returnDownBackOutline,
      arrowBackCircleOutline
    }
  }
})
</script>
<style scoped>
  ion-label {
    padding-left: 0.5em;
  }

  ion-button ion-icon {
    color: inherit;
  }

  ion-icon {
    cursor: pointer;
  }

  ion-icon.quick-back {
    vertical-align: middle;
    position: relative;
    top: -2px;
    background-color: #488aff;
    border-radius: 100%;
    color: white;
  }

  .make-a-flashcard {
    margin-bottom: 1em;
  }
</style>