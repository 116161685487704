export function keyCount(o: Record<string, any>) {
  let i = 0
  // eslint-disable-next-line
  for (const _ in o) {
    i++
  }
  return i
}

export function isEmpty(o: Record<string, any>) {
  // eslint-disable-next-line
  for (const _ in o) {
    return true
  }
  return false
}

export function omit<T extends Record<string, any>, K extends string>(obj: T, keys: K[]): Omit<T, K> {
  const copiedObj = { ...obj }
  for (const key of keys) {
    delete copiedObj[key]
  }
  return copiedObj
}

export function* valuesIterable<T>(
  object: Record<string, T>
) {
  for (const key in object) {
    yield object[key]
  }
}

export function* entriesIterable<T>(
  object: Record<string, T>
) {
  for (const key in object) {
    yield [key, object[key]] as [string, T]
  }
}

export function consumeKey<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  k: K,
  consume: (v: T[K]) => void
) {
  const value = obj[k]
  consume(value)
  delete obj[k]
  return obj
}

export function entryIterableToObject<T>(iterable: Iterable<[string, T]>) {
  const retObj = {} as Record<string, T>
  for (const [key, value] of iterable) {
    retObj[key] = value
  }

  return retObj
}
