export async function initializeErrorMonitor(main: () => Promise<void>) {
  const notificationElement = document.createElement('div')
  notificationElement.style.backgroundColor = 'white'
  notificationElement.style.padding = '1em'
  notificationElement.style.color = 'red'
  notificationElement.style.font = 'monospace'
  notificationElement.style.display = 'none'
  document.body.appendChild(notificationElement)
  const listener = (error: Error) => {
    const innerParagraph = document.createElement('p')
    innerParagraph.innerText = String(error)
    notificationElement.appendChild(innerParagraph)
    notificationElement.style.display = 'initial'
  }

  window.addEventListener('unhandledrejection', listener as any)
  window.addEventListener('error', listener as any)

  try {
    await main()
  } catch (e) {
    const x = e as PromiseRejectionEvent
    listener(x.reason)
  }
}
