import { Possible } from "@/types/patterns"
import { getOrFail } from "big-m"
import { defined } from "big-m/dist/types/utils"
import { none, Option, some } from "fp-ts/lib/Option"
import { insertSorted, scalarSort } from "./arrays"
import { buildError, ErrorBuilder } from "./errors"

export type Deferred<T> = {
  promise: Promise<T>,
  resolve: (value: T) => void,
  reject: (error: any) => void,
}

export function defer(): {
  promise: Promise<void>,
  resolve: () => void,
  reject: (error: any) => void,
}
export function defer<T>(): {
  promise: Promise<T>,
  resolve: (value: T) => void,
  reject: (error: any) => void,
}
export function defer<T>() {
  let resolve, reject

  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve, reject = _reject
  })

  return {
    promise,
    resolve,
    reject
  } as unknown as Deferred<T>
}

export function watchPromise<T>(promise: Promise<T>) {
  const outputValue = {
    promise,
    rejected: none as Option<Error>,
    resolved: none as Option<T>,
    ended: false
  }

  promise.then((r) => {
    outputValue.resolved = some(r)
    outputValue.ended = true
  }).catch((e) => {
    outputValue.rejected = some(e)
    outputValue.ended = true
  })

  return outputValue
}

export function siftError(promise: Promise<any>): Promise<Possible<Error>> {
  return new Promise((resolve) => {
    promise.then(() => resolve(undefined)).catch(e => resolve(e))
  })
}

export function mapPromise<I, O>(fn: (i: I) => O) {
  return (pi: Promise<I>) => pi.then(fn)
}

export function ms(milliseconds = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds)
  })
}

export function loadPromisesIntoArray<T>(array: T[], promises: Promise<T>[]) {
  const resultToOrdinalityMap: Map<T, number> = new Map()
  const outcome = Promise.all(promises.map((p, i) => p.then(r => {
    const resultPriorToSet = r
    const indexInserted = insertSorted(
      array,
      r,
      // If a Proxy intercedes in the setting Promise, the value actually set may not equal the value we
      // attempted to set. To work around this problem, we avoid getting the result's ordinality until
      // after it has been inserted...
      scalarSort((item) => r === resultPriorToSet ? i : getOrFail(resultToOrdinalityMap, item))
    )

    // ...and then we set the ordinality based on what actually exists at the array at that index. 
    resultToOrdinalityMap.set(array[indexInserted], i)
  })))

  return {
    array,
    outcome
  }
}

/**
 * Generate a function that only calls the underlying function
 * when any existing execution of that function has completed.
 * 
 * When a call comes in, it is added to a queue of size
 * `queueSize`. If the queue is full, the call is a no-op.
 * 
 * Returns a Promise that resolves when the requested invocation
 * is completed.
 * 
 * @param asyncFn The underlying async function that is not to be called
 * more than once in parallel.
 */
export function gate<T>(asyncFn: () => Promise<T>, queueSize = 0, throttleMs = 0): () => Promise<T> {
  const deferredArray = [] as Deferred<T>[]

  const workThroughQueue = async () => {
    while (deferredArray.length) {
      const activeDeferred = deferredArray[0]
      const retVal = await asyncFn()
      await ms(throttleMs)
      activeDeferred.resolve(retVal)
      deferredArray.shift()
    }
  }

  return () => {
    if (deferredArray.length <= queueSize) {
      const newDeferral = defer<T>()

      deferredArray.push(newDeferral)

      if (deferredArray.length === 1) {
        workThroughQueue()
      }

      return newDeferral.promise
    } else {
      return defined(
        deferredArray[0]
      ).promise
    }
  }
}

export async function noopAsync() {
  return
}

export async function identityAsync<T>(t: T) {
  return t
}

/**
 * Modify a Promise to apply a side effect on successful resolution, preserving the original Promise's resolution.
 * 
 * The returned Promise will reject if either the original Promise rejects, or if the side effect function rejects.
 * 
 * @param promise The promise to apply a side effect to.
 * @param fn The side effect, triggered on successful resolution of the promise, supplied the Promise's resolution as its first argument.
 * @returns A promise resolving to the initial promise's resolution, but only after both it and the side effect function's returned promise have resolved.
 */
export async function sideEffect<T>(promise: Promise<T>, fn: (t: T) => unknown | Promise<unknown>): Promise<T> {
  const t = await promise
  await fn(t)
  return t
}

export class SignalWaiter<T> {
  private _signals: Set<T> = new Set()
  private _deferred: Possible<Deferred<void>> = undefined
  private _unexpectedArrivals: Set<T> = new Set()
  private _stickyArrivals: Set<T> = new Set()
  constructor(signals?: Iterable<T>) {
    if (signals) {
      for (const item of signals) {
        this._signals.add(item)
      }
      this.onExpectationChange()
    }
  }

  private onExpectationChange() {
    if (this._deferred && this._signals.size === 0) {
      this._deferred.resolve()
      this._deferred = undefined
    } else if (!this._deferred && this._signals.size > 0) {
      this._deferred = defer()
    }
  }

  public expectSignal(signal: T) {
    if (!this._stickyArrivals.has(signal)) {
      if (!this._unexpectedArrivals.has(signal)) {
        this._signals.add(signal)
      } else {
        this._unexpectedArrivals.delete(signal)
      }

      this.onExpectationChange()
    }
  }

  public expectSignals(signals: Iterable<T>) {
    for (const signal of signals) {
      if (!this._stickyArrivals.has(signal)) {
        if (!this._unexpectedArrivals.has(signal)) {
          this._signals.add(signal)
        } else {
          this._unexpectedArrivals.delete(signal)
        }
      }
    }

    this.onExpectationChange()
  }

  public receiveSignals(signals: Iterable<T>, sticky = false as false | "sticky") {
    for (const signal of signals) {
      if (sticky) {
        this._unexpectedArrivals.delete(signal)
        this._stickyArrivals.add(signal)
      }

      if (this._signals.has(signal)) {
        this._signals.delete(signal)
      } else {
        this._unexpectedArrivals.add(signal)
      }
    }

    this.onExpectationChange()
  }

  public receiveSignal(signal: T, sticky = false as false | "sticky") {
    if (sticky) {
      this._unexpectedArrivals.delete(signal)
      this._stickyArrivals.add(signal)
    }

    if (this._signals.has(signal)) {
      this._signals.delete(signal)
    } else {
      this._unexpectedArrivals.add(signal)
    }

    this.onExpectationChange()
  }

  public allFulfilled() {
    return this._deferred || Promise.resolve()
  }

  public reset() {
    this._unexpectedArrivals.clear()
    this._signals.clear()
    this._stickyArrivals.clear()
    if (this._deferred) {
      this._deferred.reject(new Error("Reset"))
      this._deferred = undefined
    }
  }
}

export async function parallel(
  fn: (addPromiseToQueue: (...promise: Promise<any>[]) => void) => void | Promise<void>
) {
  const promises: Promise<any>[] = []
  await fn(
    promise => promises.push(promise)
  )
  return Promise.all(promises) as any as Promise<void>
}

export async function timeout<T>(
  promise: Promise<T>,
  ms: number,
  error: ErrorBuilder<[number]> = (ms: number) => new Error(`Timeout after ${ms} ms`)
) {
  return new Promise<T>((resolve, reject) => {
    const timeoutHandle = setTimeout(
      () => {
        reject(buildError(error, ms))
      },
      ms
    )

    promise.then((v) => {
      resolve(v)
      clearTimeout(timeoutHandle)
    }).catch((e) => {
      reject(e)
    })
  })
}

export async function* filterAsync<T>(arr: Iterable<T | Promise<T>>, filterFn: (t: T) => boolean | Promise<boolean>) {
  for await (const item of arr) {
    if (await filterFn(item)) {
      yield item
    }
  }
}

export async function toArray<T>(asyncIterator: AsyncGenerator<T>) {
  const arr = [] as T[]
  for await (const i of asyncIterator) {
    arr.push(i)
  }
  return arr
}

export function timeLog<T>(p: Promise<T>): Promise<T> {
  const startedWatchingMs = Number(new Date())
  // @eslint-disable-next-line Ideally the lint should apply to callers of this function, not the function itself. But since this is not possible we just disable it.
  p.then(() => console.log(`time ms: ${Number(new Date()) - startedWatchingMs}`))
  return p
}
