import { Possible } from "@/types/patterns"
import { getOrFill } from "big-m"
import { buildError, ErrorBuilder } from "./errors"

export function assignKey<O extends Record<string, any>, K extends keyof O>(o: O, k: K): (value: O[K]) => O {
  return (value) => Object.assign(
    o,
    { [k]: value }
  )
}

export function pass<T>(fn: (item: T) => any): (item: T) => T {
  return (item: T) => {
    fn(item)
    return item
  }
}

export function fold<I, O>(bool: (input: I) => boolean, yes: (input: I) => O, no: (input: I) => O) {
  return (input: I) => bool(input) ? yes(input) : no(input)
}

export function foldDefined<I, O>(
  isDefined: (input: I) => O,
  isUndefined: () => O
) {
  return (i: Possible<I>) => i === undefined ? isUndefined() : isDefined(i)
}

export function ternary<I, O>(bool: (input: I) => boolean, yes: O, no: O) {
  return (input: I) => bool(input) ? yes : no
}

export function thrower<T extends []>(e: Error | string | ((...args: T) => Error | string)) {
  if (typeof e === "function") {
    return (...args: T) => { throw e(...args) }
  } else {
    return () => { throw typeof e === "string" ? new Error(e) : e }
  }
}

export function pick<T extends object, K extends keyof T>(k: K): (item: T) => T[K] {
  return (item: T) => item[k]
}

export function named<I extends any[], O>(fn: (...args: I) => O, name: string) {
  const obj = {
    [name](...args: I): O {
      return fn(...args)
    }
  }

  return obj[name]
}

/**
 *
 * @returns A function called on an object of type T
 * that returns true if and only if that object
 * Has a key-value pair matching the single
 * key-value pair in the argument.
 */
export function match<T, SubT extends T, K extends keyof SubT>(queryLiteral: { [k in K]: SubT[K] }) {
  let matchKey: K = "" as any
  for (const key in queryLiteral) {
    matchKey = key
    break
  }

  const matchVal = queryLiteral[matchKey]

  return (obj: T) => (obj as SubT)[matchKey] === matchVal
}

export function identity<T>(t: T) {
  return t
}

export function noop() {
  return undefined
}

export function execute<T>(fn: () => T) {
  return fn()
}

/**
 * Given an async function, execute it once, then return a function that
 * waits for the initial execution to complete before initiating another.
 */
export function eagerAsync<T>(fn: () => Promise<T>) {
  const initialPromise = fn()

  return async () => {
    await initialPromise
    return fn()
  }
}

export function wrapInDebug<T>(fn: () => T): () => T {
  return () => {
    // tslint:disable-next-line
    eval('debug' + 'ger')
    return fn()
  }
}

export function combine(...fns: (() => any)[]) {
  return () => fns.forEach(fn => fn())
}

export function methodOf<K extends keyof O, O extends Record<K, (this: O, ...rest: any[]) => any>>(obj: O, key: K): O[K] {
  return obj[key].bind(obj) as O[K]
}

export function memoize<Arg1, T>(fn: (a: Arg1) => T) {
  const argToOutput = new Map<Arg1, T>()

  return (a: Arg1) => getOrFill(
    argToOutput,
    a,
    fn
  )
}

export function bindSecondArg<A1, A2, O>(fn: (a1: A1, a2: A2) => O, a2: A2) {
  return (a1: A1) => fn(a1, a2)
}

export function thunk<T>(t: T) {
  return () => t
}

export function debounce<A extends any[] = []>(fn: (...a: A) => void, wait: number) {
  let timeout: null | number

  return function executedFunction(...a: A) {
    const later = function () {
      timeout = null
      fn(...a)
    }

    clearTimeout(timeout as number)

    timeout = setTimeout(later, wait) as any as number
  }
}

export function composeFilters<T>(...fns: ((t: T) => boolean)[]) {
  return (t: T) => {
    for (const filterFn of fns) {
      if (!filterFn(t)) {
        return false
      }
    }

    return true
  }
}

export function valIf<T>(t: T, condition: boolean) {
  return condition ? t : undefined
}

export function asString(t: any, e: ErrorBuilder<[any]> = (i) => `Expected string but received input ${i}`) {
  if (typeof t === 'string') {
    return t
  } else {
    throw buildError(e, t)
  }
}
