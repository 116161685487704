import { provider } from "@/logic/patterns/provide"
import {
  modalController
} from "@ionic/vue"
import UpsertDeck from "./modals/UpsertDeck.vue"
import UpsertFlashcard from "./modals/UpsertFlashcard.vue"
import ReorderDecks from "./modals/ReorderDecks.vue"
import NullComponent from "./component-patterns/NullComponent.vue"
import { Possible } from "@/types/patterns"
import { NormalizedDeck } from "@/types/models/deck.model"
import { pass } from "@/logic/patterns/functions"
import { v4 as uuid } from 'uuid'

type ModalKey = "UpsertDeck" | "UpsertFlashcard" | "ReorderDecks"
type Callbacks = {
  onDismiss: () => void,
}

const components = {
  UpsertDeck,
  UpsertFlashcard,
  NullComponent,
  ReorderDecks
}

export const modalMonitor = provider(
  () => {
    let currentModalHandles: Possible<{
      uuid: string,
      presentedPromise: Promise<HTMLIonModalElement>,
      callbacks?: Callbacks,
    }> = undefined

    async function clearModal(uuid?: string) {
      if (currentModalHandles && (!uuid || currentModalHandles.uuid === uuid)) {
        const modalHandles = currentModalHandles
        currentModalHandles = undefined
        const modal = await modalHandles.presentedPromise
        const dismissPromise = modal.dismiss()
        if (modalHandles.callbacks) {
          modalHandles.callbacks.onDismiss()
        }
        return dismissPromise
      }
    }

    function updateModal(
      name: ModalKey,
      props: Record<string, any>,
      callbacks?: Callbacks
    ) {
      const modalUuid = uuid()
      // Not waiting for the previous modal to clear
      // because this allows the modals to transition
      // through each other.
      clearModal()

      const component = components[name]

      const presentedPromise = modalController.create({
        component,
        componentProps: {
          ...props,
          dismiss: () => {
            props.dismiss && props.dismiss()
            clearModal(modalUuid)
          }
        },
        backdropDismiss: false
      }).then(pass(m => m.present()))

      currentModalHandles = {
        uuid: modalUuid,
        presentedPromise,
        callbacks
      }
    }

    return {
      isOpenModal: () => !!currentModalHandles,
      clearModal,
      upsertDeck: (deckId?: number) => updateModal("UpsertDeck", { deckId }),
      upsertFlashcard: (cardId?: number) => updateModal("UpsertFlashcard", { cardId }),
      createFlashcardForDeck: (deckContext: NormalizedDeck) => updateModal(
        "UpsertFlashcard",
        { deckContext },
        {
          onDismiss: () => {
            updateModal(
              "UpsertDeck",
              { deck: deckContext }
            )
          }
        }
      ),
      reorderDecks: () => updateModal(
        "ReorderDecks",
        {}
      )
    }
  }
)
