import { isSameDayYukonTime } from "@/logic/dates"
import { none, some } from "fp-ts/lib/Option"

export type WordOfTheDay = {
  __meta__: {
    createdBy: string,
    lastModifiedBy: string,
    createdAt: string,
    lastModifiedAt: string,
  },
  cardId: number,
}

export function getValidWordOfTheDayId(wotd: WordOfTheDay | null) {
  if (wotd === null) {
    return none
  }

  const lastSet = new Date(wotd.__meta__.lastModifiedAt)
  const now = new Date()
  if (isSameDayYukonTime(lastSet, now)) {
    return some(wotd.cardId)
  } else {
    return none
  }
}
