import { modalMonitor } from '@/components/UniversalModal'
import { ms } from '@/logic/patterns/async'
import { thunk } from '@/logic/patterns/functions'
import { castRouterPropsToNumber } from '@/logic/patterns/vue'
import { clearPreAuthNavigation, registerPreAuthNavigation } from '@/services/auth.service'
import { userLoggedIn } from '@/services/state.service'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { isSome, none } from 'fp-ts/lib/Option'
import { isNone } from 'fp-ts/lib/Option'
import { RouteRecordRaw } from 'vue-router'
import { isRouterPushNavigationConsume } from './state'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/acknowledgements',
    component: () => import('@/views/Acknowledgements.vue')
  },
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/flashcard/:flashcardId',
    component: () => import('@/views/off-menu/SingleCard.vue'),
    props: castRouterPropsToNumber
  },
  {
    path: '/deck/:deckId',
    component: () => import('@/views/off-menu/SingleDeck.vue'),
    props: castRouterPropsToNumber
  },
  {
    path: '/purge',
    component: () => import('@/views/off-menu/Purge.vue')
  },
  {
    path: '/home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/community',
    component: () => import('@/views/Community.vue')
  },
  {
    path: '/community/:q',
    component: () => import('@/views/Community.vue'),
    props: true
  },
  {
    path: '/my-cards',
    component: () => import('@/views/MyCards.vue')
  },
  {
    path: '/liked-cards',
    component: () => import('@/views/LikedCards.vue')
  },
  {
    path: '/quiz',
    component: () => import('@/views/Quiz.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/off-menu/Login.vue')
  },
  {
    path: '/logout',
    component: () => import('@/views/Logout.vue')
  },
  {
    path: '/language',
    component: () => import('@/views/Language.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const WAIT_FOR_CREDENTIAL_RETRIEVAL_MS = 1000

router.beforeEach(
  async (to, from, next) => {
    const isAuthFlow = ["/login", "/logout"].includes(to.path)
    const user = await Promise.race([
      userLoggedIn(),
      ms(WAIT_FOR_CREDENTIAL_RETRIEVAL_MS).then(thunk(none))
    ])

    if (isNone(user) && !isAuthFlow) {
      registerPreAuthNavigation(to.path)
      return next("login")
    } else if (isSome(user) && to.path === "/login") {
      return next(from.path)
    } else {
      if (!isAuthFlow) {
        clearPreAuthNavigation()
      }
    }

    if (modalMonitor().isOpenModal()) {
      modalMonitor().clearModal()
      return next(false)
    } else if (isRouterPushNavigationConsume()) {
      return next(to.path)
    } else {
      return next()
    }
  }
)

export default router
