import { inject, provider } from "@/logic/patterns/provide"
import { Plugins } from "@capacitor/core"

import { RecordingData, VoiceRecorderPlugin } from 'capacitor-voice-recorder'
import { Either, left, right } from "fp-ts/lib/Either"

const { VoiceRecorder } = Plugins

export const localProvide = provider(() => ({
  VoiceRecorder: VoiceRecorder as VoiceRecorderPlugin
}))

export type AudioCaptureError = {
  stage: "permission" | "startRecording" | "stopRecording",
  e: unknown,
}

export type RecordingMetadata = RecordingData['value']
export type AudioCaptureHook = () => Promise<Either<AudioCaptureError, RecordingMetadata>>

export const captureAudio = inject(
  localProvide,
  async ({
    VoiceRecorder
  }): Promise<Either<AudioCaptureError, AudioCaptureHook>> => {
    try {
      const existingPermissionResult = await VoiceRecorder.hasAudioRecordingPermission()

      if (!existingPermissionResult.value) {
        const permissionResult = await VoiceRecorder.requestAudioRecordingPermission()
        if (!permissionResult.value) {
          return left({
            stage: 'permission',
            e: new Error("User refused permission")
          })
        }
      }
    } catch (e) {
      return left({
        stage: 'permission' as const,
        e
      })
    }

    try {
      const startRecordingResult = await VoiceRecorder.startRecording()

      if (!startRecordingResult.value) {
        return left({
          stage: 'startRecording' as const,
          e: new Error('Could not start recording for unknown reason')
        })
      }
    } catch (e) {
      return left({
        stage: 'startRecording' as const,
        e
      })
    }

    return right(
      async () => {
        try {
          const stopRecordingResult = await VoiceRecorder.stopRecording()

          return right(
            stopRecordingResult.value
          )
        } catch (e) {
          return left({
            stage: 'stopRecording' as const,
            e
          })
        }
      }
    )
  }
)
