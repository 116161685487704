import { Possible } from "big-m/dist/types/utils"
import {
  getOrElse,
  isSome,
  Option
} from "fp-ts/Option"


export function getSome<T>(option: Option<T>, errorMessage = () => "Expected Option type to be Some but it was None" as string | Error) {
  return getOrElse<T>(
    () => {
      const toThrow = errorMessage()
      throw toThrow instanceof Error ? toThrow : new Error(toThrow)
    }
  )(
    option
  )
}

export function collapse<T>(option: Option<T>): Possible<T> {
  return isSome(option) ? option.value : undefined
}
