import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

import { provider } from '@/logic/patterns/provide'

const firebaseConfig = {
  apiKey: 'AIzaSyDQDPrZwnP03yW_rOJCHVhBcqHuJS7iypM',
  authDomain: "kaska-flashcards.firebaseapp.com",
  databaseURL: "https://kaska-flashcards.firebaseio.com",
  projectId: "kaska-flashcards",
  storageBucket: "kaska-flashcards.appspot.com",
  messagingSenderId: "227244387302"
}

export const provide = provider(function provide() {
  const app = firebase.initializeApp(firebaseConfig)

  const database = app.database()
  const storage = app.storage()

  return {
    app,
    database,
    storage
  }
})
