<template>
  <IonCard class="ion-page">
    <IonContent>
      <IonCardHeader class="modal--top">
        <h2 class="modal--title">{{title}}</h2>
        <span @click="dismiss" class="modal--dismiss">
          <IonIcon :icon="close" />
        </span>
      </IonCardHeader>
      <IonCardContent>
        <slot v-bind="bindProps" />
      </IonCardContent>
    </IonContent>
  </IonCard>
</template>
<script lang="ts">
  import {
    defineComponent
  } from 'vue'
  import {
    IonCard,
    IonCardHeader,
    IonIcon,
    IonCardContent,
    IonContent
  } from '@ionic/vue'
  import { requiredType } from '@/logic/patterns/vue'
  import {
    close
  } from 'ionicons/icons'

  export default defineComponent({
    props: {
      title: requiredType(String),
      dismiss: requiredType(Function)
    },
    components: {
      IonCard,
      IonCardHeader,
      IonIcon,
      IonCardContent,
      IonContent
    },
    setup(props) {
      return {
        close,
        bindProps: props
      }
    }
  })
</script>
<style scoped>
  .modal--top {
    display: flex;
    justify-content: space-between; 
  }

  .modal--dismiss {
    cursor: pointer;
  }

  h2 {
    --color: #111111
  }
</style>
<style>
  #app .modal-wrapper {
    background: transparent;
    box-shadow: none;
    pointer-events: none;
  }

  #app .modal-wrapper ion-card {
    pointer-events: all;
  }

  #app .modal-wrapper ion-content {
    --background: initial;
  }

  .modal--title {
    margin-top: 0;
  }

  ion-card-header {
    margin-top: 8px;
  }

  .modal--dismiss {
    font-size: 2em;
  }
</style>