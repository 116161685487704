import { composeProviders, inject, provideInjected } from "@/logic/patterns/provide"
import { defined } from "big-m/dist/types/utils"
import { getAudioById, getBackgroundFile, getWelcomeFile, getMediaById, saveAudioFile } from "./db.service"
import { getDownloadUrl, putFile, deleteFile } from "./filestore.service"
import { memoizeInCache } from "./storage.service"
import {
  asImage,
  Audio,
  Image
} from "@/types/models/media.model"
import { pipe } from "fp-ts/lib/function"
import { Flashcard } from "@/types/models/flashcard.model"
import { promisedEvent } from "@/dom/events"
import { Possible } from "@/types/patterns"
import { maxBy, minBy } from "@/logic/patterns/iterables"
import { lazy } from "@/logic/patterns/lazy"
import { isMobile } from "@/router/state"
import { isSome, none, some } from 'fp-ts/lib/Option'
import { UnsplashImage } from '@/types/models/image.model'

export const localProvide = provideInjected(
  {
    getBackgroundFile,
    getWelcomeFile,
    getDownloadUrl,
    getMediaById,
    saveAudioFile,
    putFile,
    deleteFile
  }
)

export function getPathFromAudio({ authorId, fileName, generation }: { authorId: string, fileName: string, generation: Possible<number> }) {
  return `/flamelink/media/audio/${authorId}${generation === undefined ? "" : ("/" + generation)}/${fileName}`
}


export const getAudioPath = inject(
  provideInjected({ getAudioById }),
  async function getAudioPath({ getAudioById }, audio: NonNullable<Flashcard["audio"]>) {
    const audioArgs = await ((async () => {
      if (Array.isArray(audio)) {
        const [audioFileId] = audio
        const media: Audio = await getAudioById(audioFileId)
        return {
          authorId: media.__meta__.createdBy,
          fileName: media.file,
          generation: media.generation
        }
      } else {
        return {
          authorId: audio.user,
          fileName: audio.filename,
          generation: Number(audio.generation) || undefined
        }
      }
    })())

    return getPathFromAudio(audioArgs)
  }
)

async function getDownloadUrlWithV0Recovery(path: string, getDownloadUrl: (path: string) => Promise<string>) {
  try {
    return await getDownloadUrl(path)
  } catch (e) {
    const alternatePathParts = path.split("/")
    // Remove the timestamp component which was added for completeness
    // but which files uploaded at an earlier date do not include.
    alternatePathParts.splice(alternatePathParts.length - 2, 1)

    if (alternatePathParts.length !== 6) {
      throw e
    }

    return await getDownloadUrl(alternatePathParts.join("/"))
  }
}

function getAudioPlayerFromNativeAudio(audio: HTMLAudioElement) {
  audio.style.display = "none"
  document.body.appendChild(audio)

  const canPlayThroughPromise = promisedEvent(audio, "canplaythrough")
  audio.load()

  return {
    play() {
      // Start playing whether it has loaded or not; this is to ensure it happens within callback scope of whatever called play() (hopefully a click event, lest Safari rule that the browser does not have permission)
      const playPromise = audio.play()
      const playBegun = canPlayThroughPromise.finally(() => playPromise)

      return {
        playBegun,
        playEnded: playBegun.then(
          () => Promise.race([
            promisedEvent(audio, "ended"),
            promisedEvent(audio, "pause")
          ])
        ),
        pause: () => playBegun.then(
          () => audio.pause()
        )
      }
    }
  }
}

export function getAudioPlayerFromFile(file: File) {
  const url = URL.createObjectURL(file)
  const audio = new window.Audio(url)
  return {
    path: file.name,
    ...getAudioPlayerFromNativeAudio(audio)
  }
}

export const getAudioPlayerFromPath = inject(
  localProvide,
  async function getAudioPlayerFromPath({ getDownloadUrl }, path: string) {
    const url = await getDownloadUrlWithV0Recovery(
      path,
      getDownloadUrl
    )
    const audio = new window.Audio(url)
    return {
      path,
      ...getAudioPlayerFromNativeAudio(audio)
    }
  }
)

export const getAudioPlayer = inject(
  provideInjected({ getDownloadUrl, getAudioById, getAudioPath, getAudioPlayerFromPath }),
  async function getAudioPlayer({
    getAudioPlayerFromPath, getAudioPath
  }, audio: NonNullable<Flashcard["audio"]>) {
    const path = await getAudioPath(audio)
    return getAudioPlayerFromPath(path)
  }
)

export const deleteAudioFile = inject(
  localProvide,
  async ({ deleteFile }, audio: NonNullable<Flashcard["audio"]>) => {
    const path = await getAudioPath(audio)
    return deleteFile(path)
  }
)

function getImageScale(image: Image['sizes'][0]) {
  return image.height
}

export function getPathFromImage(image: Image, sizeParameter: 'full' | 'smallest' | 'large') {
  if (sizeParameter === 'full') {
    return `/flamelink/media/${image.file}`
  } else {
    const size = defined(
      sizeParameter === 'smallest' ? minBy(image.sizes, getImageScale) : maxBy(image.sizes, getImageScale)
    ).path

    return `/flamelink/media/sized/${size}/${image.file}`
  }
}

export const getDownloadUrlFromImage = inject(
  provideInjected({ getDownloadUrl }),
  async function getDownloadUrlFromImage({
    getDownloadUrl
  }, image: Image, sizeParameter: 'full' | 'smallest' | 'large') {
    return pipe(
      image,
      i => getPathFromImage(i, sizeParameter),
      getDownloadUrl
    )
  }
)

export const putAudioFile = inject(
  localProvide,
  async function putAudioFile({
    saveAudioFile,
    putFile
  }, file: File) {
    const generation = new Date().valueOf()
    const { id, savePromise, authorId } = saveAudioFile({
      contentType: file.type,
      file: file.name,
      generation
    })

    const path = getPathFromAudio({
      authorId,
      fileName: file.name,
      generation
    })

    await putFile(path, file)
    await savePromise
    return id
  }
)

const isInternal = (url: string) => url.startsWith('https://firebasestorage.googleapis.com')
const imageId = (imageIdentifier: number | UnsplashImage | {
  url: string,
  id: number,
}) => {
  if (typeof imageIdentifier === 'number') {
    return some(imageIdentifier)
  } else if (isInternal(imageIdentifier.url)) {
    // Some card image fields represent an internal Flamelink object. Canonically, this is not allowed: when selecting an internal object, only the ID is set. But there was a bug preventing this from happening, so this heals that scar in the data.
    return some(Number(imageIdentifier.id))
  } else {
    return none
  }
}

export const getDownloadUrlFromImageIdentifier = inject(
  composeProviders(
    localProvide,
    provideInjected({ getDownloadUrlFromImage })
  ),
  async function getDownloadUrlFromImageIdentifier({
    getMediaById,
    getDownloadUrlFromImage
  }, identifier: number | UnsplashImage | {
    url: string,
    id: number,
  }, sizeParameter: 'full' | 'smallest' | 'large') {
    const internalId = imageId(identifier)

    if (isSome(internalId)) {
      return pipe(
        await getMediaById(internalId.value),
        asImage,
        i => getDownloadUrlFromImage(
          i,
          sizeParameter
        )
      )
    } else if (typeof identifier === 'object' && 'thumbUrl' in identifier) {
      return sizeParameter === 'smallest' ? identifier.thumbUrl : sizeParameter === 'large' ? identifier.url : identifier.largeUrl
    } else {
      throw new Error(`Could not handle ${JSON.stringify(identifier)}`)
    }
  }
)

async function innerGetBackgroundUrl({ getBackgroundFile }: ReturnType<typeof localProvide>) {
  const backgroundFile = await getBackgroundFile()

  return getPathFromImage(backgroundFile, isMobile() ? 'large' : 'full')
}

async function innerGetWelcomeUrl({ getWelcomeFile }: ReturnType<typeof localProvide>) {
  const welcomeFile = await getWelcomeFile()

  return getPathFromImage(welcomeFile, 'large')
}

export const unwrappedGetBackgroundUrl = inject(
  localProvide,
  innerGetBackgroundUrl
)

export const unwrappedGetWelcomeUrl = inject(
  localProvide,
  innerGetWelcomeUrl
)


const DAY_MS = 24 * 3600 * 1000
const WEEK_MS = 7 * DAY_MS

const getBackgroundRawUrl = memoizeInCache(unwrappedGetBackgroundUrl, "background-url", { lifespan: WEEK_MS })

export const getBackgroundUrl = lazy(
  inject(
    localProvide,
    async ({
      getDownloadUrl
    }) => {
      return getDownloadUrl(
        await getBackgroundRawUrl()
      )
    }
  )
)

const getWelcomeRawUrl = memoizeInCache(unwrappedGetWelcomeUrl, "welcome-url", { lifespan: WEEK_MS })

export const getWelcomeUrl = lazy(
  inject(
    localProvide,
    async ({
      getDownloadUrl
    }) => {
      return getDownloadUrl(
        await getWelcomeRawUrl()
      )
    }
  )
)
