<template>
  <IonPage>
    <IonHeader class="logout">
      <IonToolbar>
        <IonTitle class="bare-header">Logout</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <div class="page-inner">
        <IonCard v-if="loggedOut" class="card--standard">You are now logged out. <a href="#" onclick="return false;" @click="reload">Log in</a></IonCard>
        <IonCard v-else class="card--standard">…</IonCard>
      </div>
    </IonContent>
  </IonPage>
</template>

<script lang="ts">
import { reuseMonitoredPromiseState, zeroPromiseState } from '@/logic/patterns/async-vue-ref'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard } from '@ionic/vue'
import { computed, defineComponent } from 'vue'
import { logout } from '@/services/auth.service'
import { isLogoutScreen } from '@/router/state'

export default defineComponent({
  name: 'Logout',
  setup() {
    const logoutPromise = zeroPromiseState(isLogoutScreen.value ? 'SUCCESS' : 'READY')

    const loggedOut = computed(() => logoutPromise.status === "SUCCESS")

    const reload = () => window.location.pathname = "/login"

    return {
      reload,
      logoutPromise: () => logoutPromise,
      loggedOut
    }
  },
  ionViewWillLeave() {
    this.reload()
  },
  ionViewDidEnter() {
    // Weird hack: Ion Vue Router keeps components alive--and re-renders them!--after first load. When a user logs out, loggedInUser() becomes none throughout the application, which breaks a lot of components that rely on that value--none of which should be re-rendering! The errors in those components make the application break. To escape this, set this flag to force the App to kill the IonRouterOutlet and just load this component. (If the user hits 'log in' the application will refresh anyway.)
    reuseMonitoredPromiseState(
      this.logoutPromise(),
      logout().then(() => {
        isLogoutScreen.value = true
      })
    )
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonCard }
})
</script>
<style scoped>
ion-card {
  text-align: center;
  min-height: 10em;
  background: #fffc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.logout ion-title {
  padding-left: 1em;
}
</style>