import { storageWrapper } from "@/logic/firebase/wrapper"
import { memoize } from "@/logic/patterns/functions"
import { inject } from "@/logic/patterns/provide"
import { provide } from "./provide/app"

// TODO calling 'memoize' here breaks provide/inject pattern;
// figure out the proper way to wrap injected functions.
export const getDownloadUrl = memoize(
  inject(
    provide,
    function getDownloadUrl({ storage }, path: string) {
      return storageWrapper(storage).getDownloadUrl(path)
    }
  )
)

export const putFile = inject(
  provide,
  function putFile({ storage }, path: string, data: Blob | Uint8Array | ArrayBuffer) {
    return storageWrapper(storage).saveBlob(path, data)
  }
)

export const deleteFile = inject(
  provide,
  function putFile({ storage }, path: string) {
    return storageWrapper(storage).deleteFile(path)
  }
)
