import {
  fromNullable,
  none,
  Option,
  some
} from "fp-ts/Option"

import {
  Observable,
  Subscriber
} from "rxjs"

import firebase from "firebase/app"

// Add the Firebase services that you want to use
import "firebase/auth"
import { extendProvider, inject } from '@/logic/patterns/provide'
import { provide } from './provide/app'
import { notNullOrUndefined } from "big-m/dist/types/utils"
import * as firebaseui from 'firebaseui'
import { syncObservable } from '@/logic/patterns/observable'

export const localProvide = extendProvider(
  provide,
  ({ app }) => {
    const auth = app.auth()
    const ui = new firebaseui.auth.AuthUI(auth)
    return { auth, ui }
  }
)

const FIREBASE_AUTH_CREDENTIAL_HELPER_NONE = "none"

let preAuthNavigation: Option<string> = none

export function registerPreAuthNavigation(route: string) {
  preAuthNavigation = some(route)
}

export function clearPreAuthNavigation() {
  preAuthNavigation = none
}

export function consumePreAuthNavigation() {
  const ret = preAuthNavigation
  clearPreAuthNavigation()
  return ret
}

export const loggedInUserGeneratedSingleton = extendProvider(
  localProvide,
  ({ auth }) => syncObservable<Option<firebase.User>>(
    new Observable<Option<firebase.User>>(
      (subscriber: Subscriber<Option<firebase.User>>) => {
        const unsubscribe = auth.onAuthStateChanged(
          newAuthState => {
            const newLoggedInUser = fromNullable(newAuthState)
            subscriber.next(newLoggedInUser)
          }
        )

        return unsubscribe
      }
    )
  )
)

export const logout = extendProvider(
  localProvide,
  ({ auth }) => auth.signOut()
)

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: (
      authResult: firebase.auth.UserCredential
    ) => {
      const authedUser = notNullOrUndefined(authResult.user, "Expected auth result to have a user object")

      notNullOrUndefined(authedUser.email, "Expected user entity to have an email")

      if (authResult.additionalUserInfo?.isNewUser) {
        authedUser.sendEmailVerification()
      }

      // 'false' indicates no automatic redirect
      return false
    }
  },
  credentialHelper: FIREBASE_AUTH_CREDENTIAL_HELPER_NONE,
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true
    }
  ],
  tosUrl: "https://kaskacards.ca/LFN-TOS.html",
  privacyPolicyUrl: "https://kaskacards.ca/LFN-TOS.html"
}

export const startFirebaseUi = inject(
  localProvide,
  function startFirebaseUi({ ui }, selector: string) {
    ui.start(
      selector,
      uiConfig
    )
  }
)

