import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonSpinner = _resolveComponent("IonSpinner")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonButton = _resolveComponent("IonButton")!

  return (_openBlock(), _createBlock(_component_IonButton, _mergeProps(_ctx.passProps, {
    onClick: _ctx.doAsync,
    alt: _ctx.state === 'ERROR' ? 'Something went wrong. Please try again later.' : ''
  }), {
    default: _withCtx(() => [
      (_ctx.state === 'LOADING')
        ? (_openBlock(), _createBlock(_component_IonSpinner, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.state === 'SUCCESS')
        ? (_openBlock(), _createBlock(_component_IonIcon, {
            key: 1,
            icon: _ctx.checkmark
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      (_ctx.state === 'ERROR')
        ? (_openBlock(), _createBlock(_component_IonIcon, {
            key: 2,
            alt: "Something went wrong. Please try again later.",
            icon: _ctx.close
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16, ["onClick", "alt"]))
}