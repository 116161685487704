import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logout = _resolveComponent("Logout")!
  const _component_IonRouterOutlet = _resolveComponent("IonRouterOutlet")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, null, {
    default: _withCtx(() => [
      (_ctx.isLogoutScreen)
        ? (_openBlock(), _createBlock(_component_Logout, {
            key: 0,
            id: "content"
          }))
        : (_openBlock(), _createBlock(_component_IonRouterOutlet, {
            key: 1,
            id: "content"
          })),
      _createVNode(_component_Tabs)
    ]),
    _: 1
  }))
}