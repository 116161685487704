import { pipe, tuple } from "fp-ts/lib/function"
import { fold, Option } from "fp-ts/lib/Option"
import { computed, onUnmounted, ref, Ref, SetupContext, unref, watch } from "vue"
import { Observable } from 'rxjs'
import { defined, Possible } from "big-m/dist/types/utils"
import { execute, methodOf, pick } from "./functions"
import { ms } from "./async"
import { mapIterable } from "./iterables"
import { entriesIterable, entryIterableToObject } from "./objects"
import { RouteInfo } from "@ionic/vue-router/dist/types/types"

/**
 * Given a Vue ref that produces an Optional value, produce a Vue ref that collapses the two states
 * into a mapped version of the Optional ref, or a default if it is not provided.
 * 
 * @comment This is useful when using an Optional pattern to chain a series of computed values
 * together, collapsing the final part of the chain before passing it to the template. As 
 * appropriate, the value can collapse to `undefined` or `[]` so the template can conveniently
 * render it with v-else or v-for respectively.
 * 
 * @param refInstance 
 * @param onNone 
 * @param onSome 
 */
export function foldingComputedOnOptional<T, V>(refInstance: Ref<Option<T>>, onNone: () => V, onSome: (t: T) => V) {
  return computed(() => pipe(
    refInstance.value,
    fold(
      onNone,
      onSome
    )
  ))
}

export function foldingComputedOnReactiveOptional<T, V>(reactiveInstance: Option<T>, onNone: () => V, onSome: (t: T) => V) {
  return computed(() => pipe(
    reactiveInstance,
    fold(
      onNone,
      onSome
    )
  ))
}

export function observableToRef<T>(observable: Observable<T>) {
  const retRef: Ref<T> = ref(undefined as Possible<T>) as any
  observable.subscribe(val => retRef.value = val)
  return retRef
}

export function observableToRefs<T>(observable: Observable<T>) {
  const result: Ref<T> = ref(undefined as Possible<T>) as any
  const yielded = ref(undefined as Possible<boolean>)
  const ended = ref(false)
  const error = ref(undefined as Possible<Error>)
  const waitingForFirst = computed(() => !unref(yielded) && !unref(error) && !unref(ended))

  observable.subscribe(
    val => {
      result.value = val
      yielded.value = true
    },
    e => {
      error.value = e
      ended.value = true
      yielded.value = false
    },
    () => {
      ended.value = true
      yielded.value = yielded.value || false
    }
  )

  return {
    result,
    yielded,
    ended,
    error,
    waitingForFirst
  }
}

export function refObservableToRefs<T>(refObservable: Ref<Observable<T>>, preserveValueUntilUpdate = false as false | "preserveValueUntilUpdate") {
  const result: Ref<Possible<T>> = ref(undefined as Possible<T>) as any
  const yielded = ref(undefined as Possible<boolean>)
  const ended = ref(false)
  const error = ref(undefined as Possible<Error>)
  const waitingForFirst = computed(() => !unref(yielded) && !unref(error) && !unref(ended))

  let unsubscribeFromPrevious: Possible<() => void> = undefined
  const dispose = watch(
    refObservable,
    observable => {
      unsubscribeFromPrevious && unsubscribeFromPrevious()

      error.value = undefined
      ended.value = false
      if (!preserveValueUntilUpdate) {
        result.value = undefined
        yielded.value = undefined
      }

      unsubscribeFromPrevious = methodOf(
        observable.subscribe(
          val => {
            result.value = val
            yielded.value = true
          },
          e => {
            error.value = e
            ended.value = true
            yielded.value = false
          },
          () => {
            ended.value = true
            yielded.value = yielded.value || false
          }
        ),
        "unsubscribe"
      )
    },
    { immediate: true }
  )

  return {
    result,
    yielded,
    ended,
    error,
    waitingForFirst,
    dispose
  }
}

export function wrapAsyncInInfiniteScroll<T>(fn: () => Promise<T>) {
  return async function (e: { target: { complete: () => void } }) {
    try {
      await fn()
    } finally {
      e.target.complete()
    }
  }
}

export function refToggle(r: Ref<boolean>) {
  return () => r.value = !r.value
}

export function pipeRef<T>(ref1: Ref<T>, ref2: Ref<T>) {
  return watch(
    ref1,
    (value) => ref2.value = value
  )
}

export function refAssign<T>(ref: Ref<T>, value: T) {
  return () => ref.value = value
}

export function refAssigner<T>(ref: Ref<T>) {
  return (value: T) => ref.value = value
}

export function requiredType<T>(
  type: any
) {
  return {
    type: type as () => T,
    required: true as const
  }
}

export function requiredPrimitive<T>(type: () => T) {
  return {
    type,
    required: true as const
  }
}

export function optionalPrimitive<T>(type: () => T) {
  return {
    type,
    required: false as const
  }
}

export function disposeArray() {
  const cleanupArray = [] as (() => void)[]
  onUnmounted(() => cleanupArray.map(execute))
  return cleanupArray
}

export function refToObservable<T>(ref: Ref<T>) {
  return new Observable(
    subscriber => {
      const unwatch = watch(
        ref,
        methodOf(subscriber, 'next'),
        { immediate: true }
      )

      return unwatch
    }
  )
}

export function waitForRefCondition<T>(ref: Ref<T>, condition: (t: T, oldT: Possible<T>) => boolean, immediate = "immediate" as "immediate" | "notImmediate") {
  return new Promise<T>((resolve) => {
    const watchStopHandle = watch(
      ref,
      async (value, oldValue) => {
        if (condition(value, oldValue)) {
          resolve(value)
          await ms(0)
          watchStopHandle()
        }
      },
      { immediate: immediate === "immediate" }
    )
  })
}

export function waitForReactiveCondition<X extends Map<any, any> | Set<any> | any[] | Record<string, any>>(reactive: X, condition: (t: X, oldT: Possible<X>) => boolean) {
  return new Promise<X>((resolve) => {
    const watchStopHandle = watch(
      reactive,
      async (value, oldValue) => {
        if (condition(value, oldValue)) {
          resolve(value)
          await ms(0)
          watchStopHandle()
        }
      },
      { immediate: true }
    )
  })
}

export function castRouterPropsToNumber(obj: RouteInfo): Record<string, number> {
  return pipe(
    obj,
    pick("params"),
    defined,
    entriesIterable,
    iter => mapIterable(iter, ([key, value]) => tuple(key, Number(value))),
    entryIterableToObject
  )
}

/**
 * A computed Ref that, if it yields a value considered "null" by "isNullState",
 * continues to yield its last non-null output until a new non-null output is
 * produced.
 */
export function laggyComputed<T>(
  inputRef: Ref<T> | (() => T),
  isNullState: (output: T) => boolean
) {
  const normalizedInputRef = typeof inputRef === "function" ? computed(inputRef) : inputRef
  const outputRef: Ref<T> = ref(normalizedInputRef.value as any)
  watch(
    normalizedInputRef,
    newValue => {
      if (!isNullState(newValue)) {
        outputRef.value = newValue
      }
    }
  )

  return outputRef
}

export function emitOnChange<T>(
  reference: Ref<T>,
  eventType: string,
  context: SetupContext
) {
  return watch(
    reference,
    newValue => context.emit(eventType, newValue),
    { immediate: true }
  )
}
