<template>
  <IonMenu contentId="content">
    <IonList :style="paddingIfIos">
      <IonItem @click="hideMenu" routerLink="/acknowledgements">
        <IonIcon :icon="bonfire" />
        <IonLabel>Acknowledgements</IonLabel>
      </IonItem>

      <IonItem @click="hideMenu" routerLink="/home">
        <IonIcon :icon="ellipse" />
        <IonLabel>Home</IonLabel>
      </IonItem>
        
      <IonItem @click="hideMenu" routerLink="/community">
        <IonIcon :icon="people" />
        <IonLabel>Community</IonLabel>
      </IonItem>
      
      <IonItem @click="hideMenu" routerLink="/my-cards">
        <IonIcon :icon="person" />
        <IonLabel>My Decks<span class="slight-margin">/</span>Cards</IonLabel>
      </IonItem>

      <IonItem @click="hideMenu" routerLink="/liked-cards">
        <IonIcon :icon="heart" />
        <IonLabel>Liked Decks<span class="slight-margin">/</span>Cards</IonLabel>
      </IonItem>

      <IonItem @click="hideMenu" routerLink="/quiz">
        <IonIcon :icon="school" />
        <IonLabel>Quiz</IonLabel>
      </IonItem>

      <IonItem @click="hideMenu" routerLink="/language">
        <IonIcon :icon="repeat" />
        <IonLabel>Swap Card Languages</IonLabel>
      </IonItem>

      <IonItem @click="hideMenu" routerLink="/logout">
        <IonIcon :icon="exit" />
        <IonLabel>Logout</IonLabel>
      </IonItem>
    </IonList>
  </IonMenu>
</template>
<script lang="ts">
import { IonMenu, IonLabel, IonIcon, IonList, IonItem } from '@ionic/vue'
import { ellipse, people, heart, person, exit, school, repeat, bonfireSharp, bonfire, bonfireOutline } from 'ionicons/icons'
import { hideMenu } from '@/services/menu.service'
import { isIos, languageToGuess } from '@/router/state'

export default {
  name: 'Tabs',
  components: { IonLabel, IonMenu, IonIcon, IonList, IonItem },
  setup() {
    const paddingIfIos = isIos() ? {
      paddingTop: '1.4em'
    } : {}

    return {
      paddingIfIos,
      ellipse,
      people,
      person,
      exit,
      hideMenu,
      school,
      heart,
      repeat,
      bonfireSharp, bonfire, bonfireOutline,
      languageToGuess
    }
  }
}
</script>
<style scoped>
  ion-item {
    user-select: none;
    cursor: pointer;
    color: #488aff;
  }

  ion-icon {
    margin-right: 3px;
  }
</style>