<template>
  <ModalPattern title="Reorder Decks" :dismiss="dismiss">
    <form name="cardForm">
      <p>Drag decks around to change the order they appear in for the default sort.</p>
      <IonList radio-group>
        <IonReorderGroup :disabled="false" @ionItemReorder="handleMoveDeck">
          <div v-for="deck of decks" :key="deck.id">
            <IonReorder>
              <IonItem>
                <IonLabel>{{deck.title}} – {{deck.cards?.length ? deck.cards?.length === 1 ? '1 card' : `${deck.cards.length} cards` : 'No cards'}}</IonLabel>
              </IonItem>
            </IonReorder>
          </div>
        </IonReorderGroup>
      </IonList>
    </form>
    <div>
      <IonButton fill="solid" color="primary" id="save" @click="dismiss">
        Done
      </IonButton>
    </div>
  </ModalPattern>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import ModalPattern from '@/components/component-patterns/ModalPattern.vue'
import { DeckFetcher, saveDeck } from '@/services/db.service'
import { scalarSort } from '@/logic/patterns/arrays'
import {
  IonItem,
  IonLabel,
  IonButton,
  IonReorderGroup,
  IonReorder,
  IonList
} from '@ionic/vue'
import { pickAndEnrolToDispose, reactiveDeltaListenerArray } from '@/logic/firebase/wrapper'
import { pipe } from 'fp-ts/lib/function'
import { requiredType } from '@/logic/patterns/vue'
import { Deck } from "@/types/models/deck.model"
import { defined } from 'big-m/dist/types/utils'

const DEFAULT_BUMP_ORDINALITY_BY = 10000

const getOrdinality = ({id, ordinality}: Deck) => ordinality ?? id

export default defineComponent({
  components: {
    ModalPattern,
    IonLabel,
    IonItem,
    IonButton,
    IonReorder,
    IonReorderGroup,
    IonList
  },
  props: {
    dismiss: requiredType<() => void>(Function)
  },
  setup() {
    const dispose = [] as (() => void)[]
    const receivedArrayFn = pickAndEnrolToDispose<Deck>(dispose)

    const deckFetcher = DeckFetcher()

    const decks = pipe(
      reactiveDeltaListenerArray(
        deckFetcher.userCreatedDecksObservable(),
        () => ({}),
        () => ({
          sortFn: scalarSort(d => -getOrdinality(d))
        })
      ),
      receivedArrayFn
    )

    const handleMoveDeck = async (event: { detail: {
      from: number,
      to: number,
      complete: () => void,
    }, }) => {
      const {from, to, complete} = event.detail
      const deckBeingMoved = defined(decks[from], 'Expected to find a deck being moved')
      const deckMovingTo = defined(decks[to], 'Expected to find a deck being moved to')

      if (to === 0) {
        deckBeingMoved.ordinality = getOrdinality(decks[0]) + DEFAULT_BUMP_ORDINALITY_BY
      } else if (to === decks.length - 1) {
        deckBeingMoved.ordinality = getOrdinality(decks[decks.length - 1]) - DEFAULT_BUMP_ORDINALITY_BY
      } else {
        const next = defined(decks[to + 1])
        deckBeingMoved.ordinality = (getOrdinality(deckMovingTo) + getOrdinality(next)) / 2
      }

      await saveDeck(deckBeingMoved)

      complete()
    }

    return {
      decks,
      handleMoveDeck
    }
  }
})
</script>
<style scoped>
  ion-icon {
    cursor: pointer;
  }

  ion-button ion-icon {
    color: inherit;
  }

  hr {
    background: #ddd
  }

  ion-label {
    vertical-align: middle;
  }
</style>
<style>
.audio-player {
  padding-right: 0.25em;
}

.audio--filename {
  max-width: 30%;
  padding-left: 0;
  padding-right: 0.2em;
}

.clear-audio, .clear-image {
  padding-right: 1em;
  color: var(--ion-color-danger-shade);
}
</style>