import { inject, provider } from '@/logic/patterns/provide'
import { menuController } from '@ionic/core/components'

export const localProvide = provider(() => {
  return {
    menuController
  }
})

export const hideMenu = inject(
  localProvide,
  function hideMenu({ menuController }) {
    return menuController.close(null)
  }
)
