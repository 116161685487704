<template>
  <IonButton v-bind="passProps" @click="doAsync" :alt="state === 'ERROR' ? 'Something went wrong. Please try again later.' : ''">
    <IonSpinner v-if="state === 'LOADING'" />
    <IonIcon v-if="state === 'SUCCESS'" :icon="checkmark" />
    <IonIcon v-if="state === 'ERROR'" alt="Something went wrong. Please try again later." :icon="close" />
    <slot />
  </IonButton>
</template>
<script lang="ts">
  import {
    defineComponent, ref, computed
  } from 'vue'
  import { monitorAsync, PromiseState } from '@/logic/patterns/async-vue-ref'
  import { Possible } from 'big-m/dist/types/utils'
  import { checkmark, close } from 'ionicons/icons'
  import { IonSpinner, IonIcon, IonButton } from '@ionic/vue'
  import { requiredType } from '@/logic/patterns/vue'
  import { omit } from '@/logic/patterns/objects'

  export default defineComponent({
    components: {
      IonSpinner,
      IonIcon,
      IonButton
    },
    props: {
      asyncFn: requiredType<() => Promise<any>>(Function)
    },
    setup(props) {
      const monitoredAsync = ref(undefined as Possible<PromiseState<unknown>>)

      const doAsync = () => monitoredAsync.value = monitorAsync(props.asyncFn())

      const state = computed(() => monitoredAsync.value === undefined ? "READY" : monitoredAsync.value.status)

      return {
        checkmark,
        close,
        passProps: omit(props, ["asyncFn"]),
        state,
        doAsync
      }
    }
  })
</script>
<style scoped>
  ion-icon {
    color: inherit;
  }

  ion-spinner {
    margin-right: 0.5em;
  }
</style>