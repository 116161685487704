function* lazyGenerator<T>(getter: () => T) {
  const value = getter()
  while (true) {
    yield value
  }
}

export function lazy<T>(getter: () => T) {
  const generator = lazyGenerator(getter)

  return () => generator.next().value as T
}
