import { endpointWithTokenAccess } from '@/logic/patterns/axios'
import { lazy } from '@/logic/patterns/lazy'
import { inject, provider } from '@/logic/patterns/provide'
import { Awaited } from 'big-m/dist/types/utils'
import {
  createApi
} from 'unsplash-js'
import { getUnbounceKey } from './db.service'

// TODO Unsplash, NOT unbounce. This is a typo.

// NOTE Retaining the Creative Commons API logic even though we are not actively using it, so it is easy to revert to it if Unsplash poses problems.

const stringifiedEntryList = (dictionary: Record<string, string>) => Object.entries(dictionary).map((keyValuePair) => keyValuePair.map(s => encodeURIComponent(s)).join('=')).join('&')

const SECOND_MS = 1000

export const localProvide = provider(
  () => {
    const clientId = 'fwEzRBxb9S0Wpz24YbTvqmxZPuWQHYjscpaoA0Mc'

    const clientSecret = 'huA6sRwEMXOtcQCvuU5uFLue4PlZ6joLWAVsewrlLPxlheA6i7zxBc6oGKxm6YxSqLyjBHsArLefdTE8n0jVnw1nrj5H9PBNOiM46KPsQF3nW86XEyEU1tatpGI5PUgF'

    return {
      endpoint: endpointWithTokenAccess({
        getTokenRequest: {
          method: 'POST',
          url: 'https://api.creativecommons.engineering',
          path: '/v1/auth_tokens/token/',
          body: stringifiedEntryList({
            'client_id': clientId,
            'client_secret': clientSecret,
            'grant_type': 'client_credentials'
          })
        },
        tokenStoreKey: 'creative_commons_token',
        testTokenRequest: {
          method: 'GET',
          url: 'https://api.creativecommons.engineering',
          queryParams: {
            'page_size': String(1)
          },
          path: '/v1/images'
        },
        getTokenFromResponse: (response) => {
          if (response && typeof response === 'object' && 'access_token' in response && 'expires_in' in response) {
            const {
              'access_token': token,
              'expires_in': lifespan
            } = response as {
              'access_token': string,
              'expires_in': number,
            }

            return {
              token,
              lifespan: lifespan * SECOND_MS
            }
          } else {
            throw new Error(`Malformed response when fetching token: ${JSON.stringify(response)}`)
          }
        }
      })
    }
  }
)

const CC_PAGE_SIZE = 20

export const searchCcImages = inject(
  localProvide,
  async ({
    endpoint
  }, query: {
    page?: number,
    search: string,
  }) => {
    const {
      'page_count': pageCount,
      'result_count': resultCount,
      results
    } = await endpoint<{
      'page_count': number,
      'page_size': number,
      'result_count': number,
      'results': {
        source: string,
        creator?: string,
        'creator_url'?: string,
        id: string,
        license: string,
        thumbnail: string,
        url: string,
        title: string,
        tags: string[],
      }[],
    }>(
      {
        method: 'GET',
        url: 'https://api.creativecommons.engineering',
        queryParams: {
          q: query.search,
          page: String(query.page || 1),
          'page_size': String(CC_PAGE_SIZE),
          source: [
            'woc_tech',
            // 'wellcome_collection',
            // 'thorvaldsensmuseum',
            // 'thingiverse',
            // 'statensmuseum',
            'smithsonian_zoo_and_conservation',
            //'smithsonian_postal_museum',
            //'smithsonian_portrait_gallery',
            // 'smithsonian_national_museum_of_natural_history',
            // 'smithsonian_libraries',
            // 'smithsonian_institution_archives',
            // 'smithsonian_hirshhorn_museum',
            'smithsonian_gardens',
            'smithsonian_freer_gallery_of_art',
            // 'smithsonian_cooper_hewitt_museum',
            // 'smithsonian_anacostia_museum',
            'smithsonian_american_indian_museum',
            // 'bio_diversity',
            'behance',
            // 'flickr',
            // 'deviantart',
            // 'nasa',
            'animaldiversity',
            'WoRMS',
            'CAPL'
          ].join()
        },
        path: '/v1/images'
      }
    )

    return {
      pageCount,
      resultCount,
      results: results.map(({
        creator,
        creator_url,
        id,
        license,
        thumbnail,
        title,
        source,
        tags
      }) => ({
        ...creator ? {
          creator
        } : undefined,
        ...creator_url ? {
          creatorUrl: creator_url
        } : undefined,
        id,
        license,
        title,
        url: thumbnail,
        source,
        tags: tags || []
      }))
    }
  }
)

const api = lazy(
  async () => {
    return createApi({
      accessKey: await getUnbounceKey()
    })
  }
)

export const searchUnbounceImages = async ({
  page, search
}: {
  page?: number,
  search: string,
}) => {
  const apiInstance = await api()
  const results = await apiInstance.search.getPhotos({
    page,
    query: search,
    perPage: CC_PAGE_SIZE
  })

  if (results.response) {
    const { response } = results
    return {
      pageCount: response['total_pages'],
      resultCount: response.total,
      results: response.results.map(
        r => ({
          id: r.id,
          title: r.description || r['alt_description'] || '',
          thumbUrl: r.urls.thumb,
          url: r.urls.small,
          largeUrl: r.urls.regular,
          photographer: {
            name: r.user.name,
            lastName: r.user['last_name'],
            url: r.user['portfolio_url'] || r.user.links.html
          },
          downloadNotifyUrl: r.links['download_location'],
        })
      )
    }
  } else {
    throw new Error('Failed to retrieve Unbounce images')
  }
}

// As requested by Unsplash, notify Unsplash through the specified endpoint when an image was chosen for display on a flashcard.
export async function notifyDownload(img: Awaited<ReturnType<typeof searchUnbounceImages>>['results'][0]) {
  const apiInstance = await api()
  apiInstance.photos.trackDownload({ downloadLocation: img.downloadNotifyUrl })
}
