import { Possible } from "@/types/patterns"
import { isNone, isSome, none, Option, some } from "fp-ts/lib/Option"
import { buildError, ErrorBuilder } from "./errors"
import { collapse } from "./option"

export function* intersect<T>(arr1: Iterable<T>, arr2: Iterable<T>) {
  const set1 = new Set<T>(arr1)
  const set2 = new Set<T>(arr2)
  for (const item of set1) {
    if (set2.has(item)) {
      yield item
    }
  }
}

export function* mapIterable<T, V>(iterable: Iterable<T>, mapper: (t: T, i: number) => V): Iterable<V> {
  let i = 0
  for (const value of iterable) {
    yield mapper(value, i)
    i++
  }
}

export function* filterIterable<T>(iterable: Iterable<T>, filter: (t: T, i: number) => boolean): Iterable<T> {
  let i = 0
  for (const value of iterable) {
    if (filter(value, i)) {
      yield value
    }
    i++
  }
}

export function* without<T, K>(arr: Iterable<T>, against: Iterable<T>, keyFn: (item: T) => K = (item: T) => item as unknown as K) {
  const againstAsSet = new Set(mapIterable(against, keyFn))

  for (const item of arr) {
    const key = keyFn(item)

    if (!againstAsSet.has(key)) {
      yield item
    }
  }
}

export function generateDiff<T>(former: Iterable<T>, latter: Iterable<T>) {
  const noChange = Array.from(
    intersect(
      former,
      latter
    )
  )

  const creations = Array.from(
    mapIterable(
      without(
        latter,
        noChange
      ),
      (item) => ({
        item,
        type: "CREATE" as const
      })
    )
  )

  const deletions = Array.from(
    mapIterable(
      without(
        former,
        noChange
      ),
      (item) => ({
        item,
        type: "DESTROY" as const
      })
    )
  )

  return [
    ...creations,
    ...deletions
  ]
}

export function forEach<T>(arr: Iterable<T>, fn: (t: T, i: number) => void) {
  let i = 0
  for (const value of arr) {
    fn(value, i++)
  }
}

export function* iterateOption<T>(opt: Option<T>): Iterable<T> {
  if (isSome(opt)) {
    yield opt.value
  }
}

export function maxBy<T>(arr: Iterable<T>, fn: (t: T, index: number) => number): Possible<T> {
  let maxSoFar = -Infinity
  let valWithMax: Possible<T> = undefined

  forEach(arr, (val, index) => {
    const measure = fn(val, index)

    if (measure > maxSoFar) {
      maxSoFar = measure
      valWithMax = val
    }
  })

  return valWithMax
}

export function maxByNonScalar<T>(arr: Iterable<T>, fn: (t1: T, t2: T, index1: number, index2: number) => number): Possible<T> {
  let maxSoFar = none as Option<T>
  let maxSoFarIndex = undefined as Possible<number>

  forEach(arr, (val, index) => {
    if (isNone(maxSoFar) || fn(val, maxSoFar.value, index, maxSoFarIndex!) > 0) {
      maxSoFar = some(val)
      maxSoFarIndex = index
    }
  })

  return collapse(maxSoFar)
}

export function max(arr: Iterable<number>): number {
  let maxSoFar = -Infinity

  forEach(arr, (val) => {
    const measure = val

    if (measure > maxSoFar) {
      maxSoFar = measure
    }
  })

  return maxSoFar
}

export function min(arr: Iterable<number>): number {
  let minSoFar = Infinity

  forEach(arr, (val) => {
    const measure = val

    if (measure < minSoFar) {
      minSoFar = measure
    }
  })

  return minSoFar
}

export function last<T>(arr: Iterable<T>) {
  if (Array.isArray(arr)) {
    return arr[arr.length - 1]
  } else {
    let ret: Possible<T> = undefined
    for (const i of arr) {
      ret = i
    }

    return ret
  }
}

export function tupleFirst<T>(tuple: [T, any]) {
  return tuple[0]
}

export function tupleSecond<T>(tuple: [any, T]) {
  return tuple[0]
}

export function first<T>(arr: Iterable<T>) {
  for (const i of arr) {
    return i
  }
}

export function firstInsist<T>(arr: Iterable<T>, err = "Expected to receive an item" as ErrorBuilder<[Iterable<T>]>) {
  for (const i of arr) {
    return i
  }

  throw buildError(err, arr)
}

export function* take<T>(arr: Iterable<T>, take: number) {
  let i = 0
  for (const value of arr) {
    if (i >= take) {
      return
    }

    yield value
    i++
  }
}


export function minBy<T>(arr: Iterable<T>, fn: (t: T) => number): T | undefined {
  let min = Infinity
  let minInput: T | undefined = undefined

  forEach(arr, (t: T) => {
    const val = fn(t)
    if (val < min) {
      min = val
      minInput = t
    }
  })

  return minInput
}

export function countIterable<T>(arr: Iterable<T>, condition: (t: T) => boolean = () => true) {
  let inc = 0
  for (const t of arr) {
    condition(t) && inc++
  }
  return inc
}

export function existIterable<T>(arr: Iterable<T>, condition: (t: T) => boolean = () => true) {
  for (const t of arr) {
    if (condition(t)) {
      return true
    }
  }
  return false
}

export function forEachIterable<T>(iterable: Iterable<T>, mapper: (t: T, i: number) => void): void {
  let i = 0
  for (const value of iterable) {
    mapper(value, i)
    i++
  }
}

export function sumBy<T>(arr: Iterable<T>, quant: (t: T) => number) {
  let inc = 0
  for (const t of arr) {
    inc += quant(t)
  }
  return inc
}

export function isIterable(obj: any): obj is Iterable<any> {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj[Symbol.iterator] === 'function'
}

export function* flatMap<T, V>(
  arr: Iterable<T>,
  fn: (value: T, index: number) => V | Iterable<V>
): Iterable<V> {

  let index = 0
  for (const val of arr) {
    index++

    const out = fn(val, index)

    if (isIterable(out)) {
      yield* out
    } else {
      yield out
    }
  }
}

export function isOverlap<T>(iter1: Iterable<T>, iter2: Iterable<T>) {
  const iter1AsSet = iter1 instanceof Set ? iter1 : new Set(iter1)

  for (const item of iter2) {
    if (iter1AsSet.has(item)) {
      return true
    }
  }

  return false
}

export function arrayWindow<T>(arr: T[], start = 0, end = Infinity) {
  return {
    *[Symbol.iterator]() {
      for (let i = start; i < end && i < arr.length; i++) {
        yield arr[i]
      }
    }
  }
}


export function stitchedArrayWindow<T>(arr: T[][], start = 0, end = Infinity) {
  return {
    *[Symbol.iterator]() {
      let yielded = 0
      let toSkip = start
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
          if (toSkip > 0) {
            toSkip--
            continue
          } else {
            if (yielded < end - start) {
              yield arr[i][j]
              yielded++
            } else {
              return
            }
          }
        }
      }
    }
  }
}
