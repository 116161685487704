<template>
  <div class="play--icon">
    <IonIcon :icon="musicalNotes" class="play--icon__note" />
    <IonIcon :icon="playMode ? pause : play" class="play--icon__state" />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  import {
    IonIcon
  } from '@ionic/vue'

  import {
    pause,
    play,
    musicalNotes
  } from 'ionicons/icons'
import { requiredPrimitive } from '@/logic/patterns/vue'

  export default defineComponent({
    props: {
      playMode: requiredPrimitive(Boolean)
    },
    components: {IonIcon},
    setup() {
      return {
        pause,
        play,
        musicalNotes
      }
    }
  })
</script>
<style>
ion-icon {
  cursor: pointer;
}

.play--icon__note {
  background: #386dcc;
  border-radius: 100%;
  color: #6e9ae4;
  padding: 3px;
  position: relative;
}

.play--icon {
  position: relative;
  user-select: none;
  vertical-align: middle;
}

.play--icon__note {
  vertical-align: middle;
}

.play--icon__state {
  position: absolute;
  left: 4px;
  top: 3px;
  color: #ffffffc2;
}
</style>
