export function scalarSort<T>(fn: (t: T) => string | number) {
  return (a: T, b: T) => {
    const scalarA = fn(a)
    const scalarB = fn(b)
    if (scalarA > scalarB) {
      return 1
    } else if (scalarA < scalarB) {
      return -1
    } else {
      return 0
    }
  }
}

export function spliceOut<T>(arr: T[], fn: (t: T) => boolean) {
  const index = arr.findIndex(fn)

  if (index === -1) {
    return false
  } else {
    arr.splice(index, 1)
    return true
  }
}

export function insertAtomic<T, ID>(arr: T[], item: T, idFn: (t: T) => ID) {
  const itemId = idFn(item)
  const indexOfPreexisting = arr.findIndex(i => idFn(i) === itemId)
  indexOfPreexisting === -1 ? arr.push(item) : arr.splice(indexOfPreexisting, 1, item)
}

export function spliceOutItem<T>(arr: T[], item: T) {
  const index = arr.indexOf(item)

  if (index === -1) {
    return false
  } else {
    arr.splice(index, 1)
    return true
  }
}

export function replaceInto<T>(arr: T[], item: T, searchFn: (i: T) => boolean) {
  const index = arr.findIndex(searchFn)

  if (index === -1) {
    throw new Error("Expected value to substitute to be found but it was not found")
  } else {
    arr[index] = item
  }

  return arr
}

function indexOfFirstSuperior<T>(arr: T[], el: T, fn: (a: T, b: T) => number) {
  function recurse(arr: T[], el: T, fn: (a: T, b: T) => number, offset: number): number {
    if (arr.length === 0) {
      return 0
    } else if (arr.length === 1) {
      if (fn(el, arr[0]) > 0) {
        return offset + 1
      } else if (fn(el, arr[0]) === 0) {
        // In case of a tie, incoming value should be inserted after existing
        return offset + 1
      } else {
        // Toss control over to other fork of recursion, or element will be
        // inserted at start.
        return 0
      }
    } else {
      const recurseLeft = recurse(arr.slice(0, arr.length / 2), el, fn, offset)
      const recurseRight = recurse(arr.slice(arr.length / 2, arr.length), el, fn, offset + Math.floor(arr.length / 2))
      return recurseRight || recurseLeft
    }
  }

  return recurse(arr, el, fn, 0)
}

export function insertSortedAtomic<T, ID>(arr: T[], el: T, fn: (a: T, b: T) => number, idFn: (i: T) => ID) {
  const itemId = idFn(el)
  const preexistingIndex = arr.findIndex(i => itemId === idFn(i))
  if (preexistingIndex !== -1) {
    arr.splice(preexistingIndex, 1)
  }

  const index = indexOfFirstSuperior(arr, el, fn)
  arr.splice(index, 0, el)
  return index
}

export function insertSorted<T>(arr: T[], el: T, fn: (a: T, b: T) => number) {
  const index = indexOfFirstSuperior(arr, el, fn)
  arr.splice(index, 0, el)
  return index
}

export function pushTo<T>(arr: T[]) {
  return arr.push.bind(arr)
}

export function* deal<T>(arr: T[], n: number) {
  if (n > arr.length) {
    throw new Error('Trying to deal more elements than available in list')
  } else {
    const unusedIndices = arr.map((_, i) => i)

    let iter = n
    while (iter > 0) {
      const indexOfUnusedIndex = Math.floor(
        Math.random() * unusedIndices.length
      )
      const index = unusedIndices.splice(indexOfUnusedIndex, 1)[0]
      yield arr[index]

      iter--
    }
  }
}

export function last<T>(arr: T[]) {
  return arr[arr.length - 1]
}

export function reorder<T>(arr: T[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

export function pickWithForwardBias<T>(arr: T[], p: number, avoidLast = false as false | "avoidLast") {
  if (p <= 0) {
    throw new Error(`p must be positive but it is ${p}`)
  }

  if (arr.length === 1) {
    return arr[0]
  } else {
    const lastItem = last(arr)

    for (; ;) {
      for (const item of arr) {
        if (avoidLast && item === lastItem) {
          continue
        }

        const r = Math.random()
        if (r < p) {
          return item
        }
      }
    }
  }
}

export function fromIterableSorted<T>(iterable: Iterable<T>, sortFn: (t1: T, t2: T) => number): T[] {
  const retArr: T[] = []
  for (const item of iterable) {
    insertSorted(
      retArr,
      item,
      sortFn
    )
  }

  return retArr
}
