import firebase from 'firebase/app'
import 'firebase/database'
import { pipe } from 'fp-ts/lib/function'
import { none, some, Option } from 'fp-ts/lib/Option'
import { computed, reactive, Ref, ref, unref, watch } from 'vue'
import {
  Observable
} from "rxjs"
import { map } from "rxjs/operators"
import { Possible } from 'big-m/dist/types/utils'
import { insertAtomic, insertSortedAtomic, pushTo, scalarSort, spliceOut } from '../patterns/arrays'
import { combine, debounce, execute, match, memoize, methodOf, noop, pick } from '../patterns/functions'
import { consumeKey, valuesIterable } from '../patterns/objects'
import { lazy } from '../patterns/lazy'
import { forEach, generateDiff, iterateOption, mapIterable } from '../patterns/iterables'
import { tuple } from '../patterns/tuple'
import { pipeObservableToSubscriber, statefulObservable, syncObservable, waitForObservable } from '../patterns/observable'
import { consume, optionOnMap } from '../patterns/map'
import { getOrFill } from 'big-m'
import { ms } from '../patterns/async'

type WrappedRef<T> = {
  observable: () => Observable<T>,
  promise: () => Promise<T>,
  increment: (by?: number) => Promise<void>,
  decrement: (by?: number) => Promise<void>,
  ref: () => Ref<Possible<T>>,
  set: (newItem: T) => Promise<void>,
}

export type IdOnly<T extends { id: string | number }> = { id: T["id"] }
export type CollectionObservableEmit<T extends { id: string | number }> = {
  item: T,
  event: "ADD" | "CHANGE" | "REMOVE",
}

export type RelaxedCollectionObservableEmit<T extends { id: string | number }> = {
  item: T,
  event: "ADD" | "CHANGE",
} | {
  item: IdOnly<T>,
  event: "REMOVE",
}

export type DneEnabledRelaxedCollectionObservableEmit<T extends { id: string | number }> = {
  item: T,
  event: "ADD" | "CHANGE",
} | {
  item: IdOnly<T>,
  event: "REMOVE",
  doesNotExist?: true,
}

export type DeltaEmit<T> = {
  item: T,
  event: "ADD" | "CHANGE" | "REMOVE",
}

export type SimpleDeltaEmit<T> = {
  item: T,
  event: "ADD" | "REMOVE",
}

export function statefulObservableFromFirebaseCollectionRef<V extends { id: string | number }>(firebaseRef: firebase.database.Query, entityFilterFunction: (t: V) => boolean) {
  const childMap: Map<V["id"], V> = reactive(new Map())
  const preFilterEntityCountRef = ref(0)
  const noMemoryStatefulObservable = statefulObservable<DeltaEmit<V>>(
    ({ next }) => {
      const onChildRemoved = (x: V) => {
        childMap.delete(x.id)
        next({ item: x, event: "REMOVE" })
      }

      const childAddedCallback = (snapshot: firebase.database.DataSnapshot) => pipe(
        snapshot.val(),
        x => {
          preFilterEntityCountRef.value++
          if (entityFilterFunction(x)) {
            childMap.set(x.id, x)
            next({ item: x, event: "ADD" })
          } else {
            onChildRemoved(x)
          }
        }
      )

      firebaseRef.on(
        "child_added",
        childAddedCallback
      )

      const childChangedCallback = (snapshot: firebase.database.DataSnapshot) => pipe(
        snapshot.val(),
        x => {
          if (entityFilterFunction(x)) {
            childMap.set(x.id, x)
            next({ item: x, event: "CHANGE" })
          } else {
            onChildRemoved(x)
          }
        }
      )
      firebaseRef.on(
        "child_changed",
        childChangedCallback
      )

      const childRemovedCallback = (snapshot: firebase.database.DataSnapshot) => pipe(
        snapshot.val(),
        x => {
          preFilterEntityCountRef.value--
          onChildRemoved(x)
        }
      )

      firebaseRef.on(
        "child_removed",
        childRemovedCallback
      )

      return {}
    })

  const trueStatefulObservable = statefulObservable<DeltaEmit<V>>(
    ({ next }) => {
      noMemoryStatefulObservable.subscribe(
        next
      )

      return {
        eventQueue: () => mapIterable(
          childMap,
          ([, value]) => ({ event: "ADD", item: value })
        )
      }
    }
  )

  return {
    preFilterEntityCountRef,
    reactiveMap: childMap,
    observable: trueStatefulObservable,
    observeChild: memoize(
      (id: V["id"]) => statefulObservable<DeltaEmit<V>>(
        ({ next }) => {
          noMemoryStatefulObservable.subscribe(
            emitted => emitted.item.id === id && next(emitted)
          )

          return {
            eventQueue: () => mapIterable(
              iterateOption(
                optionOnMap(childMap, id)
              ),
              item => ({ event: "ADD", item })
            )
          }
        }
      )
    )
  }
}

export function statefulObservableFromFirebasePrimitiveArrayRef<V extends string | number>(ref: firebase.database.Query) {
  const childSet: Set<V> = new Set()
  return statefulObservable<SimpleDeltaEmit<V>>(
    ({ next }) => {
      const childAddedCallback = (snapshot: firebase.database.DataSnapshot) => pipe(
        snapshot.val(),
        x => {
          childSet.add(x)
          next({ item: x, event: "ADD" })
        }
      )

      ref.on(
        "child_added",
        childAddedCallback
      )
      ref.on(
        "child_changed",
        childAddedCallback
      )

      const childRemovedCallback = (snapshot: firebase.database.DataSnapshot) => pipe(
        snapshot.val(),
        x => {
          childSet.delete(x)
          next({ item: x, event: "REMOVE" })
        }
      )
      ref.on(
        "child_removed",
        childRemovedCallback
      )

      return {
        eventQueue: () => mapIterable(
          childSet,
          item => ({ event: "ADD", item })
        )
      }
    })
}

export function databaseWrapper(db: firebase.database.Database) {
  const WrappedRef = memoize(
    function WrappedRef<T>(path: string): WrappedRef<T> {
      function increment(by = 1): Promise<void> {
        return db.ref(path).set(firebase.database.ServerValue.increment(by))
      }

      function decrement(by = 1): Promise<void> {
        return db.ref(path).set(firebase.database.ServerValue.increment(-by))
      }

      const innerObservable = function innerObservable() {
        return statefulObservable(
          ({ next }: { next: (t: T) => void }) => {
            const ref = db.ref(path)
            let yieldedValue: Option<T> = none

            const valueCallback = (snapshot: firebase.database.DataSnapshot) => pipe(
              snapshot.val(),
              (value: T) => {
                yieldedValue = some(value)
                next(value)
              }
            )

            ref.on(
              "value",
              valueCallback
            )

            return {
              eventQueue: () => iterateOption(yieldedValue),
            }
          }
        )
      }

      const observable = lazy(innerObservable)

      function ref() {
        return syncObservable(observable()).ref
      }

      async function promise() {
        return waitForObservable(observable())
      }

      async function set(item: T) {
        return db.ref(path).set(item)
      }

      return {
        observable,
        promise,
        increment,
        decrement,
        ref,
        set
      }
    }
  )

  const WrappedPrimitiveArray = memoize(
    function WrappedPrimitiveArray<T extends string | number>(path: string) {
      return {
        observable() {
          return statefulObservableFromFirebasePrimitiveArrayRef<T>(db.ref(path)) as Observable<SimpleDeltaEmit<T>>
        }
      }
    }
  )

  const WrappedCollection = function WrappedCollection<V extends { id: number | string }>(
    path: string,
    entityFilterFunction: (item: V) => boolean = () => true
  ) {
    const rootRef = db.ref(path)

    function initializeObservable() {
      return statefulObservableFromFirebaseCollectionRef<V>(rootRef, entityFilterFunction)
    }

    const initialized = lazy(initializeObservable)

    const observeSingleChild = memoize(function observeSingleChild(keyId: V["id"]): Observable<DeltaEmit<V>> {
      return initialized().observeChild(keyId)
    })

    async function querySingleChild(keyId: V["id"]): Promise<Possible<V>> {
      const ref = db.ref(path).child(String(keyId))

      const value = (await ref.get()).val()
      return value === null || !entityFilterFunction(value) ? undefined : value
    }

    function saveChild(item: V): Promise<void> {
      const { id } = item
      const ref = db.ref(path)
      return ref.child(String(id)).set(item)
    }

    function saveDescendant(item: V, innerPath: string[], value: any) {
      const { id } = item
      const ref = db.ref(`${path}/${id}/${innerPath.join("/")}`)
      return ref.set(value)
    }

    function removeDescendant(item: V, innerPath: string[]) {
      const { id } = item
      const ref = db.ref(`${path}/${id}/${innerPath.join("/")}`)
      return ref.remove()
    }

    function remove({ id }: V): Promise<void> {
      const dbRef = db.ref(`${path}/${id}`)
      return dbRef.remove()
    }

    function dryRunRemove(path: string) {
      console.warn(`Dry run: would remove ${path}`)
    }

    async function removeByMatchedValue<K extends keyof V & string>(key: K, value: V[K]) {
      const ref = db.ref(path)

      const queryVal: string | number | boolean | null = typeof value === "string" ? value
        : typeof value === "number" ? value
          : value === null ? null
            : value === undefined ? null
              : String(value)

      const snapshot = await ref.equalTo(queryVal, key).get()
      const promises = snapshot.val() === null ? [] : Object.keys(snapshot.val()).map( /* key => db.ref(`${path}/${key}`).remove() */ dryRunRemove)
      return Promise.all(promises).then(() => promises.length)
    }


    async function loadFullSnapshot() {
      return pipe(
        await rootRef.get(),
        snapshot => snapshot.val() as Record<string, V>,
        valuesIterable,
        iterable => mapIterable(iterable, value => tuple(value.id, value)),
        iterable => new Map(iterable)
      )
    }

    return {
      saveDescendant,
      removeDescendant,
      remove,
      removeByMatchedValue,
      saveChild,
      observeSingleChild,
      querySingleChild,
      loadFullSnapshot,
      observable: () => initialized().observable,
      reactiveMap: () => initialized().reactiveMap,
      preFilterEntityCountRef: () => initialized().preFilterEntityCountRef
    }
  }

  return {
    WrappedRef,
    WrappedCollection,
    WrappedPrimitiveArray
  }
}

export function storageWrapper(storage: firebase.storage.Storage) {
  return {
    getDownloadUrl(path: string) {
      return storage.ref(path).getDownloadURL() as Promise<string>
    },
    saveBlob(path: string, data: Blob | Uint8Array | ArrayBuffer) {
      return storage.ref(path).put(data)
    },
    deleteFile(path: string) {
      return storage.ref(path).delete()
    }
  }
}

export type ListenForMatches<T extends { id: string | number }> = (matchFn: (item: T) => boolean, array: T[]) => {
  array: {
    id: string | number,
  }[],
  dispose: () => void,
}


type CollectionQuery<T> = (i: T) => boolean
type SortDefinition<T> = (i1: T, i2: T) => number
type SortedCollectionQuery<T> = {
  queryFn?: CollectionQuery<T>,
  sortFn?: SortDefinition<T>,
}

/**
 * Initiate waiting on a Firebase Observable, loading its data stream into a Reactive array. Per the observed behaviour of Firebase, all currently known-about data will be loaded in first, then data will be fetched from the database, and lastly the system will react to any update notifications.
 *
 * The data will be filtered and sorted according to supplied parameters. When these parameters change, the existing dataset is immediately purged and the new result set progressively loaded in.
 *
 * @param observable The underlying Observable, emitting events piped from a Firebase ref. These are objects with the key 'item' being the emitted object and
 * 'event' being the event type: 'ADD' | 'CHANGE' | 'REMOVE'.
 * @param queryParamGenerator A function which will serve as a Computed function,
 * supplying the underlying data for the filtering and sorting operations.
 * @param queryFnGenerator A function that is re-run each time queryParams change
 * (per Vue's change detection). It generates either a single function, this being
 * a filter function which will run on every supplied item of the Observable; or
 * an object with keys 'queryFn' and 'sortFn'. These are the query and sort
 * functions respectively. The sortFn uses the same structure as `Array.sort()`.
 */
export function reactiveDeltaListenerArray<T extends { id: string | number }, QueryParams>(observable: Observable<RelaxedCollectionObservableEmit<T>>, queryParamGenerator: () => QueryParams, queryFnGenerator:
  (o: QueryParams) => CollectionQuery<T> | SortedCollectionQuery<T>): { receivedArray: T[], unsubscribe: () => void } {
  const receivedArray = [] as T[]
  const outputArray = reactive([]) as T[]
  const writeReceivedToOutput = debounce(
    () => outputArray.splice(0, outputArray.length, ...receivedArray),
    200
  )

  let unsubscribePrevious: () => void = noop
  const queryParams = computed(queryParamGenerator)
  const watchStopHandle = watch(queryParams, newQueryParams => {
    unsubscribePrevious()

    receivedArray.splice(0, receivedArray.length)

    const generatedFnRet = queryFnGenerator(newQueryParams)
    const queryFn = typeof generatedFnRet === "function" ? generatedFnRet : generatedFnRet.queryFn
    const sortFn = typeof generatedFnRet === "function" ? undefined : generatedFnRet.sortFn

    const subscription = observable.subscribe(
      ({ event, item }) => {
        if (["ADD", "CHANGE"].includes(event) && (!queryFn || queryFn(item as T))) {
          sortFn ? insertSortedAtomic(
            receivedArray,
            item as T,
            sortFn,
            pick("id")
          ) : insertAtomic(
            receivedArray,
            item,
            pick("id")
          )
        } else {
          spliceOut(
            receivedArray,
            match({ id: item.id })
          )
        }

        writeReceivedToOutput()
      }
    )

    unsubscribePrevious = subscription.unsubscribe.bind(subscription)
  }, { immediate: true })

  return {
    receivedArray: outputArray,
    unsubscribe: combine(watchStopHandle, unsubscribePrevious)
  }
}

export function arrayRefToDeltaEmitter<T>(underlyingArray: Ref<Possible<T[]>>): Observable<SimpleDeltaEmit<T>> {
  return new Observable(
    subscriber => {
      const watchStopHandle = watch(
        underlyingArray,
        (newValue, oldValue) => {
          const [newArr, oldArr] = [newValue || [], oldValue || []]
          const delta = generateDiff(oldArr, newArr)
          delta.forEach(
            ({
              item,
              type
            }) => subscriber.next(
              {
                event: type === "CREATE" ? "ADD" : "REMOVE",
                item
              }
            )
          )
        },
        { immediate: true }
      )

      return watchStopHandle
    }
  )
}

/**
 * @param observable The source of delta events to monitor.
 * @param query A modifier specifying sorting and filtering, if required.
 * @returns A reactive Array that will update itself in response to delta events.
 */
export function dbListenerArray<T extends { id: string | number }>(observable: Observable<RelaxedCollectionObservableEmit<T>>, query?: CollectionQuery<T> | SortedCollectionQuery<T>) {
  const receivedArray = reactive([]) as T[]
  const queryFn = typeof query === "function" || typeof query === "undefined" ? query : query.queryFn
  const sortFn = typeof query === "function" || typeof query === "undefined" ? undefined : query.sortFn

  const subscription = observable.subscribe(
    ({ event, item }) => {
      if (["ADD", "CHANGE"].includes(event) && (!queryFn || queryFn(item as T))) {
        sortFn ? insertSortedAtomic(
          receivedArray,
          item as T,
          sortFn,
          pick("id")
        ) : insertAtomic(
          receivedArray,
          item,
          pick("id")
        )
      } else {
        spliceOut(
          receivedArray,
          match({ id: item.id })
        )
      }
    }
  )

  return {
    receivedArray,
    unsubscribe: subscription.unsubscribe.bind(subscription)
  }
}

type Primitive = string | number | boolean | null | undefined

/**
 * @param observable The source of delta events to monitor.
 * @param query A modifier specifying sorting and filtering, if required.
 * @returns A reactive Set that will update itself in response to delta events.
 */
export function dbListenerSet<T extends Primitive>(observable: Observable<SimpleDeltaEmit<T>>, query?: CollectionQuery<T>) {
  const receivedSet = reactive(new Set()) as Set<T>

  const subscription = observable.subscribe(
    ({ event, item }) => {
      if (event === "REMOVE" || (query && !query(item))) {
        receivedSet.delete(item)
      } else {
        receivedSet.add(item)
      }
    }
  )

  return {
    receivedSet,
    unsubscribe: subscription.unsubscribe.bind(subscription)
  }
}

/**
 * Given an Observable that emits creation and deletion events for an ID,
 * and an Observable that emits creation and deletion events for a database
 * with that ID as its primary key, return an Observable that emits update events
 * for the watched objects only. 
 * 
 * @param idObservable The event stream for object IDs.

 * @param request A function that will be called to explicitly load
 * the object.
 */
export function dbJoinObservable<T extends { id: string | number }, O extends T = T>(
  idObservable: Observable<SimpleDeltaEmit<T["id"]>>,
  request: (id: T["id"]) => Observable<RelaxedCollectionObservableEmit<O>>
): Observable<RelaxedCollectionObservableEmit<O>> {
  return new Observable(
    subscriber => {
      const unsubscribeHandles = new Map<T["id"], () => void>()

      idObservable.subscribe(
        async ({ event, item }) => {
          if (event === "REMOVE") {
            consume(
              unsubscribeHandles,
              item,
              execute
            )

            subscriber.next({ event: "REMOVE", item: { id: item } })
          } else {
            getOrFill(
              unsubscribeHandles,
              item,
              () => methodOf(
                pipeObservableToSubscriber(
                  request(item),
                  subscriber
                ),
                "unsubscribe"
              )
            )
          }

          return () => {
            forEach(
              unsubscribeHandles.values(),
              execute
            )
            unsubscribeHandles.clear()
          }
        },
        error => subscriber.error(error)
      )
    }
  )
}

export function dbJoinArray<T extends { id: string | number }>(
  idArrRef: Ref<T["id"][]>,
  request: (id: T["id"]) => Observable<RelaxedCollectionObservableEmit<T>>
) {
  const arrayChangeObservable = arrayRefToDeltaEmitter(idArrRef)
  const valuesObservable = dbJoinObservable(
    arrayChangeObservable,
    request
  )
  return dbListenerArray(
    valuesObservable,
    {
      sortFn: scalarSort(t => unref(idArrRef).indexOf(t.id))
    }
  )
}

export const byIdDescending = scalarSort<{ id: number }>(
  t => -t.id
)

export const byIdAscending = scalarSort<{ id: number }>(
  t => t.id
)

export function pickAndEnrolToDispose<T>(dispose: (() => void)[]) {
  return (retObj: { receivedArray: T[], unsubscribe: () => void }) => pipe(
    retObj,
    obj => consumeKey(
      obj,
      "unsubscribe",
      pushTo(dispose)
    ),
    pick("receivedArray")
  )
}

export function deltaObservableToSimpleObservable<T extends { id: string | number }>(observable: Observable<DneEnabledRelaxedCollectionObservableEmit<T>>): Observable<Possible<T>>
export function deltaObservableToSimpleObservable<T extends { id: string | number }>(observable: Observable<RelaxedCollectionObservableEmit<T>>): Observable<Possible<T>> {
  return observable.pipe(
    map(
      ({ event, item }) => event === "REMOVE" ? undefined : item as T
    )
  )
}

export function patientObserver<T extends { id: string | number }>(
  observeEntity: (id: T["id"]) => Observable<DeltaEmit<T>>,
  fetchEntity: (id: T["id"]) => Promise<Possible<T>>,
  timeoutMs: number
): (a: T["id"]) => Observable<DneEnabledRelaxedCollectionObservableEmit<T>> {
  return (id: string | number) => new Observable(
    subscriber => {
      const observable = observeEntity(id)
      pipeObservableToSubscriber(observable, subscriber)

      void (async () => {
        const receivedWithinTimeout = await Promise.race([
          waitForObservable(observable).then(() => true),
          ms(timeoutMs).then(() => false)
        ])

        if (!receivedWithinTimeout) {
          const value = await fetchEntity(id)

          if (value) {
            subscriber.next({ event: "ADD", item: value })
          } else {
            subscriber.next({ event: "REMOVE", item: { id }, doesNotExist: true })
          }
        }
      })()
    }
  )
}

export function filterDeltaObservable<T extends { id: number | string }>(observable: Observable<RelaxedCollectionObservableEmit<T>>, filterFn: (t: T) => boolean): Observable<RelaxedCollectionObservableEmit<T>> {
  return observable.pipe(
    map(
      emitted => {
        if (emitted.event === "REMOVE") {
          return emitted
        } else if (filterFn(emitted.item)) {
          return emitted
        } else {
          return { event: "REMOVE" as const, item: emitted.item }
        }
      }
    )
  )
}
