import { Possible } from "@/types/patterns"

// Thanks javascripttutorial.net https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/#:~:text=Use%20the%20getBoundingClientRect()%20method%20to%20get%20the%20size%20of,in%20the%20viewport%20or%20not.
export function isWithinViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function isInScroll(element: HTMLElement) {
  let childrenScrollHeight = 0
  // TODO: more like could-do, why did the element not have an accurate scroll height such that it was necessary to query its children? This is used for the 'forced' infinite scroll.
  for (const child of element.childNodes) {
    // @ts-expect-error - scrollHeight is available but not defined on DOM Node
    childrenScrollHeight += child.scrollHeight || 0
  }

  return childrenScrollHeight > element.clientHeight
}

export function isScrolledPastContainerHeight(element: HTMLElement): boolean {
  return element.offsetHeight > 0 // If element has no height, we can't meaningfully determine this; serves as a fallback in case input was nonsensical.
    && element.scrollTop >= element.offsetHeight
}

export function scrollToTop(element: HTMLElement) {
  element.scrollTop = 0
}

export function getModalScrollableElement() {
  return (document.querySelector('.modal-wrapper ion-content')?.shadowRoot?.querySelector('[part=scroll]') || undefined) as Possible<HTMLElement>
}

const ELEMENT_NODE_TYPE = 1
function getCurrentlyDisplayedPage() {
  const routerOutlet = document.querySelector('ion-router-outlet')

  if (routerOutlet === null) {
    return undefined
  }

  const candidatePage = routerOutlet.querySelector('.ion-page:not(.ion-page-hidden)') as null | HTMLElement

  if (candidatePage && candidatePage?.style.display !== 'none') {
    return candidatePage
  } else {
    // We're in an environment where pages are hidden by setting the style directly.
    for (const page of routerOutlet.childNodes) {
      if (page.nodeType === ELEMENT_NODE_TYPE && (page as HTMLElement).style.display !== 'none') {
        return page as HTMLElement
      }
    }
  }

  return undefined
}

export function getMainContentScrollableElement() {
  const page = getCurrentlyDisplayedPage()

  if (page === undefined) {
    return undefined
  }

  return (page.querySelector('ion-content')?.shadowRoot?.querySelector('[part=scroll]') || undefined) as Possible<HTMLElement>
}
