const MINUTE_MS = 60 * 1000
const HOUR_MS = 60 * 60 * 1000
const YUKON_HOURS_OFF_GREENWICH_PERMANENT = -7

export function startOfDayYukonTimeMs(date: Date) {
  const startOfDay = new Date(date).setHours(0, 0, 0, 0)
  const inUtcMs = startOfDay.valueOf() + (date.getTimezoneOffset() * MINUTE_MS)
  return inUtcMs + YUKON_HOURS_OFF_GREENWICH_PERMANENT * HOUR_MS
}

export function endOfDayYukonTimeMs(date: Date) {
  return startOfDayYukonTimeMs(date) + 24 * HOUR_MS - 1
}

export function isSameDayYukonTime(date1: Date, date2: Date) {
  const millisecondsIntoDay = date2.valueOf() - startOfDayYukonTimeMs(date1)
  return millisecondsIntoDay > 0 && millisecondsIntoDay < 24 * HOUR_MS
}
