import { foldingGet, getOrFill } from 'big-m'
import { fold, named, pick, thrower } from './functions'
import { lazy } from './lazy'
import { pipe } from 'rxjs'
import { Awaited } from '@/types/patterns'

export const wrapSubstitutionContext = Symbol("Wrap Substitution Context")

export function SubstitutionContext(name = "Anonymous", substitutions: [() => any, () => any][] = []) {
  const substitutionMap = new Map<() => any, () => any>(substitutions)

  return {
    name,
    substitutionMap,
    substitute<T>(provider: () => T, substitutable: () => T) {
      substitutionMap.set(provider, substitutable)
    },
    substituteAll(...args: [() => any, () => any][]) {
      args.forEach(([provider, substitutable]) => this.substitute(provider, substitutable))
    },
    unSubstituteProviders(...providers: (() => any)[]) {
      providers.forEach(provider => (substitutionMap.delete(provider)))
    },
    clearSubstitutions() {
      substitutionMap.clear()
    }
  }
}

export type SubstitutionContext = ReturnType<typeof SubstitutionContext>

const globalSubstitutionContext = SubstitutionContext("Global")

export { globalSubstitutionContext }

let substitutionPolicy = "ALLOW" as "ALLOW" | "PROHIBIT" | "REQUIRE"

export const getSubstitutionPolicy = () => substitutionPolicy

export function setSandboxed() {
  substitutionPolicy = "REQUIRE"
}

export function setDefault() {
  substitutionPolicy = "ALLOW"
}

export function setLive() {
  substitutionPolicy = "PROHIBIT"
}

type SearchMonadNotFound<I> = { searchKey: I, found: false }
type SearchMonadFound<I, O> = { searchKey: I, found: true, result: O }
type SearchMonad<I, O> = SearchMonadFound<I, O> | SearchMonadNotFound<I>

function searchIfNotYetFound<I, O>(fn: (input: I) => SearchMonad<I, O>) {
  return (s: SearchMonad<I, O>) => s.found ? s : fn(s.searchKey)
}

function searchMonadOnMap<I, O>(map: Map<I, O>, key: I): SearchMonad<I, O> {
  return foldingGet(
    map,
    key,
    (result) => ({ found: true, result, searchKey: key }) as SearchMonad<I, O>,
    () => ({ found: false, searchKey: key }) as SearchMonad<I, O>
  )
}

function searchMonad<I, O>(key: I) {
  return {
    found: false, searchKey: key
  } as SearchMonad<I, O>
}

export function provider<T>(initializeDependency: () => T): Providable<T> {
  const defaultDependency = lazy(initializeDependency)

  if (substitutionPolicy === "PROHIBIT") {
    return defaultDependency
  } else {
    const weakDependencyMap = new WeakMap<SubstitutionContext, T>()

    return (substitutionContext = globalSubstitutionContext) => getOrFill(
      weakDependencyMap as Map<SubstitutionContext, T>,
      substitutionContext,
      pipe(
        () => initializeDependency,
        fold<() => T, SearchMonad<() => T, () => T>>(
          () => substitutionContext === globalSubstitutionContext,
          searchMonad,
          key => searchMonadOnMap(
            substitutionContext.substitutionMap,
            key
          )
        ),
        searchIfNotYetFound(
          initializeDependency => searchMonadOnMap(
            globalSubstitutionContext.substitutionMap,
            initializeDependency
          )
        ),
        fold<SearchMonad<() => T, () => T>, T>(
          pick("found"),
          (i) => (i as SearchMonadFound<() => T, () => T>).result(),
          fold(
            () => substitutionPolicy === "REQUIRE",
            thrower(() => `No substitution found for ${initializeDependency.name ? `provider "${initializeDependency.name}"` : `unnamed provider`} and default provider may not be used because function is running in sandboxed environment.\nAdd the substitution by calling substitute() with a suitable mock for the provider.`),
            defaultDependency
          )
        )
      )
    )
  }
}

export type Injectable<T extends any[], V, DependencyShape extends Record<string, any>> = (dependency: DependencyShape, ...args: T) => V;
export type Providable<DependencyShape> = (substitutionContext?: SubstitutionContext) => DependencyShape;

type WrapSubstitutionContext<T extends any[], V> = (newSubstitutionContext: SubstitutionContext) => (...args: T) => V;

type Injected<T extends any[], V> = ((...args: T) => V) & { [wrapSubstitutionContext]: WrapSubstitutionContext<T, V> }

export function inject<T extends any[], V, DependencyShape extends Record<string, any>>(provider: Providable<DependencyShape>, fn: Injectable<T, V, DependencyShape>) {
  const substitutionContext = globalSubstitutionContext

  const callFn = named(
    ((...args: T) => fn(provider(substitutionContext), ...args)),
    fn.name
  ) as any as Injected<T, V>

  function wrapSubstitutionContextFn(newSubstitutionContext: SubstitutionContext) {
    return (...args: T) => fn(provider(newSubstitutionContext), ...args)
  }

  callFn[wrapSubstitutionContext] = wrapSubstitutionContextFn

  return callFn
}

export async function injectAsync<T extends any[], V, DependencyShape extends Record<string, any>>(provider: Providable<Promise<DependencyShape>>, fn: Injectable<T, V, DependencyShape>) {
  const substitutionContext = globalSubstitutionContext

  const callFn = named(
    async (...args: T) => fn(await provider(substitutionContext), ...args),
    fn.name
  ) as any as Injected<T, Promise<V>>

  function wrapSubstitutionContextFn(newSubstitutionContext: SubstitutionContext) {
    return async (...args: T) => fn(await provider(newSubstitutionContext), ...args)
  }

  callFn[wrapSubstitutionContext] = wrapSubstitutionContextFn

  return callFn
}


/**
 * Compose providers to create a new provider that provides the dependencies of every input provider.
 * Collisions between dependency names are resolved in favour of the latter provider in the list.
 */
export function composeProviders<T1 extends Record<string, any>, T2 extends Record<string, any>, T3 extends Record<string, any>, T4 extends Record<string, any>, T5 extends Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>, provider3: Providable<T3>, provider4: Providable<T4>, provider5: Providable<T5>): Providable<T1 & T2 & T3 & T4 & T5>
export function composeProviders<T1 extends Record<string, any>, T2 extends Record<string, any>, T3 extends Record<string, any>, T4 extends Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>, provider3: Providable<T3>, provider4: Providable<T4>): Providable<T1 & T2 & T3 & T4>
export function composeProviders<T1 extends Record<string, any>, T2 extends Record<string, any>, T3 extends Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>, provider3: Providable<T3>): Providable<T1 & T2 & T3>
export function composeProviders<T1 extends Record<string, any>, T2 extends Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>): Providable<T1 & T2>
export function composeProviders<T1 extends Record<string, any>>(provider1: Providable<T1>): Providable<T1>
export function composeProviders<T1 extends Record<string, any>>(...providers: Providable<Record<string, any>>[]): Providable<T1> {
  return (ctx?: SubstitutionContext) => {
    const retObj = {} as Record<string, any>

    providers.forEach(
      provider => Object.assign(retObj, provider(ctx))
    )

    return retObj as any
  }
}

export function composeProvidersAsync<T1 extends Promise<Record<string, any>> | Record<string, any>, T2 extends Promise<Record<string, any>> | Record<string, any>, T3 extends Promise<Record<string, any>> | Record<string, any>, T4 extends Promise<Record<string, any>> | Record<string, any>, T5 extends Promise<Record<string, any>> | Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>, provider3: Providable<T3>, provider4: Providable<T4>, provider5: Providable<T5>): Providable<Promise<Awaited<T1> & Awaited<T2> & Awaited<T3> & Awaited<T4> & Awaited<T5>>>
export function composeProvidersAsync<T1 extends Promise<Record<string, any>> | Record<string, any>, T2 extends Promise<Record<string, any>> | Record<string, any>, T3 extends Promise<Record<string, any>> | Record<string, any>, T4 extends Promise<Record<string, any>> | Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>, provider3: Providable<T3>, provider4: Providable<T4>): Providable<Promise<Awaited<T1> & Awaited<T2> & Awaited<T3> & Awaited<T4>>>
export function composeProvidersAsync<T1 extends Promise<Record<string, any>> | Record<string, any>, T2 extends Promise<Record<string, any>> | Record<string, any>, T3 extends Promise<Record<string, any>> | Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>, provider3: Providable<T3>): Providable<Promise<Awaited<T1> & Awaited<T2> & Awaited<T3>>>
export function composeProvidersAsync<T1 extends Promise<Record<string, any>> | Record<string, any>, T2 extends Promise<Record<string, any>> | Record<string, any>>(provider1: Providable<T1>, provider2: Providable<T2>): Providable<Promise<Awaited<T1> & Awaited<T2>>>
export function composeProvidersAsync<T1 extends Promise<Record<string, any>> | Record<string, any>>(provider1: Providable<T1>): Providable<Promise<Awaited<T1>>>
export function composeProvidersAsync(...providers: Providable<Promise<Record<string, any>> | Record<string, any>>[]) {
  return async (ctx: SubstitutionContext) => {
    const retObj = {} as Record<string, any>

    await Promise.all(
      providers.map(
        async provider => {
          const provided = await provider(ctx)
          Object.assign(retObj, provided)
        }
      )
    )

    return retObj
  }
}

/**
 * Take already injected functions and turn them into a new provider.
 *
 * The provider may then be injected into some other function. When wrapSubstitutionContext()
 * is obtained from the resulting function and called with a substitutionContext, the injected
 * functions will inherit that same substitutionContext.
 */
export function provideInjected<InjectedFunctions extends Record<string, (...args: any[]) => any>>(injectedFunctions: InjectedFunctions): Providable<InjectedFunctions> {
  return (ctx?: SubstitutionContext) => {
    return Object.fromEntries(
      Object.entries(injectedFunctions).map(
        ([functionName, fn]) => [functionName, wrapSubstitutionContext in fn ? (fn as any)[wrapSubstitutionContext](ctx) : fn]
      )
    ) as any
  }
}

export function injectWithSecondaries<T extends T[], V, DependencyShape extends Record<string, any>, InjectedFunctions extends Record<string, (...args: any[]) => any>>(provider: Providable<DependencyShape>, secondaryDependencies: InjectedFunctions, fn: Injectable<T, V, DependencyShape & InjectedFunctions>) {
  return inject(
    composeProviders(
      provider,
      provideInjected(secondaryDependencies)
    ),
    fn
  )
}

export function injectWithSecondariesAsync<T extends T[], V, DependencyShape extends Record<string, any>, InjectedFunctions extends Record<string, (...args: any[]) => any>>(provider: Providable<Promise<DependencyShape>>, secondaryDependencies: InjectedFunctions, fn: Injectable<T, Promise<V>, DependencyShape & InjectedFunctions>) {
  return injectAsync(
    // @ts-expect-error Doesn't accept that Awaited<InjectedFunctions> and InjectedFunctions are equivalent types
    composeProvidersAsync(
      provider,
      provideInjected<InjectedFunctions>(secondaryDependencies)
    ),
    fn
  )
}

/**
 * Given an already existing provider, and a function that returns a new value based on its output,
 * produce a new provider.
 *
 * The original provider and the newly returned one may be used in conjunction,
 * with any substitution context, and the underlying provide() function will still not be called more
 * than once.
 */
export function extendProvider<I, O>(lastProvider: Providable<I>, extend: (i: I) => O): Providable<O> {
  const initializeDependency = () => extend(lastProvider())
  const defaultDependency = lazy(initializeDependency)

  if (substitutionPolicy === "PROHIBIT") {
    return defaultDependency
  } else {
    const weakDependencyMap = new WeakMap<SubstitutionContext, O>()

    return (substitutionContext = globalSubstitutionContext) => getOrFill(
      weakDependencyMap as Map<SubstitutionContext, O>,
      substitutionContext,
      pipe(
        () => initializeDependency,
        fold<() => O, SearchMonad<() => O, () => O>>(
          () => substitutionContext === globalSubstitutionContext,
          searchMonad,
          key => searchMonadOnMap(
            substitutionContext.substitutionMap,
            key
          )
        ),
        searchIfNotYetFound(
          initializeDependency => searchMonadOnMap(
            globalSubstitutionContext.substitutionMap,
            initializeDependency
          )
        ),
        fold<SearchMonad<() => O, () => O>, O>(
          pick("found"),
          (i) => (i as SearchMonadFound<() => O, () => O>).result(),
          fold(
            () => substitutionPolicy === "REQUIRE",
            thrower(() => `No substitution found for ${extend.name ? `provider "${extend.name}"` : `unnamed provider`} and default provider may not be used because function is running in sandboxed environment.\nAdd the substitution by calling substitute() with a suitable mock for the provider.`),
            defaultDependency
          )
        )
      )
    )
  }
}

/**
 * Call an injected function such that the given substitution context is passed on to its
 * provider.
 */
export async function withSubstitutions<T extends any[], V>(substitutionContext: SubstitutionContext, fn: Injected<T, V>, ...args: T) {
  return fn[wrapSubstitutionContext](substitutionContext)(...args)
}

/**
 * How to use:
 * To define a reuseable dependency, call provide with a function that initializes the dependency.
 * To use the dependency, call inject with the provider and a function that uses the dependency. The
 * output will be a function. Export that function and call it from anywhere else in your code.
 * To test, set the global substitution context. This lets you define overrides for the provider
 * functions so you can replace their outputs with mocks. You can use any normal mocking library for
 * this.
 * If your tests only run sequentially, you can set the global substitution context to a desired state
 * prior to running the test and check it again afterwards.
 * If your tests may run in parallel, you will need to make use of the withSubstitutions() function.
 * This allows you to run an injected function with a substitution context that is scoped to that call,
 * such that no other calls of the dependency will result in calls to the defined substitutes.
 * However, this has the limitation that if your injected function calls other injected functions, they
 * will not inherit the substitution context you specified. To get around this, you must incorporate
 * all of these functions into the injection's provider. This is easily done with the
 * injectWithSecondaries() and injectWithSecondariesAsync() functions. Each takes a normal provider
 * as its first argument, an object of injected functions as its second argument, and the Injectable
 * function as its third.
 */
