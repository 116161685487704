import { databaseWrapper, dbJoinArray, dbJoinObservable, filterDeltaObservable, patientObserver, RelaxedCollectionObservableEmit } from "@/logic/firebase/wrapper"
import { deal, pickWithForwardBias, scalarSort, spliceOutItem } from "@/logic/patterns/arrays"
import {
  filterIterable,
  first,
  firstInsist,
  mapIterable
} from "@/logic/patterns/iterables"
import {
  composeProviders,
  extendProvider,
  inject,
  provideInjected,
  provider
} from "@/logic/patterns/provide"
import { flow, pipe } from "fp-ts/lib/function"
import { fold, fromNullable, isSome } from "fp-ts/lib/Option"
import { provide } from "./provide/app"
import "firebase/app"
import { getLoggedInUserSync } from "./state.service"
import { lazy } from "@/logic/patterns/lazy"
import { asImage, filterImage, Media, Image } from "@/types/models/media.model"
import { Deck, hasCards } from "@/types/models/deck.model"
import { filterer, Flashcard, isPublic, visibleChecker } from "@/types/models/flashcard.model"
import { UserLikes } from "@/types/models/user.model"
import { filterAsync, identityAsync, mapPromise, parallel, toArray } from "@/logic/patterns/async"
import { composeFilters, identity, match, memoize, methodOf, noop, pick } from "@/logic/patterns/functions"
import { getValidWordOfTheDayId, WordOfTheDay } from "@/types/models/wotd.model"
import { unref } from "vue"
import { computed } from "vue"
import { Observable } from 'rxjs'
import {
  mergeMap,
  switchMap
} from 'rxjs/operators'
import { from } from 'rxjs'
import { waitForObservableCondition } from "@/logic/patterns/observable"
import { waitForRefCondition } from "@/logic/patterns/vue"
import { getOrFail } from "big-m"

export const localProvide = extendProvider(
  provide,
  ({ database }) => {
    const {
      WrappedRef,
      WrappedCollection,
      WrappedPrimitiveArray
    } = databaseWrapper(
      database
    )

    const userId = () => getLoggedInUserSync().uid

    const deckPath = "/flamelink/environments/production/content/decks/en-US"
    const cardPath = "/flamelink/environments/production/content/flashcards/en-US"

    const cardCount = () => WrappedRef<number>('/flamelink/environments/production/aggregate/cards/count')
    const deckCount = () => WrappedRef<number>('/flamelink/environments/production/aggregate/decks/count')

    return {
      backgroundMediaId: () => WrappedRef<[number]>("/flamelink/environments/production/content/backgroundImage/en-US/img"),
      welcomeMediaId: () => WrappedRef<[number]>("/flamelink/environments/production/content/welcomeImage/en-US/img"),
      flashcardCount: () => WrappedRef<[number]>("/flamelink/collection"),
      mediaFile: (id: number) => { return WrappedRef<Media>(`/flamelink/media/files/${id}`) },
      mediaFileCollection: lazy(() => WrappedCollection<Media>(`/flamelink/media/files`)),
      audioFile: (id: number) => { return WrappedRef<Media>(`/flamelink/media/audio/${id}`) },
      audioFileCollection: lazy(() => WrappedCollection<Media>(`/flamelink/media/audio`)),
      decks: () => WrappedCollection<Deck>(deckPath),
      cards: () => WrappedCollection<Flashcard>(cardPath),
      userLikes: lazy(() => WrappedCollection<UserLikes>("/flamelink/environments/production/aggregate/userLikes")),
      userCardLikes: memoize((id: string) => WrappedPrimitiveArray<number>(`/flamelink/environments/production/aggregate/userLikes/${id}/cards`)),
      userDeckLikes: memoize((id: string) => WrappedPrimitiveArray<number>(`/flamelink/environments/production/aggregate/userLikes/${id}/decks`)),
      cardCount,
      deckCount,
      loggedInUser: getLoggedInUserSync,
      wordOfTheDay: () => WrappedRef<WordOfTheDay | null>(
        '/flamelink/environments/production/content/wordOfTheDay/en-US'
      ),
      unbounceKey: () => WrappedRef<string>(
        '/flamelink/environments/production/key/unbounce'
      ),
      neodymium: () => WrappedCollection<{ id: string, user: string }>(
        '/flamelink/environments/production/content/neodymium/en-US'
      ),
      userId
    }
  }
)

export const neodymiumUsers = provider(
  inject(localProvide, ({ neodymium }) => {
    const neodymiumUserIds = neodymium().loadFullSnapshot()

    return {
      neodymiumUsers: lazy(() => neodymiumUserIds.then(nid => new Set(
        mapIterable(
          nid.values(),
          pick("user")
        )
      )))
    }
  })
)

export const isNeodymiumAsync = inject(
  neodymiumUsers,
  async function isNeodymiumAsync({ neodymiumUsers }) {
    const nu = await neodymiumUsers()
    return ({ author, public: p }: { author: string, public?: boolean }) => nu.has(author) && !!p
  }
)

export const isLoggedInUserNeodymium = inject(
  composeProviders(
    localProvide,
    neodymiumUsers
  ),
  async function isNeodymiumAsync({ neodymiumUsers, loggedInUser }) {
    const nu = await neodymiumUsers()
    return nu.has(loggedInUser().uid)
  }
)

export const getWordOfTheDay = inject(
  localProvide,
  function getWordOfTheDay({ wordOfTheDay }) {
    return wordOfTheDay().observable()
  }
)

export const setWordOfTheDay = inject(
  localProvide,
  function ({ wordOfTheDay }, arg: WordOfTheDay) {
    return wordOfTheDay().set(arg)
  }
)

export const getMediaById = inject(
  localProvide,
  function ({ mediaFile }, id: number) {
    return mediaFile(id).promise()
  }
)

/**
 * Fetch an audio file by its ID.
 * This is an entity in the realtime database; the actual file is stored in Firebase Storage.
 */
export const getAudioById = inject(
  localProvide,
  function ({ audioFile }, id: number) {
    return audioFile(id).promise()
  }
)

export const getBackgroundFile = inject(
  localProvide,
  async function getBackgroundFile({ backgroundMediaId, mediaFile }) {
    const mediaId = pipe(
      await backgroundMediaId().promise(),
      firstInsist
    )
    const file = pipe(
      await mediaFile(mediaId).promise(),
      asImage
    )

    return file
  }
)

export const getWelcomeFile = inject(
  localProvide,
  async function getBackgroundFile({ welcomeMediaId, mediaFile }) {
    const mediaId = pipe(
      await welcomeMediaId().promise(),
      firstInsist
    )
    const file = pipe(
      await mediaFile(mediaId).promise(),
      asImage
    )

    return file
  }
)

const HARD_CODED_NEODYMIUM_ONLY_IMAGE_IDS = [1616999769843, 1617158636263]

export const getAllImages = inject(
  composeProviders(
    localProvide,
    provideInjected({
      isLoggedInUserNeodymium
    })
  ),
  async function getAllImages({ mediaFileCollection, isLoggedInUserNeodymium }) {
    const snap = await mediaFileCollection().loadFullSnapshot()

    const isNeodymium = await isLoggedInUserNeodymium()

    return pipe(
      snap,
      m => m.values(),
      iter => filterIterable(
        iter,
        x => isSome(
          filterImage(x)
        )
      ),
      iter => filterIterable(
        iter,
        m => isNeodymium || !HARD_CODED_NEODYMIUM_ONLY_IMAGE_IDS.includes(m.id)
      ),
      iter => [...iter] as Image[]
    )
  }
)

const generateId = () => Number(new Date()) * 32 + Math.floor(Math.random() * 32)

type PossiblyWithoutId<T extends { id: string | number }> = Omit<T, "id"> & { id?: T["id"] | undefined }
type PossiblyWithoutAuthor<T extends { author: string, authorName: string }> = Omit<T, "author" | "authorName"> & { author?: T["author"] | undefined, authorName?: T["authorName"] | undefined }

export const saveDeck = inject(
  localProvide,
  ({ decks, deckCount, loggedInUser }, deck: PossiblyWithoutId<PossiblyWithoutAuthor<Deck>>) => {
    return parallel(addPromiseToQueue => {
      if (deck.id === undefined) {
        deck.id = generateId()
        addPromiseToQueue(deckCount().increment())
      }

      if (deck.author === undefined) {
        deck.author = loggedInUser().uid
      }

      if (!deck.authorName && deck.author && deck.author === loggedInUser().uid) {
        deck.authorName = loggedInUser().displayName || undefined
      }

      return decks().saveChild(deck as Deck)
    })
  }
)


export const saveAudioFile = inject(
  localProvide,
  function saveFile({
    audioFileCollection,
    loggedInUser,
  }, { file, contentType, generation }: {
    contentType: string,
    file: string,
    generation: number,
  }) {
    const id = generateId()
    const authorId = loggedInUser().uid
    const fileEntity = {
      __meta__: {
        createdDate: String(new Date(generation)),
        createdBy: authorId
      },
      id,
      folderId: 1533011137114,
      file,
      contentType,
      type: "files" as const,
      generation
    }
    const savePromise = audioFileCollection().saveChild(fileEntity)
    return { id, savePromise, authorId, generation }
  }
)

export const saveCard = inject(
  composeProviders(
    localProvide
  ),
  ({ cards, cardCount, loggedInUser }, card: PossiblyWithoutId<PossiblyWithoutAuthor<Flashcard>>) => {
    const ultimateCardId = card.id === undefined ? generateId() : card.id

    return {
      cardId: ultimateCardId,
      savePromise: parallel(addPromiseToQueue => {
        if (card.id === undefined) {
          card.id = ultimateCardId
          addPromiseToQueue(cardCount().increment())
        }

        if (card.author === undefined) {
          card.author = loggedInUser().uid
        }

        if (!card.authorName && card.author && card.author === loggedInUser().uid) {
          card.authorName = loggedInUser().displayName || undefined
        }

        return cards().saveChild(card as Flashcard)
      })
    }
  }
)

export const toggleCardPublic = inject(
  localProvide,
  ({ cards }, card: Flashcard) => {
    card.public = !card.public

    return cards().saveChild(card)
  }
)

export const toggleDeckPublic = inject(
  localProvide,
  ({ decks }, deck: Deck) => {
    deck.public = !deck.public

    return decks().saveChild(deck)
  }
)

export const deleteDeck = inject(
  localProvide,
  ({ decks, deckCount }, deck: Deck) => {
    return Promise.all(
      [
        decks().remove(deck),
        deckCount().decrement()
      ]
    ) as any as Promise<void>
  }
)

const WAIT_FOR_NATURAL_ARRIVAL_MS = 250

export const CardFetcher = extendProvider(
  composeProviders(
    localProvide,
    provideInjected({
      isNeodymiumAsync
    })
  ),
  function CardFetcher({ cards, cardCount, isNeodymiumAsync, loggedInUser, userCardLikes }) {
    const userId = loggedInUser().uid

    const wrappedCardCollection = cards()
    const {
      observable,
      reactiveMap,
      preFilterEntityCountRef
    } = wrappedCardCollection

    const cardCountRef = cardCount().ref()

    const publicCardsObservable = lazy(
      () => filterDeltaObservable<Flashcard>(
        observable(),
        isPublic
      )
    )

    const userCreatedCardsObservable = lazy(
      () => filterDeltaObservable<Flashcard>(
        observable(),
        match({ author: userId })
      )
    )

    const neodymiumCardsObservable = lazy(
      () => from(isNeodymiumAsync()).pipe(
        mergeMap(
          isNeodymium => filterDeltaObservable(
            observable(),
            item => isNeodymium(item)
          )
        )
      )
    )

    const visibleCardsObservable = lazy(
      () => filterDeltaObservable(
        observable(),
        visibleChecker(userId)
      )
    )

    const fetchIndividualCard = wrappedCardCollection.querySingleChild
    const rawObserveIndividualCard = wrappedCardCollection.observeSingleChild
    const observeIndividualCard = patientObserver(
      rawObserveIndividualCard,
      fetchIndividualCard,
      WAIT_FOR_NATURAL_ARRIVAL_MS
    )
    const requestIndividualCard = flow(
      observeIndividualCard,
      o => waitForObservableCondition(o, ({ event }) => event !== "REMOVE"),
      mapPromise(pick("item"))
    ) as (a: number) => Promise<Flashcard>

    const userLikesObservable = lazy(
      () => {
        const userCardLikesObservable = userCardLikes(userId).observable()
        return dbJoinObservable<Flashcard>(
          userCardLikesObservable,
          id => filterDeltaObservable<Flashcard>(
            wrappedCardCollection.observeSingleChild(id),
            visibleChecker(userId)
          )
        )
      }
    )

    function waitForAllFlashcards() {
      return waitForRefCondition(
        computed(() => cardCountRef.value !== undefined && preFilterEntityCountRef().value >= cardCountRef.value),
        identity
      ) as Promise<true>
    }

    // Ensure that cards load
    observable().subscribe()

    return {
      waitForAllFlashcards,
      chooseCards: async (count: number, filterFn: (c: Flashcard) => boolean) => {
        await waitForAllFlashcards()

        const r = reactiveMap()
        const candidateCardIds = pipe(
          r,
          x => filterIterable(
            x,
            ([, value]) => filterFn(value)
          ),
          x => mapIterable(
            x,
            first
          ),
          methodOf(Array, "from")
        ) as number[]

        const randomlySelectedCardIds = deal(
          candidateCardIds,
          Math.min(count, candidateCardIds.length)
        )

        return [...mapIterable(
          randomlySelectedCardIds,
          cardId => getOrFail(
            r,
            cardId
          )
        )]
      },
      rawObservable: observable,
      publicCardsObservable,
      reactiveMap,
      elementCount: cardCountRef,
      userCreatedCardsObservable,
      userLikesObservable,
      observeIndividualCard,
      requestIndividualCard,
      neodymiumCardsObservable,
      visibleCardsObservable
    }
  }
)

export const DeckFetcher = extendProvider(
  composeProviders(
    localProvide,
    provideInjected({ saveDeck, CardFetcher, isNeodymiumAsync })
  ),
  function DeckFetcher({ decks, CardFetcher, userDeckLikes, deckCount, loggedInUser }) {
    const userId = loggedInUser().uid

    const cardFetcher = CardFetcher()
    const wrappedDeckCollection = decks()
    wrappedDeckCollection.observable().subscribe(({ event, item: deck }) => void event !== "DELETE" && deck.cards?.forEach(async (id) => {
      const fetchedCard = await cardFetcher.requestIndividualCard(id)
      if (!fetchedCard) {
        spliceOutItem(deck.cards!, id)
        await saveDeck(deck)
      }
    }))

    const {
      observable,
      reactiveMap
    } = wrappedDeckCollection

    const publicDecksObservable = lazy(
      () => filterDeltaObservable(
        observable(),
        isPublic
      )
    )

    const userCreatedDecksObservable = lazy(
      () => filterDeltaObservable(
        observable(),
        match({ author: userId })
      )
    )

    const neodymiumDecksObservable = lazy(
      () => from(isNeodymiumAsync()).pipe(
        mergeMap(
          isNeodymium => filterDeltaObservable(
            observable(),
            item => isNeodymium(item)
          )
        )
      )
    )

    const deckCountRef = deckCount().ref()

    const userLikesObservable = lazy(
      () => {
        const userDeckLikesObservable = userDeckLikes(userId).observable()
        return dbJoinObservable<Deck>(
          userDeckLikesObservable,
          id => filterDeltaObservable<Deck>(
            wrappedDeckCollection.observeSingleChild(id),
            visibleChecker(userId)
          )
        )
      }
    )

    const visibleDecksObservable = lazy(
      () => filterDeltaObservable(
        observable(),
        visibleChecker(userId)
      )
    )

    const fetchIndividualDeck = wrappedDeckCollection.querySingleChild
    const rawObserveIndividualDeck = wrappedDeckCollection.observeSingleChild
    const observeIndividualDeck = patientObserver(
      rawObserveIndividualDeck,
      fetchIndividualDeck,
      WAIT_FOR_NATURAL_ARRIVAL_MS
    )
    const requestIndividualDeck = flow(
      observeIndividualDeck,
      o => waitForObservableCondition(o, ({ event }) => event !== "REMOVE"),
      mapPromise(pick("item"))
    ) as (a: number) => Promise<Deck>

    // Ensure that decks load
    observable().subscribe()

    function waitForAllDecks() {
      return waitForRefCondition(
        computed(() => deckCountRef.value !== undefined && wrappedDeckCollection.preFilterEntityCountRef().value >= deckCountRef.value),
        identity
      ) as Promise<true>
    }

    return {
      async chooseRandomNonEmptyNeodymiumDeck() {
        await waitForAllDecks()
        const v = reactiveMap().values()
        const isNeodymium = await isNeodymiumAsync()
        const entries = await toArray(
          filterAsync(
            v,
            composeFilters(
              isNeodymium,
              hasCards
            )
          )
        )

        return pipe(
          entries,
          x => deal(x, Math.min(1, x.length)),
          first,
          fromNullable
        )
      },
      waitForAllDecks,
      reactiveMap,
      observable,
      publicDecksObservable,
      elementCount: deckCountRef,
      requestIndividualDeck,
      observeIndividualDeck,
      userCreatedDecksObservable,
      userLikesObservable,
      deckCardsReactiveArray: memoize(
        (deck: Pick<Deck, "cards">) => {
          return dbJoinArray<Flashcard>(
            computed(() => unref(deck).cards || []),
            cardFetcher.observeIndividualCard
          ).receivedArray
        }
      ),
      neodymiumDecksObservable,
      visibleDecksObservable
    }
  }
)

export const deleteCard = inject(
  composeProviders(
    localProvide,
    provideInjected({
      DeckFetcher,
      saveDeck
    })
  ),
  async ({ cards, cardCount, saveDeck, DeckFetcher }, card: Flashcard) => {
    return parallel(
      addPromiseToQueue => {
        for (const deck of DeckFetcher().reactiveMap().values()) {
          deck.cards && spliceOutItem(deck.cards, card.id) && addPromiseToQueue(saveDeck(deck))
        }
        addPromiseToQueue(
          cards().remove(card),
          cardCount().decrement()
        )
      }
    )
  }
)

export const toggleCardLiked = inject(
  composeProviders(
    localProvide,
    provideInjected({ CardFetcher })
  ),
  async ({ CardFetcher, cards, userLikes, loggedInUser }, card: Flashcard) => {
    const latestCard = await CardFetcher().requestIndividualCard(card.id)
    const liker = loggedInUser().uid

    const isLiked = latestCard.likes && liker in latestCard.likes

    if (isLiked) {
      delete latestCard.likes[liker]

      const userLikesRef = userLikes()
      const likerLikes = {
        id: liker,
        decks: [] as number[],
        cards: [] as number[],
        ...(await userLikesRef.querySingleChild(liker))
      }

      spliceOutItem(likerLikes.cards, latestCard.id)

      await Promise.all([
        ...likerLikes ? [await userLikesRef.saveChild(likerLikes)] : [],
        await cards().removeDescendant(latestCard, ["likes", liker])
      ])
    } else {
      if (latestCard.likes) {
        latestCard.likes[liker] = true
      } else {
        latestCard.likes = { [liker]: true }
      }

      const userLikesRef = userLikes()
      const likerLikes = {
        id: liker,
        decks: [] as number[],
        cards: [] as number[],
        ...(await userLikesRef.querySingleChild(liker))
      }

      if (!likerLikes.cards.includes(latestCard.id)) {
        likerLikes.cards.push(latestCard.id)
      }

      await Promise.all([
        ...likerLikes ? [await userLikesRef.saveChild(likerLikes)] : [],
        await cards().saveDescendant(latestCard, ["likes", liker], true)
      ])
    }
  }
)

export const toggleDeckLiked = inject(
  composeProviders(
    localProvide,
    provideInjected({ DeckFetcher })
  ),
  async ({ DeckFetcher, decks, userLikes, loggedInUser }, deck: Deck) => {
    const latestDeck = await DeckFetcher().requestIndividualDeck(deck.id)
    const liker = loggedInUser().uid

    const isLiked = latestDeck.likes && liker in latestDeck.likes

    if (isLiked) {
      delete latestDeck.likes[liker]

      const userLikesRef = userLikes()
      const likerLikes = {
        id: liker,
        cards: [] as number[],
        decks: [] as number[],
        ...(await userLikesRef.querySingleChild(liker))
      }


      spliceOutItem(likerLikes.decks, latestDeck.id)

      await Promise.all([
        ...likerLikes ? [await userLikesRef.saveChild(likerLikes)] : [],
        await decks().removeDescendant(latestDeck, ["likes", liker])
      ])
    } else {
      if (latestDeck.likes) {
        latestDeck.likes[liker] = true
      } else {
        latestDeck.likes = { [liker]: true }
      }

      const userLikesRef = userLikes()
      const likerLikes = {
        id: liker,
        cards: [] as number[],
        decks: [] as number[],
        ...(await userLikesRef.querySingleChild(liker))
      }

      if (!likerLikes.decks.includes(latestDeck.id)) {
        likerLikes.decks.push(latestDeck.id)
      }

      await Promise.all([
        ...likerLikes ? [await userLikesRef.saveChild(likerLikes)] : [],
        await decks().saveDescendant(latestDeck, ["likes", liker], true)
      ])
    }
  }
)

export const getOrRefreshWordOfTheDayFlashcard = inject(
  composeProviders(
    localProvide,
    provideInjected({
      getWordOfTheDay,
      setWordOfTheDay,
      saveCard,
      CardFetcher
    })
  ),
  function getOrRefreshWordOfTheDay({ CardFetcher, saveCard, getWordOfTheDay, setWordOfTheDay, userId, }): Observable<RelaxedCollectionObservableEmit<Flashcard>> {
    return getWordOfTheDay().pipe(
      mergeMap(
        wordOfTheDay => pipe(
          wordOfTheDay,
          getValidWordOfTheDayId,
          fold(
            async () => {
              const wordOfTheDayOrFreshObj = wordOfTheDay || {
                __meta__: {}
              } as WordOfTheDay

              await CardFetcher().waitForAllFlashcards()
              const candidateFlashcardCondition = await isNeodymiumAsync()
              const allCandidateFlashcards = [
                ...(
                  filterIterable(
                    CardFetcher().reactiveMap().values(),
                    candidateFlashcardCondition
                  )
                )
              ]

              if (allCandidateFlashcards.length) {
                const sortedCandidateFlashcards = allCandidateFlashcards.sort(
                  scalarSort(
                    ({ lastHighlighted }) => lastHighlighted || 0
                  )
                )

                const newHighlightedFlashcard = pickWithForwardBias(
                  sortedCandidateFlashcards,
                  0.3,
                  "avoidLast"
                )

                wordOfTheDayOrFreshObj.cardId = newHighlightedFlashcard.id
                newHighlightedFlashcard.lastHighlighted = Number(new Date())
                saveCard(newHighlightedFlashcard).savePromise.catch(noop)
              }

              wordOfTheDayOrFreshObj.__meta__.lastModifiedAt = String(new Date())
              wordOfTheDayOrFreshObj.__meta__.lastModifiedBy = userId()
              setWordOfTheDay(wordOfTheDayOrFreshObj).catch(noop)
              return wordOfTheDayOrFreshObj.cardId
            },
            identityAsync
          )
        )
      ),
      switchMap(
        cardId => CardFetcher().observeIndividualCard(cardId)
      )
    )
  }
)

export const generateFilterer = lazy(inject(
  composeProviders(
    localProvide,
    provideInjected({
      isNeodymiumAsync
    })
  ),
  async ({
    userId,
    isNeodymiumAsync
  }) => {
    const isNeodymium = await isNeodymiumAsync()
    return filterer({
      isMine: (e: Flashcard | Deck) => e.author === userId(),
      isLikedByMe: (e: Flashcard | Deck) => e.likes && (userId() in e.likes),
      isNeodymium
    })
  }
))

export const getUnbounceKey = inject(
  localProvide,
  ({
    unbounceKey
  }) => {
    return unbounceKey().promise()
  }
)

export const purgeUserData = inject(
  localProvide,
  async ({
    cards,
    decks,
    userLikes,
    // cardCount,
    // deckCount,
  }) => {
    const loggedInUser = getLoggedInUserSync()
    const promises = [] as Promise<any>[]

    // Delete user's cards, lower global card count
    promises.push(cards().removeByMatchedValue("author", loggedInUser.uid).then(
      /* removed => cardCount().decrement(removed) */
    ))

    // Delete user's decks, lower global deck count
    promises.push(decks().removeByMatchedValue("author", loggedInUser.uid).then(
      /* removed => deckCount().decrement(removed) */
    ))

    // Delete user's likes
    false && promises.push((async () => {
      const userLikesRef = userLikes()
      const userLikesEntity = await userLikesRef.querySingleChild(loggedInUser.uid)
      if (!userLikesEntity) {
        return
      }

      if (userLikesEntity.cards.length) {
        for (const cardId of userLikesEntity.cards) {
          const card = await cards().querySingleChild(cardId)
          if (card) {
            promises.push(cards().saveDescendant(card, ["likes", loggedInUser.uid], false))
          }
        }
      }

      if (userLikesEntity.decks.length) {
        for (const deckId of userLikesEntity.decks) {
          const deck = await cards().querySingleChild(deckId)
          if (deck) {
            promises.push(cards().saveDescendant(deck, ["likes", loggedInUser.uid], false))
          }
        }
      }

      await userLikesRef.remove(userLikesEntity)
    })())

    return Promise.all(promises)
  }
)