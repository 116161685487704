import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayIcon = _resolveComponent("PlayIcon")!

  return (_openBlock(), _createBlock(_component_PlayIcon, {
    ref: "playIcon",
    playMode: _ctx.isAudioPlaying,
    onClick: _ctx.togglePlaying
  }, null, 8, ["playMode", "onClick"]))
}