<template>
  <ModalPattern :title="cardModalTitle" :dismiss="dismiss">
    <form name="cardForm" v-if="view === 'Main'">
      <IonItem>
        <IonLabel class="input">Kaska</IonLabel>
        <IonInput v-model="cardBeingEdited.kaska" type="text" />
      </IonItem>

      <IonItem>
        <IonLabel class="input">English</IonLabel>
        <IonInput v-model="cardBeingEdited.english" type="text" />
      </IonItem>

      <IonItem>
        <IonLabel class="input">Tags</IonLabel>
        <IonInput id="tagInput" v-model="tag" type="text" @keyup.enter="addTag()" />
        <IonIcon :icon="addCircle" @click="addTag()" />
      </IonItem>

      <IonChip v-for='tag of cardBeingEdited.tags' :key='tag'>
        <IonLabel>{{tag}}</IonLabel>
        <IonIcon @click="removeTag(tag)" :icon="closeCircle"/>
      </IonChip>

      <IonItem>
        <IonLabel class="input">Notes</IonLabel>
        <IonInput v-model="cardBeingEdited.notes" type="text" />
      </IonItem>

      <IonItem>
        <IonLabel>Image</IonLabel>
        <IonThumbnail v-if="displayPic" item-start>
          <SelfDestructImage :src="displayPic" />
        </IonThumbnail>
        <IonIcon v-if="cardBeingEdited.img?.length" @click="clearImage" class="clear-image" :icon="closeCircle" />
        <IonButton @click="openImageSelector()" color="secondary" fill="outline">
          <IonIcon :icon='image' />
        </IonButton>
      </IonItem>

      <IonItem>
        <IonLabel>Audio</IonLabel>
        <IonSpinner v-if="audioUploading.status === 'LOADING'" />
        <PlayAudio v-if="cardBeingEdited.audio" class="audio-player" :audioFile="cardBeingEdited.audio" @filename="handleFilenameUpdate" />
        <h4 class='audio--filename' v-if="audioFilename">{{audioFilename}}</h4>
        <IonIcon v-if="cardBeingEdited.audio" @click="clearAudio" class="clear-audio" :icon="closeCircle" />
        <IonButton color="primary" fill="outline" @click="openAudioSelector()">
          <IonIcon :icon='mic' is-active="false"/>
        </IonButton>
      </IonItem>

      <IonItem>
        <div class='inline-grid'>
          <p>{{numDecks}}</p>
          <IonButton color="primary" fill="outline" @click="openDeckSelector()">
            <span>Add Card to Decks</span>&nbsp;&nbsp;
            <IonIcon :icon='layersOutline' />
          </IonButton>
        </div>
      </IonItem>

      <IonItem>
        <FunctionIonToggle aria-label="Public" :checked="cardBeingEdited.public" :toggle="togglePublic" />
      </IonItem>
    </form>
    <SelectImage v-if="view === 'SelectImage'" v-bind="selectImageProps" />
    <SelectAudio v-if="view === 'SelectAudio'" v-bind="selectAudioProps" />
    <AddDecksToFlashcard v-if="view === 'AddDecksToFlashcard'" v-bind="addDecksToFlashcardProps" />
    <hr class="card--divider" />
    <div>
      <AsyncButton fill="solid" color="primary" id="save" :asyncFn="upload" :disabled="!isValid">
        Save
      </AsyncButton>
    </div>
  </ModalPattern>
</template>
<script lang="ts">
import { computed, defineComponent, reactive, ref, unref, watch, Ref } from "vue"
import AddDecksToFlashcard from './upsert-card/AddDecksToFlashcard.vue'
import ModalPattern from '@/components/component-patterns/ModalPattern.vue'
import SelectImage from './upsert-card/SelectImage.vue'
import SelectAudio from './upsert-card/SelectAudio.vue'
import { CardFetcher, DeckFetcher, saveDeck, saveCard } from '@/services/db.service'
import { insertAtomic, spliceOutItem } from '@/logic/patterns/arrays'
import {
  addCircle,
  image,
  closeCircle,
  mic,
  add,
  layersOutline
} from 'ionicons/icons'
import {
  IonItem,
  IonLabel,
  IonThumbnail,
  IonSpinner,
  IonIcon,
  IonInput,
  IonChip,
  IonButton
} from '@ionic/vue'
import AsyncButton from '@/components/component-patterns/AsyncButton.vue'
import SelfDestructImage from '@/components/component-patterns/SelfDestructImage.vue'
import FunctionIonToggle from '@/components/component-patterns/FunctionIonToggle.vue'
import { Flashcard, NormalizedFlashcard } from '@/types/models/flashcard.model'
import { pickAndEnrolToDispose, reactiveDeltaListenerArray } from '@/logic/firebase/wrapper'
import { identity, match, pick } from '@/logic/patterns/functions'
import { ms, sideEffect } from '@/logic/patterns/async'
import { defined } from 'big-m/dist/types/utils'
import { none, some, fold } from 'fp-ts/lib/Option'
import { asyncRefs, monitorAsync, reuseMonitoredPromiseState, zeroPromiseState } from '@/logic/patterns/async-vue-ref'
import { getDownloadUrlFromImageIdentifier, deleteAudioFile } from '@/services/media.service'
import { pipe } from 'fp-ts/lib/function'
import { Possible } from '@/types/patterns'
import { requiredType, refAssigner } from '@/logic/patterns/vue'
import { Deck, NormalizedDeck, queryMatchDeck } from "@/types/models/deck.model"
import PlayAudio from '@/components/component-patterns/PlayAudio.vue'
import { getLoggedInUserSync } from '@/services/state.service'
import { notifyDownload } from '@/services/creative_commons.service'
import { UnsplashImage } from '@/types/models/image.model'
import { PublicPropsOf } from '@/types/vue'

function toNormalizedFlashcard
(c: Flashcard): NormalizedFlashcard {
  return {
    ...c,
    likes: c.likes || {},
    tags: c.tags || [],
    public: c.public || false,
    audio: c.audio || null
  }
}

function makeZeroFlashcard() {
  const isNeodymium = getLoggedInUserSync().email === 'guzagikeh@gmail.com'

  return {
    likes: {},
    title: "",
    tags: [] as string[],
    kaska: "",
    english: "",
    notes: "",
    audio: null,
    img: null,
    public: isNeodymium
  } as Partial<Flashcard> & NormalizedFlashcard
}

async function getCardBeingEdited(deckCtx: Possible<NormalizedDeck>, cardId: number | undefined) {
  if (cardId !== undefined && deckCtx === undefined) {
    const card = await CardFetcher().requestIndividualCard(cardId)
    if (card) {
      return toNormalizedFlashcard(card)
    }
  }

  return makeZeroFlashcard()
}

const SUCCESS_BLINK_MS = 500

export default defineComponent({
  components: {
    PlayAudio,
    AddDecksToFlashcard,
    AsyncButton,
    SelectImage,
    SelectAudio,
    SelfDestructImage,
    ModalPattern,
    FunctionIonToggle,
    IonLabel,
    IonItem,
    IonThumbnail,
    IonSpinner,
    IonIcon,
    IonInput,
    IonChip,
    IonButton
  },
  props: {
    cardId: Number,
    deckContext: Object as () => NormalizedDeck & Partial<Deck>,
    dismiss: requiredType<() => void>(Function)
  },
  setup(props) {
    const dispose = [] as (() => void)[]
    const receivedArrayFn = pickAndEnrolToDispose<Deck>(dispose)

    const view = ref("Standby" as "Standby" | "Main" | "AddDecksToFlashcard" | "SelectImage" | "SelectAudio")

    const {
      result: cardBeingEdited,
      loading
     } = asyncRefs<Partial<Flashcard> & NormalizedFlashcard>(
      getCardBeingEdited(props.deckContext, props.cardId),
      makeZeroFlashcard()
    )

    watch(
      loading,
      l => l || (view.value = 'Main')
    )

    const isValid = computed(() => !!cardBeingEdited.value.kaska && !!cardBeingEdited.value.english)

    const deckFetcher = DeckFetcher()
    const deckFilter = ref("")
    const associatedDecks = computed(
      () => cardBeingEdited.value.id === undefined ? reactive([] as Deck[]) : pipe(
        reactiveDeltaListenerArray(
          deckFetcher.observable(),
          () => ({id: cardBeingEdited.value.id}),
          ({id}) => ({cards}) => !!cards && cards.includes(id!)
        ),
        receivedArrayFn
      )
    )

    const filteredAllDecks = pipe(
      reactiveDeltaListenerArray(
        deckFetcher.userCreatedDecksObservable(),
        () => ({id: cardBeingEdited.value.id, filter: deckFilter.value}),
        ({filter}) => queryMatchDeck(filter)
      ),
      receivedArrayFn
    )

    const tag = ref("")

    function addTag() {
      const tags = (tag.value.includes('#') ? tag.value.split('#') : tag.value.split(",")).map(s => s.trim())

      tags.forEach((tag) => {
        if (tag) {
          const normalizedTag = tag.startsWith('#') ? tag : `#${tag}`
          normalizedTag && !cardBeingEdited.value.tags.includes(normalizedTag) && cardBeingEdited.value.tags.push(normalizedTag)
        }
      })
      
      tag.value = ""
    }

    function removeTag(tag: string) {
      spliceOutItem(
        cardBeingEdited.value.tags,
        tag
      )
    }

    const dismissSubModal = () => { view.value = "Main" }

    const deckDelta = ref((
      props.deckContext?.id === undefined ? [] : [{ id: props.deckContext.id, type: "ADD" }]
    ) as { id: number, type: "REMOVE" | "ADD" }[])
    const addDecksToFlashcardProps = computed(() => {
      const props: PublicPropsOf<typeof AddDecksToFlashcard> = {
        associatedDecks: unref(associatedDecks),
        filteredAllDecks: unref(filteredAllDecks),
        toggleDeck: (id: number) => {        
          const preexisting = deckDelta.value.find(match({id}))

          if (preexisting) {
            spliceOutItem(deckDelta.value, preexisting)
          } else {
            const isInOriginalSet = !!unref(associatedDecks).find(match({id}))
            deckDelta.value.push({ id, type: isInOriginalSet ? "REMOVE" : "ADD"})
          }
        },
        willBeChecked: (id: number) => {
          const preexistingDelta = deckDelta.value.find(match({id}))
          if (preexistingDelta) {
            return preexistingDelta.type === "ADD"
          } else {
            return !!unref(associatedDecks).find(match({id}))
          }
        },
        deckFilter: deckFilter.value,
        updateDeckFilter: (s: string) => deckFilter.value = s,
        dismiss: dismissSubModal
      }

    return props
  })

    const imageWasChanged = ref(false)
    function upload() {
      // In case tags are un-committed
      addTag()

      // Unsplash download notification
      if (imageWasChanged.value && cardBeingEdited.value.img && cardBeingEdited.value.img.length && typeof cardBeingEdited.value.img[0] === 'object') {
        const unsplashImage = cardBeingEdited.value.img[0]

        // Per Unsplash guidelines, *no* need to ensure that this Promise resolves
        notifyDownload(unsplashImage)
      }

      const {
        cardId,
        savePromise
      } = saveCard(cardBeingEdited.value)
      const retPromise = Promise.all(
        [
          savePromise,
          ...deckDelta.value.map(
            ({id, type}) => {
              const deck = defined(filteredAllDecks.find(match({id})), `Deck with ID ${id} appeared in delta but was not found in loaded decks`)
              
              if (deck.cards) {
                if (type === "ADD" && !deck.cards.includes(cardId)) {
                  deck.cards.push(cardId)
                } else if (type === "REMOVE") {
                  spliceOutItem(deck.cards, cardId)
                }
              } else if (type === "ADD") {
                deck.cards = [cardId]
              }

              return saveDeck(deck)
            }
          )
        ]
      )

      // If this card was created as part of the "make flashcard for deck" flow, add the card's ID to the still-being-edited deck object.
      if (props.deckContext) {
        insertAtomic(
          props.deckContext.cards,
          unref(cardBeingEdited).id,
          identity
        )
      }

      return sideEffect(
        retPromise,
        async () => {
          await ms(SUCCESS_BLINK_MS)
          props.dismiss()
        }
      )
    }

    const deckCount = computed(() => {
      const currentIds = new Set(unref(associatedDecks).map(pick("id")))
      deckDelta.value.forEach(
        ({type, id}) => {
          type === "ADD" && currentIds.add(id)
          type === "REMOVE" && currentIds.delete(id)
        }
      )
      return currentIds.size
    })
    const numDecks = computed(
      () => deckCount.value === 0 ? "Not part of any decks yet"
        : deckCount.value === 1 ? "Part of 1 deck"
        : `Part of ${deckCount.value} decks`
    )

    const openDeckSelector = () => view.value = "AddDecksToFlashcard"
    const openImageSelector = () => view.value = "SelectImage"
    const openAudioSelector = () => view.value = "SelectAudio"

    const displayPicFetch = computed(
      () => (!cardBeingEdited.value.img || !cardBeingEdited.value.img.length) ? none : some(monitorAsync(getDownloadUrlFromImageIdentifier(cardBeingEdited.value.img[0], 'smallest')))
    )

    const displayPic = computed(
      () => pipe(
        displayPicFetch.value,
        fold(
          () => undefined,
          monitored => monitored.result
        )
      )
    )

    const selectImageProps: PublicPropsOf<typeof SelectImage> = {
      dismiss: dismissSubModal,
      updateImg: (imageIdentifier: Possible<number> | UnsplashImage) => {
        cardBeingEdited.value.img = imageIdentifier === undefined ? [] : [imageIdentifier]
        imageWasChanged.value = true
      }
    }

    const audioUploading = zeroPromiseState<number>()

    const selectAudioProps = {
      dismiss: dismissSubModal,
      notifyAudioUpload: (audioFileIdPromise: Promise<number>) => {
        reuseMonitoredPromiseState(
          audioUploading,
          sideEffect(
            audioFileIdPromise,
            audioId => {
              cardBeingEdited.value.audio = [audioId]
              view.value = "Main"
            }
          )
        )
      }
    }

    const togglePublic = () => cardBeingEdited.value.public = !cardBeingEdited.value.public

    const cardModalTitle = computed(() => {
      if (props.deckContext) {
        const deckStr = props.deckContext.title ? `deck '${props.deckContext.title}'` : props.deckContext.id ? 'a deck' : 'a new deck'
        return `Make a new flashcard for ${deckStr}`
      } else {
        return props.cardId === undefined ? 'Make a new flashcard' : 'Edit a flashcard'
      }
    })

    const audioFilename: Ref<Possible<string>> = ref(undefined)
    const handleFilenameUpdate = refAssigner(audioFilename)

    const clearImage = () => {
      cardBeingEdited.value.img = null
    }

    const clearAudio = () => {
      if (cardBeingEdited.value.audio !== null) {
        deleteAudioFile(cardBeingEdited.value.audio)
      }
      cardBeingEdited.value.audio = null
      audioFilename.value = undefined
    }

    return {
      cardModalTitle,
      audioUploading,
      upload,
      cardBeingEdited,
      isValid,
      view,
      addDecksToFlashcardProps,
      tag,
      addTag,
      removeTag,
      numDecks,
      openDeckSelector,
      openImageSelector,
      displayPic,
      selectImageProps,
      selectAudioProps,
      handleFilenameUpdate,
      togglePublic,
      addCircle,
      image,
      mic,
      closeCircle,
      openAudioSelector,
      add,
      layersOutline,
      clearImage,
      clearAudio,
      audioFilename
    }
  }
})
</script>
<style scoped>
  ion-icon {
    cursor: pointer;
  }

  ion-button ion-icon {
    color: inherit;
  }

  hr {
    background: #ddd
  }

  ion-label {
    vertical-align: middle;
  }
</style>
<style>
.audio-player {
  padding-right: 0.25em;
}

.audio--filename {
  max-width: 30%;
  padding-left: 0;
  padding-right: 0.2em;
}

.clear-audio, .clear-image {
  padding-right: 1em;
  color: var(--ion-color-danger-shade);
}
</style>