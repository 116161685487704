import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

import './theme/global.css'

import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { setLoggedInUserSyncedObservable } from './services/state.service'
import { loggedInUserGeneratedSingleton } from './services/auth.service'
import { setLive } from './logic/patterns/provide'
import {
  initializeErrorMonitor
} from './error-monitor'

async function main() {
  // Disable substitutions on injected functions
  setLive()

  // Call the element loader after the platform has been bootstrapped
  defineCustomElements(window)

  /**
   * Initialize the global state tracker which services will refer to 
   */
  setLoggedInUserSyncedObservable(
    loggedInUserGeneratedSingleton()
  )

  const app = createApp(App)
    .use(IonicVue)
    .use(router)

  await router.isReady().then(() => {
    app.mount('#app')
  })
}

initializeErrorMonitor(main)
