
<template>
  <ModalPattern :title="deckId === undefined ? 'Make a new deck' : 'Edit a deck'" :dismiss="dismiss">
    <form v-if="view === 'Main'">
      <IonItem>
        <IonLabel class="input">Title</IonLabel>
        <ion-input v-model="deckBeingEdited.title" type="text"></ion-input>
      </IonItem>
      <IonItem>
        <IonLabel class="input">Tags</IonLabel>
        <IonInput id="tagInput" v-model="tag" type="text" @keyup.enter="addTag()" />
        <IonIcon :icon="addCircle" @click="addTag()" />
      </IonItem>
      <IonChip v-for='tag of deckBeingEdited.tags' :key='tag'>
        <IonLabel>{{tag}}</IonLabel>
        <IonIcon @click="removeTag(tag)" :icon="closeCircle" />
      </IonChip>
      <IonItem>
        <IonLabel>Image</IonLabel>
        <IonThumbnail v-if="displayPic" item-start>
          <SelfDestructImage :src="displayPic" />
        </IonThumbnail>
        <IonIcon v-if="deckBeingEdited.img?.length" @click="clearImage" class="clear-image" :icon="closeCircle" />
        <IonButton @click="openImageSelector()" color="secondary" fill="outline">
          <IonIcon :icon='image' />
        </IonButton>
      </IonItem>
      <IonItem>
        <div class='inline-grid'>
          <p>{{numCards}}</p>
          <IonButton color="primary" fill="outline" @click="openCardSelector()">
            <span>Add Cards</span>
            <IonIcon :icon='add' />
          </IonButton>
          <IonButton v-if="numCards" color="secondary" fill="outline" @click="openCardArranger()">
            <span>Rearrange Cards</span>
            <span class="vertical"><IonIcon :icon='repeatSharp' /></span>
          </IonButton>
        </div>
      </IonItem>
      <IonItem>
        <IonToggle aria-label="Public" v-model="deckBeingEdited.public" />
      </IonItem>
    </form>
    <AddFlashcardsToDeck v-else-if="view === 'AddFlashcardsToDeck'" v-bind="addFlashcardsToDeckProps" />
    <ReorderFlashcardsInDeck v-else-if="view === 'ReorderFlashcardsInDeck'" v-bind="reorderFlashcardsInDeckProps" />
    <SelectImage v-if="view === 'SelectImage'" v-bind="selectImageProps" />
    <hr class="card--divider" />
    <AsyncButton class='max-width center' :disabled="!isValid" id="save" ion-button icon-end block :asyncFn="upload">
      Save
    </AsyncButton>
  </ModalPattern>
</template>
<script lang="ts">
import { computed, defineComponent, ref, unref, watch } from "vue"
import AddFlashcardsToDeck from './upsert-deck/AddFlashcardsToDeck.vue'
import ReorderFlashcardsInDeck from './upsert-deck/ReorderFlashcardsInDeck.vue'
import { Deck, NormalizedDeck } from '@/types/models/deck.model'
import { DeckFetcher, saveDeck } from '@/services/db.service'
import { spliceOutItem, reorder } from '@/logic/patterns/arrays'
import AsyncButton from '@/components/component-patterns/AsyncButton.vue'
import SelectImage from './upsert-card/SelectImage.vue'
import { ms } from '@/logic/patterns/async'
import ModalPattern from '@/components/component-patterns/ModalPattern.vue'
import SelfDestructImage from '@/components/component-patterns/SelfDestructImage.vue'
import {
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonInput,
  IonToggle,
  IonChip,
  IonThumbnail
} from '@ionic/vue'
import { add, closeCircle, addCircle, image, repeatSharp } from 'ionicons/icons'
import { requiredType } from "@/logic/patterns/vue"
import { Possible } from "@/types/patterns"
import { asyncRefs, monitorAsync } from "@/logic/patterns/async-vue-ref"
import { modalMonitor } from "../UniversalModal"
import { getLoggedInUserSync } from '@/services/state.service'
import { UnsplashImage } from '@/types/models/image.model'
import { none, some, fold } from 'fp-ts/lib/Option'
import { getDownloadUrlFromImageIdentifier } from '@/services/media.service'
import { pipe } from 'fp-ts/lib/function'
import { PublicPropsOf } from '@/types/vue'

function toNormalizedDeck(d: Deck): NormalizedDeck {
  return {
    ...d,
    likes: d.likes || {},
    cards: d.cards || [],
    tags: d.tags || []
  }
}

function makeZeroDeck() {
  const isNeodymium = getLoggedInUserSync().email === 'guzagikeh@gmail.com'

  return {
    likes: {},
    title: "",
    tags: [] as string[],
    cards: [] as number[],
    public: isNeodymium,
    img: []
  } as Partial<Deck> & NormalizedDeck
}

async function getDeckBeingEdited(deck: Possible<Deck>, deckId: number | undefined) {
  if (deck) {
    return toNormalizedDeck(deck)
  } else if (deckId !== undefined) {
    const deck = await DeckFetcher().requestIndividualDeck(deckId)
    if (deck) {
      return toNormalizedDeck(deck)
    }
  }

  return makeZeroDeck()
}

const SUCCESS_BLINK_MS = 500

export default defineComponent({
  components: {
    SelfDestructImage,
    IonThumbnail,
    AddFlashcardsToDeck,
    AsyncButton,
    ModalPattern,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonInput,
    IonToggle,
    IonChip,
    SelectImage,
    ReorderFlashcardsInDeck
  },
  props: {
    deckId: Number,
    deck: {
      type: Object as () => Deck
    },
    dismiss: requiredType<() => void>(Function)
  },
  setup(props) {
    const view = ref("Standby" as "Standby" | "Main" | "AddFlashcardsToDeck" | "ReorderFlashcardsInDeck" | "SelectImage")

    const {
      result: deckBeingEdited,
      loading
     } = asyncRefs<NormalizedDeck>(
      getDeckBeingEdited(props.deck, props.deckId),
      makeZeroDeck()
    )

    watch(
      loading,
      l => l || (view.value = 'Main')
    )

    const isValid = computed(() => !!deckBeingEdited.value.title)

    const addFlashcardsToDeckProps = computed(() => {
      const props: PublicPropsOf<typeof AddFlashcardsToDeck> = {
        cards: deckBeingEdited.value.cards,
        addCard: (id: number) => void (!deckBeingEdited.value.cards.includes(id) && deckBeingEdited.value.cards.push(id)),
        removeCard: (id: number) => void (spliceOutItem(deckBeingEdited.value.cards, id)),
        dismiss: () => { view.value = "Main" },
        createCard() {
          // Once the card is created, the responsibility lies with
          // UniversalModal to bring us back here.
          modalMonitor().createFlashcardForDeck(
            unref(deckBeingEdited)
          )
        }
      }

      return props
  })

    const reorderFlashcardsInDeckProps = computed(() => {
      const props: PublicPropsOf<typeof ReorderFlashcardsInDeck> = {
        cards: deckBeingEdited.value.cards,
        moveCard: (from: number, to: number) => {
          reorder(deckBeingEdited.value.cards, from, to)
        },
        dismiss: () => {
          view.value = "Main"
        },
      }

      return props
    })

    const deckFetcher = DeckFetcher()
    const cards = computed(
      () => "id" in deckBeingEdited.value ? deckFetcher.deckCardsReactiveArray(deckBeingEdited.value) : []
    )

    const numCards = computed(
      () => cards.value.length === 0 ? "No cards selected"
        : cards.value.length === 1 ? "1 card selected"
        : `${cards.value.length} cards selected`
    )

    const openCardSelector = () => view.value = "AddFlashcardsToDeck"
    const openCardArranger = () => view.value = "ReorderFlashcardsInDeck"
    const openImageSelector = () => view.value = "SelectImage"

    const tag = ref("")

    function addTag() {
      const tags = (tag.value.includes('#') ? tag.value.split('#') : tag.value.split(",")).map(s => s.trim())

      tags.forEach((tag) => {
        if (tag) {
          const normalizedTag = tag.startsWith('#') ? tag : `#${tag}`
          normalizedTag && !deckBeingEdited.value.tags.includes(normalizedTag) && deckBeingEdited.value.tags.push(normalizedTag)
        }
      })
      
      tag.value = ""
    }

    function upload() {
      // In case tags are un-committed
      addTag()

      const retPromise = saveDeck(deckBeingEdited.value)

      retPromise.then(
        async () => {
          await ms(SUCCESS_BLINK_MS)
          props.dismiss()
        }
      )

      return retPromise
    }

    function removeTag(tag: string) {
      spliceOutItem(
        deckBeingEdited.value.tags,
        tag
      )
    }

    const dismissSubModal = () => view.value = "Main"
    const imageWasChanged = ref(false)
    const selectImageProps = {
      dismiss: dismissSubModal,
      updateImg: (imageIdentifier: Possible<number> | UnsplashImage) => {
        deckBeingEdited.value.img = imageIdentifier === undefined ? [] : [imageIdentifier]
        imageWasChanged.value = true
      }
    }

    const displayPicFetch = computed(
      () => (!deckBeingEdited.value.img || !deckBeingEdited.value.img.length) ? none : some(monitorAsync(getDownloadUrlFromImageIdentifier(deckBeingEdited.value.img[0], 'smallest')))
    )

    const displayPic = computed(
      () => pipe(
        displayPicFetch.value,
        fold(
          () => undefined,
          monitored => monitored.result
        )
      )
    )

    const clearImage = () => {
      deckBeingEdited.value.img = null
    }

    return {
      openImageSelector,
      clearImage,
      selectImageProps,
      upload,
      deckBeingEdited,
      isValid,
      view,
      addFlashcardsToDeckProps,
      reorderFlashcardsInDeckProps,
      tag,
      addTag,
      removeTag,
      numCards,
      openCardSelector,
      openCardArranger,
      add,
      displayPic,
      closeCircle,
      addCircle,
      image,
      repeatSharp
    }
  }
})

</script>
<style scoped>
ion-icon {
  cursor: pointer;
}

ion-button ion-icon {
  color: inherit;
}

hr {
  background: #ddd
}

.vertical {
  transform: rotate(90deg) scaleX(1);
  visibility: inherit;
}
</style>
