import { isOverlap } from "@/logic/patterns/iterables"
import { getPlatforms } from "@ionic/vue"
import { ref } from "vue"

let isRouterPushNavigation = false

export function setRouterPushNavigation() {
  isRouterPushNavigation = true
}

export function isRouterPushNavigationConsume() {
  const ret = isRouterPushNavigation
  isRouterPushNavigation = false
  return ret
}

export const languageToGuess = ref("ENGLISH" as "KASKA" | "ENGLISH")

export const isBrowser = () => isOverlap(
  getPlatforms(),
  ["desktop", "mobileweb"]
)

export const isMobile = () => isOverlap(
  getPlatforms(),
  ["iphone", "android"]
)

export const isIos = () => isOverlap(
  getPlatforms(),
  ["ios", "iphone", "ipad"]
)

export const isLogoutScreen = ref(false)
