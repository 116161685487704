<template>
  <h3>
    Choose from one of the available images.
  </h3>
  <form name="thumbForm" @submit.prevent>
    <IonSearchbar mode="md" class="max-width center" name="cardSearchField" placeholder="Search for an image"
        v-model="imageQuery" />
    <IonRadioGroup v-model="identifier">
      <IonList>
        <IonItem>
          <IonLabel>No Image</IonLabel>
          <IonRadio mode="ios" :value="undefined" />
        </IonItem>
        <IonItem v-for="thumb of thumbResultPage" :key="thumb.id">
          <div class="ion-item-image--wrapper">
            <IonThumbnail>
              <img :src="thumb.url" />
            </IonThumbnail>
            <p :title="thumb.tags ? thumb.tags.map(({name}) => name).join() : thumb.name || thumb.title">{{thumb.name || thumb.title}} <template v-if="thumb.source">({{thumb.source}})</template></p>
          </div>
          <IonRadio mode="ios" :value="isInternal(thumb) ? thumb.id : thumb"></IonRadio>
        </IonItem>
        <div v-if="ccPageBeingLoaded !== undefined" style="margin: 1em">
          <IonSpinner />
        </div>
        <p>Page {{resultPage}} of {{maxPossiblePages}}</p>
        <div @click="previous" v-if="resultPage > 1">Previous</div>
        <div @click="next" v-if="resultPage < maxPossiblePages">Next</div>
      </IonList>
    </IonRadioGroup>
  </form>
  <IonButton class="select-image--return" color="secondary" fill="outline" @click="dismiss()">
    <IonIcon :icon="returnDownBackOutline" />&nbsp;Done
  </IonButton>
</template>
<script lang='ts'>
import { defineComponent, computed, ref, watch } from 'vue'
import {
  IonList, IonItem, IonRadio, IonLabel, IonThumbnail, IonRadioGroup, IonButton, IonIcon, IonSearchbar, IonSpinner
} from '@ionic/vue'
import {
  returnDownBackOutline
} from 'ionicons/icons'
import { getAllImages } from '@/services/db.service'
import { monitorAsync, getOption } from '@/logic/patterns/async-vue-ref'
import { pipe } from 'fp-ts/lib/function'
import { fold } from 'fp-ts/lib/Option'
import { loadPromisesIntoArray } from '@/logic/patterns/async'
import { getDownloadUrlFromImage } from '@/services/media.service'
import {
  searchUnbounceImages
} from '@/services/creative_commons.service'
import { Awaited, Possible } from '@/types/patterns'
import { requiredType } from '@/logic/patterns/vue'
import { debounce, identity, pick } from '@/logic/patterns/functions'
import { first, stitchedArrayWindow } from '@/logic/patterns/iterables'
import { reactiveDeltaListenerArray, arrayRefToDeltaEmitter, pickAndEnrolToDispose } from '@/logic/firebase/wrapper'
import { unref } from 'vue'
import { isMobile } from '@/router/state'
import { insertAtomic } from '@/logic/patterns/arrays'
import { UnsplashImage } from '@/types/models/image.model'

function stripFileExtension(file: string) {
  return first(file.split('.'))!
}

const RESULT_PAGE_COUNT = (isMobile() ? 5 : 10) && 20

type AvailableImageSelection = {
  tags?: {
    name: string,
  }[],
  name?: string,
  title?: string,
  source?: string,
} & ({
  id: string,
  title: string,
  thumbUrl: string,
  url: string,
  largeUrl: string,
  photographer: {
    name: string,
  },
} | {
  url: string,
  name: string,
  id: number,
})

export default defineComponent({
  components: {
    IonList, IonItem, IonRadio, IonThumbnail, IonLabel, IonRadioGroup, IonButton, IonIcon, IonSearchbar, IonSpinner
  },
  props: {
    dismiss: requiredType<() => void>(Function),
    updateImg: requiredType<(id: Possible<number> | UnsplashImage) => void>(Function)
  },
  setup(props) {
    const dispose = [] as (() => void)[]

    const receivedArrayFn = pickAndEnrolToDispose<{ url: string, name: string, id: number }>(dispose)
    const allImagesPromise = monitorAsync(getAllImages())

    const imageArray = computed(
      () => pipe(
        allImagesPromise,
        getOption,
        fold(
          () => [],
          identity
        )
      )
    )

    const thumbArray = ref([] as {url: string, name: string, id: number}[])
    watch(
      imageArray,
      () => {
        thumbArray.value = []
        loadPromisesIntoArray(
          thumbArray.value,
          imageArray.value.map(async image => {
            const url = await getDownloadUrlFromImage(image, isMobile() ? 'large' : 'full')
            return {
              url,
              name: stripFileExtension(image.file).trim(),
              id: image.id
            }
          })
        )
      }
    )

    const imageQuery = ref('')
    const thumbResult = computed(
      () => {
        // Hack to make this value re-compute when thumbArray's wrapped value changes; in principle this should be unnecessary.
        if (thumbArray.value.length === -1) {
          throw new Error('Unreachable')
        }

        return pipe(
          thumbArray,
          arrayRefToDeltaEmitter,
          obs => reactiveDeltaListenerArray(
            obs,
            () => ({ input: unref(imageQuery) }),
            ({input}) => ({
              queryFn: thumb => thumb.name.toLowerCase().includes(input.toLowerCase())
            })
          ),
          receivedArrayFn
        )
      }
    )

    const resultPage = ref(1)

    const identifier = ref(undefined as Possible<number> | UnsplashImage)

    watch(
      identifier,
      props.updateImg
    )

    const ccResults = ref([] as Awaited<ReturnType<typeof searchUnbounceImages>>['results'])
    const ccPageCount = ref(undefined as Possible<number>)
    const ccResultCount = ref(undefined as Possible<number>)
    const ccPage = ref(1)

    const availableResultLength = computed(
      () => {
        return thumbResult.value.length + ccResults.value.length
      }
    )

    const maxAvailablePages = computed(
      () => {
        return Math.ceil(availableResultLength.value / RESULT_PAGE_COUNT)
      }
    )

    const maxPossiblePages = computed(
      () => {
        if (ccResultCount.value === undefined) {
          return Math.ceil((thumbResult.value.length + (imageQuery.value ? 1 : 0)) / RESULT_PAGE_COUNT)
        } else {
          return Math.ceil((thumbResult.value.length + ccResultCount.value) / RESULT_PAGE_COUNT)
        }
      }
    )

    const loadPageIntoCcResults = async () => {
      const query = imageQuery.value
      const {
        results,
        pageCount,
        resultCount
      } = await searchUnbounceImages({
        search: imageQuery.value,
        page: ccPage.value
      })

      if (imageQuery.value /** still */ === query) {
        ccResults.value = [...ccResults.value]
        results.forEach(
          r => insertAtomic(
            ccResults.value,
            r,
            pick('id')
          )
        )
        ccPageCount.value = pageCount
        ccResultCount.value = resultCount
      }
    }

    const loadPageIntoCcResultsPromise = ref(undefined as Possible<Promise<void>>)
    const ccPageBeingLoaded = ref(undefined as Possible<number>)

    const loadPageIntoCcResultsGated = async () => {
      if (ccPageBeingLoaded.value === ccPage.value) {
        return
      }

      await loadPageIntoCcResultsPromise.value
      const p = loadPageIntoCcResults()
      loadPageIntoCcResultsPromise.value = p
      ccPageBeingLoaded.value = ccPage.value

      try {
        await p
      } finally {
        if (p === loadPageIntoCcResultsPromise.value) {
          loadPageIntoCcResultsPromise.value = undefined
          ccPageBeingLoaded.value = undefined
        }
      }
    }

    watch(
      ccPageCount,
      n => {
        ccPage.value = Math.min(ccPage.value, n === undefined ? 1 : Math.max(1, n - 1))
      }
    )

    watch(
      imageQuery,
      debounce(
        () => {
          ccResults.value.splice(0)
          resultPage.value = 1

          if (imageQuery.value) {
            ccPageBeingLoaded.value = undefined
            ccPageCount.value = undefined
            ccResultCount.value = undefined
            loadPageIntoCcResultsGated()
          } else {
            ccPageCount.value = 0
            ccResultCount.value = 0
          }
        },
        1500
      ),
      { immediate: true }
    )

    const next = () => resultPage.value++
    const previous = () => resultPage.value--

    watch(
      resultPage,
      async () => {
        const availablePagesExhausted = resultPage.value > maxAvailablePages.value || (resultPage.value === maxAvailablePages.value && maxAvailablePages.value * RESULT_PAGE_COUNT > availableResultLength.value)
        
        const nextCcPageNotAlreadyLoading = ccPageBeingLoaded.value === undefined || ccPageBeingLoaded.value === ccPage.value
        
        const stillCcResultsToFetch = ccPageCount.value !== undefined && ccPage.value < ccPageCount.value

        if (availablePagesExhausted && imageQuery.value && nextCcPageNotAlreadyLoading && stillCcResultsToFetch) {
          ccPage.value++
          loadPageIntoCcResultsGated()
        }
      }
    )

    watch(
      ccPage,
      () => imageQuery.value && loadPageIntoCcResultsGated()
    )

    const thumbResultPage = computed(() => {
      return stitchedArrayWindow<AvailableImageSelection>(
        [thumbResult.value, ccResults.value],
        (resultPage.value - 1) * RESULT_PAGE_COUNT,
        resultPage.value * RESULT_PAGE_COUNT
      )
    })

    const isInternal = (thumb: {
        id: string,
        title: string,
        thumbUrl: string,
        url: string,
        largeUrl: string,
        photographer: {
          name: string,
        },
      } | {
        url: string,
        name: string,
        id: number,
      }) => thumb.url.startsWith('https://firebasestorage.googleapis.com')

    return {
      previous,
      next,
      resultPage,
      maxPossiblePages,
      thumbResultPage,
      identifier,
      returnDownBackOutline,
      imageQuery,
      ccPageCount,
      ccPageBeingLoaded,
      isInternal
    }
  }
})
</script>
<style scoped>
  .select-image--return {
    clear: both;
  }

  .ion-item-image--wrapper {
    display: flex;
    vertical-align: middle;
  }

  ion-icon {
    color: inherit;
  }

  .ion-image {
    flex-direction: row;
    display: flex;
  }

  .ion-item-image--wrapper p {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 1.1em;
  }

  ion-thumbnail.md.hydrated {
    --size: 33vh;
  }
</style>
