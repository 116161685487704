<template>
  <img v-if="noError" @load="$emit('load')" v-bind="propObj" @error="setError" />
  <span v-else />
</template>
<script lang="ts">
  import {
    defineComponent, ref, computed, watch
  } from 'vue'

  export default defineComponent({
    props: {
      src: String
    },
    setup(props) {
      const isError = ref(false)
      const setError = () => isError.value = true
      const noError = computed(() => !isError.value)

      watch(
        () => props.src,
        () => isError.value = false
      )

      return {
        noError,
        setError,
        propObj: props
      }
    }
  })
</script>