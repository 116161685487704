import { SyncedObservable, waitForObservableCondition } from "@/logic/patterns/observable"
import { getSome } from "@/logic/patterns/option"
import { Possible } from "@/types/patterns"
import { defined } from "big-m/dist/types/utils"
import { pipe } from "fp-ts/lib/function"
import { flatten, fromNullable, isSome, Some } from "fp-ts/lib/Option"
import { Option } from "fp-ts/lib/Option"
import { computed, unref } from "vue"

let loggedInUserSyncedObservable: Possible<SyncedObservable<Option<firebase.default.User>>> = undefined

export function setLoggedInUserSyncedObservable(o: SyncedObservable<Option<firebase.default.User>>) {
  loggedInUserSyncedObservable = o
}

export function getLoggedInUserSyncedObservable() {
  return defined(
    loggedInUserSyncedObservable,
    "Attempted to fetch logged in user watcher before it was set"
  )
}

export async function userLoggedIn() {
  const userLoggedInPromise = waitForObservableCondition(getLoggedInUserSyncedObservable().syncedObservable, isSome) as unknown as Promise<Some<firebase.default.User>>
  return userLoggedInPromise
}

export function getLoggedInUserRef() {
  return computed(
    () => pipe(
      getLoggedInUserSyncedObservable().ref.value,
      fromNullable,
      flatten
    )
  )
}

export function getLoggedInUserInsistRef() {
  return computed(
    () => pipe(
      getLoggedInUserRef(),
      unref,
      getSome
    )
  )
}

export function getLoginStateSync() {
  return getLoggedInUserRef().value
}

export function getLoggedInUserSync() {
  return pipe(
    getLoginStateSync(),
    t => defined(t, "User login state not yet fetched"),
    o => getSome(o, () => "User is not logged in")
  )
}
