import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "play--icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IonIcon, {
      icon: _ctx.musicalNotes,
      class: "play--icon__note"
    }, null, 8, ["icon"]),
    _createVNode(_component_IonIcon, {
      icon: _ctx.playMode ? _ctx.pause : _ctx.play,
      class: "play--icon__state"
    }, null, 8, ["icon"])
  ]))
}