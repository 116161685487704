import { getBackgroundUrl } from '@/services/media.service'

export async function addBackgroundStyling() {
  const urlPromise = getBackgroundUrl()
  const css = document.createElement('style')

  const styles = `
  #content > ion-content::part(background), #content > * > ion-content::part(background) {
    background-image: url('${await urlPromise}');
    z-index: -1;
  }
  `

  css.appendChild(document.createTextNode(styles))
  document.getElementsByTagName("head")[0].appendChild(css)
}
