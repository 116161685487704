<template>
  <h3>{{deckView}} <IonIcon :icon="arrowBackCircleOutline" class="quick-back" @click="dismiss" alt="back" /></h3>
  <div>
    <IonSearchbar mode="md" class='max-width center' :value="deckFilter" @input="e => updateDeckFilter(e.target.value)" placeholder="Search for a card" />
    <form name="deckCheckboxGroup">
      <IonList radio-group>
        <div v-for="deck of filteredAllDecks" :key="deck.id">
          <IonItem>
            <IonCheckbox @ionChange="toggleDeck(deck.id)" :checked="willBeChecked(deck.id)" class='center-text center card-check' />
            <IonLabel>Select: {{deck.title}} - {{visibleCardsInDeck.get(deck.id)?.length || 0}} {{deck.cards?.length === 1 ? 'card' : 'cards'}}</IonLabel>
          </IonItem>
        </div>
      </IonList>
    </form>
    <IonButton class="select-image--return" color="secondary" fill="outline" @click="dismiss()">
      <IonIcon :icon="returnDownBackOutline" />&nbsp;Done
    </IonButton>
  </div>
</template>
<script lang='ts'>
  import { defineComponent, computed } from 'vue'
  import { Deck } from '@/types/models/deck.model'
  import { returnDownBackOutline, arrowBackCircleOutline } from 'ionicons/icons'
  import {
    IonIcon,
    IonButton,
    IonSearchbar,
    IonList,
    IonItem,
    IonLabel,
    IonCheckbox
  } from '@ionic/vue'
  import { requiredType } from '@/logic/patterns/vue'
import { DeckFetcher } from '@/services/db.service'

  export default defineComponent({
    components: {
      IonIcon,
      IonButton,
      IonSearchbar,
      IonList,
      IonItem,
      IonLabel,
      IonCheckbox
    },
    props: {
      associatedDecks: requiredType<Deck[]>(Array),
      filteredAllDecks: requiredType<Deck[]>(Array),
      toggleDeck: requiredType<(id: number) => void>(Function),
      willBeChecked: requiredType<(id: number) => boolean>(Function),
      dismiss: {
        type: Function as any as () => (() => void),
        required: true
      },
      deckFilter: {
        type: String,
        required: true
      },
      updateDeckFilter: requiredType<(s: string) => void>(Function),
    },
    setup(props) {
      const deckView = computed(() => `Viewing ${props.filteredAllDecks.length} Deck${props.filteredAllDecks.length === 1 ? "" : "s"}`)

      const deckFetcher = DeckFetcher()
      const visibleCardsInDeck = computed(
        () => new Map(
          props.filteredAllDecks.map(
            deck => [
              deck.id,
              deckFetcher.deckCardsReactiveArray(deck)
            ]
          )
        )
      )

      return {
        deckView,
        returnDownBackOutline,
        arrowBackCircleOutline,
        visibleCardsInDeck
      }
    }
  })
</script>
<style scoped>
  ion-label {
    padding-left: 0.5em;
  }

  ion-icon {
    color: inherit;
  }

  ion-icon.quick-back {
    vertical-align: middle;
    position: relative;
    top: -2px;
    background-color: #488aff;
    border-radius: 100%;
    color: white;
  }

  ion-icon {
    cursor: pointer;
  }
</style>
