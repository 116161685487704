import { queryMatch } from "@/logic/language"
import { Deck } from "./deck.model"
import { ImageDefinition } from "./image.model"

export interface Flashcard {
  id: number,
  author: string,
  authorName: string,
  public: boolean,
  tags?: string[],
  notes: string,
  likes: Record<string, true>,
  kaska: string,
  english: string,
  lastHighlighted?: number,
  audio: ({
    filename: string,
    ref: string,
    url: string,
    user: string,
    generation: string,
  } | [number]) | null,
  img: ImageDefinition,
}

export function isPublic(entity: { public?: boolean }): boolean {
  return !!entity.public
}

export function visibleChecker(userId: string) {
  return (entity: { public?: boolean, author: string }) => entity.author === userId || !!entity.public
}

export function queryMatchCard(filter: string) {
  return (card: Flashcard) => card.authorName === filter || queryMatch(
    filter,
    [
      card.kaska,
      card.english,
      ...(card.tags || [])
    ]
  )
}

export type NormalizedFlashcard = {
  public: boolean,
  tags: string[],
  notes: string,
  likes: Record<string, true>,
  kaska: string,
  english: string,
  audio: Flashcard["audio"],
  img: Flashcard["img"],
}

export type Filter = "All" | "Mine" | "Liked" | "LFNLD"

export function filterer(
  {
    isMine,
    isLikedByMe,
    isNeodymium
  }: {
    isMine: (e: Flashcard | Deck) => boolean,
    isLikedByMe: (e: Flashcard | Deck) => boolean,
    isNeodymium: (e: Flashcard | Deck) => boolean,
  } // In non-test environment, use getFiltererArgs to generate these
) {
  return (filter: Filter) => (e: Flashcard | Deck) => {
    const isVisible = isMine(e) || isPublic(e)

    if (!isVisible) {
      return false
    } else if (filter === "All") {
      return true
    } else if (filter === "Mine") {
      return isMine(e)
    } else if (filter === "Liked") {
      return isLikedByMe(e)
    } else {
      return isNeodymium(e)
    }
  }
}