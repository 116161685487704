import { defined } from "big-m/dist/types/utils"

type FileInputEvent = {
  target: {
    files: File[],
  },
}

export function getFile(event: FileInputEvent) {
  const [file] = event.target.files
  return defined(file, "Expected a file to be received")
}
