<template>
  <h3><strong>Editing flashcards in deck</strong> <IonIcon :icon="arrowBackCircleOutline" class="quick-back" @click="dismiss" alt="back" /></h3>
  <br />
  <p>Create a new flashcard for this deck:</p>
  <IonButton class="make-a-flashcard" @click="createCard">Make a flashcard</IonButton>
  <p>…or, choose cards to add:</p>
  <br />
  <div>
    <div>
      <IonSearchbar mode="md" class='max-width center' placeholder="Search for a card"
          v-model="cardInput" />
      <IonRadioGroup v-model="filter" class="deck-chooser--filter">
        <IonListHeader class="main-label extra-contrast-option-list">Limit to</IonListHeader>
        <IonList>
          <IonItem v-for="{ value, text } of filterOptions" :key="value" >
            <IonLabel><span class="extra-contrast-option-list">{{text}}</span></IonLabel>
            <IonRadio mode="ios" :value="value"></IonRadio>
          </IonItem>
        </IonList>
      </IonRadioGroup>
    </div>
    <form>
      <IonList radio-group>
        <div v-for="card of viewableCards" :key="card.id">
          <IonItem>
            <IonCheckbox @ionChange="toggleCard(card.id)" :checked="cards.includes(card.id)" class='center-text center card-check'>
            </IonCheckbox>
            <IonLabel>Select: {{card.kaska}} - {{card.english}}</IonLabel>
          </IonItem>
        </div>
      </IonList>
    </form>
    <IonButton class="select-image--return" color="secondary" fill="outline" @click="dismiss()">
      <IonIcon :icon="returnDownBackOutline" />&nbsp;Done
    </IonButton>
  </div>
</template>
<script lang='ts'>
import { defineComponent, ref, unref } from 'vue'
import {
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonList,
  IonSearchbar,
  IonCheckbox,
  IonListHeader,
  IonRadioGroup,
  IonRadio
} from '@ionic/vue'
import { CardFetcher, generateFilterer } from '@/services/db.service'
import { pickAndEnrolToDispose, reactiveDeltaListenerArray } from '@/logic/firebase/wrapper'
import { Flashcard, queryMatchCard } from '@/types/models/flashcard.model'
import {
  returnDownBackOutline,
  arrowBackCircleOutline
} from 'ionicons/icons'
import { disposeArray, requiredType } from '@/logic/patterns/vue'
import { pipe, constFalse } from 'fp-ts/lib/function'
import { simpleAsyncRef } from '@/logic/patterns/async-vue-ref'
import { composeFilters } from '@/logic/patterns/functions'

  const getText = (filter: "All" | "Mine" | "Liked" | "LFNLD") => {
    if (filter === "All") {
      return "All cards"
    } else if (filter === "Mine") {
      return "Only my cards"
    } else if (filter === "Liked") {
      return "Only cards I liked"
    } else {
      return "LFN Language Department cards"
    }
  }

export default defineComponent({
  components: {
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonList,
    IonSearchbar,
    IonCheckbox,
    IonListHeader,
    IonRadioGroup,
    IonRadio
  },
  props: {
    createCard: requiredType<() => void>(Function),
    cards: {
      type: Array as () => number[],
      required: true
    },
    addCard: {
      type: Function as any as () => ((id: number) => undefined),
      required: true
    },
    removeCard: {
      type: Function as any as () => ((id: number) => undefined),
      required: true
    },
    dismiss: {
      type: Function as any as () => (() => undefined),
      required: true
    }
  },
  setup(props) {
    const dispose = disposeArray()
    const receivedArrayFn = pickAndEnrolToDispose<Flashcard>(dispose)
    const cardFetcher = CardFetcher()

    const cardInput = ref("")
    const filterOptions = ["All" as const, "Mine" as const, "Liked" as const, "LFNLD" as const].map(
      filter => ({
        value: filter,
        text: getText(filter)
      })
    )

    const filter = ref('All' as 'All' | 'Mine' | 'LFNLD' | 'Liked')
    const filtererInstanceAsRef = simpleAsyncRef(generateFilterer())

    const viewableCards = pipe(
      reactiveDeltaListenerArray(
        cardFetcher.visibleCardsObservable(),
        () => ({
          cardInput: cardInput.value,
          filter: unref(filter),
          filtererInstance: unref(filtererInstanceAsRef)
        }),
        ({cardInput, filter, filtererInstance}) => filtererInstance ? composeFilters(
            filtererInstance(filter),
            queryMatchCard(cardInput)
          ) : constFalse
      ),
      receivedArrayFn
    )

    const toggleCard = (id: number) => props.cards.includes(id) ? props.removeCard(id) : props.addCard(id)

    return {
      filter,
      filterOptions,
      viewableCards,
      cardInput,
      toggleCard,
      returnDownBackOutline,
      arrowBackCircleOutline
    }
  }
})
</script>
<style scoped>
  ion-label {
    padding-left: 0.5em;
  }

  ion-button ion-icon {
    color: inherit;
  }

  ion-icon {
    cursor: pointer;
  }

  ion-icon.quick-back {
    vertical-align: middle;
    position: relative;
    top: -2px;
    background-color: #488aff;
    border-radius: 100%;
    color: white;
  }

  .make-a-flashcard {
    margin-bottom: 1em;
  }
</style>