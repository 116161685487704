import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.noError)
    ? (_openBlock(), _createElementBlock("img", _mergeProps({
        key: 0,
        onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('load')))
      }, _ctx.propObj, {
        onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setError && _ctx.setError(...args)))
      }), null, 16))
    : (_openBlock(), _createElementBlock("span", _hoisted_1))
}