import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonToggle = _resolveComponent("IonToggle")!

  return (_openBlock(), _createBlock(_component_IonToggle, {
    "aria-label": "$props['aria-label']",
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
    ref: "refToggle"
  }, null, 8, ["modelValue"]))
}