import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4f8faea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonTitle = _resolveComponent("IonTitle")!
  const _component_IonToolbar = _resolveComponent("IonToolbar")!
  const _component_IonHeader = _resolveComponent("IonHeader")!
  const _component_IonCard = _resolveComponent("IonCard")!
  const _component_IonContent = _resolveComponent("IonContent")!
  const _component_IonPage = _resolveComponent("IonPage")!

  return (_openBlock(), _createBlock(_component_IonPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonHeader, { class: "logout" }, {
        default: _withCtx(() => [
          _createVNode(_component_IonToolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_IonTitle, { class: "bare-header" }, {
                default: _withCtx(() => [
                  _createTextVNode("Logout")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_IonContent, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.loggedOut)
              ? (_openBlock(), _createBlock(_component_IonCard, {
                  key: 0,
                  class: "card--standard"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("You are now logged out. "),
                    _createElementVNode("a", {
                      href: "#",
                      onclick: "return false;",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
                    }, "Log in")
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_IonCard, {
                  key: 1,
                  class: "card--standard"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("…")
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}