import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d379f1e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal--title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonCardHeader = _resolveComponent("IonCardHeader")!
  const _component_IonCardContent = _resolveComponent("IonCardContent")!
  const _component_IonContent = _resolveComponent("IonContent")!
  const _component_IonCard = _resolveComponent("IonCard")!

  return (_openBlock(), _createBlock(_component_IonCard, { class: "ion-page" }, {
    default: _withCtx(() => [
      _createVNode(_component_IonContent, null, {
        default: _withCtx(() => [
          _createVNode(_component_IonCardHeader, { class: "modal--top" }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1),
              _createElementVNode("span", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dismiss && _ctx.dismiss(...args))),
                class: "modal--dismiss"
              }, [
                _createVNode(_component_IonIcon, { icon: _ctx.close }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_IonCardContent, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(_ctx.bindProps)), undefined, true)
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}