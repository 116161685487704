import { Observable, isObservable, pipe } from 'rxjs'

import { Possible } from '@/types/patterns'
import { computed, ComputedRef, reactive, ref, Ref, unref, watch } from 'vue'
import { none, Option, some } from 'fp-ts/lib/Option'
import { waitForObservable } from './observable'
import {
  defer, siftError
} from './async'
import { pass } from './functions'

export type PromiseState<T> = {
  result: Possible<T>,
  status: "LOADING" | "SUCCESS" | "ERROR" | "READY",
  promise: Promise<T>,
  underlyingObservable?: Observable<T>,
}

function assignToPromiseState<T>(state: PromiseState<T>, status: "SUCCESS" | "ERROR"): (t: Possible<T>) => PromiseState<T> {
  return (t: Possible<T>) => {
    state.status = status
    state.result = t
    return state
  }
}

export function monitorAsync<T>(item: Promise<T> | Observable<T> | T): PromiseState<T>
export function monitorAsync<T>(item: Promise<T> | Observable<T> | T, onError: (e: Error) => T): PromiseState<T>
export function monitorAsync<V, T extends V>(item: Promise<T> | Observable<T> | T, onError: (e: Error) => Possible<V> = () => undefined, preload: V = undefined as any as V): PromiseState<V> {
  const promiseStateDeferred = defer<V>()

  const promiseState = reactive({
    result: preload,
    status: "LOADING",
    promise: promiseStateDeferred.promise,
    underlyingObservable: isObservable(item) ? item : undefined
  }) as PromiseState<V>

  Promise.resolve(
    isObservable(item) ? waitForObservable(item) : item
  ).then(
    pipe(
      pass(promiseStateDeferred.resolve),
      assignToPromiseState(promiseState, "SUCCESS"),
    )
  ).catch(
    pipe(
      pass(promiseStateDeferred.reject),
      onError,
      assignToPromiseState(promiseState, "ERROR")
    )
  )

  return promiseState
}

/**
 * Reuses a PromiseState object, while replacing the Promise, Observable or value being monitored.
 * 
 * Anything that was watching for changes on the PromiseState will be notified of the new state change process.
 * 
 * @param promiseState The pre-existing PromiseState to reuse.
 * @param newItem The new Promise, Observable or value to monitor.
 * @param onError A function to transform errors into values.
 * @param resetResult Whether to reset the result to undefined when the PromiseState is reused.
 * @returns The same PromiseState, with the new Promise, Observable or value being monitored.
 */
export function reuseMonitoredPromiseState<V>(
  promiseState: PromiseState<V>,
  newItem: Promise<V> | Observable<V> | V,
  onError: (e: Error) => Possible<V> = () => undefined,
  resetResult: Possible<"resetResult"> = undefined
) {
  if (promiseState.status === "LOADING") {
    throw new Error("Underlying Promise must have resolved before PromiseState can be reused")
  } else {
    if (resetResult) {
      promiseState.result = undefined
    }

    const promiseStateDeferred = defer<V>()

    Promise.resolve(
      isObservable(newItem) ? newItem.toPromise() : newItem
    ).then(
      pipe(
        pass(promiseStateDeferred.resolve),
        assignToPromiseState(promiseState, "SUCCESS"),
      )
    ).catch(
      pipe(
        pass(promiseStateDeferred.reject),
        onError,
        assignToPromiseState(promiseState, "ERROR")
      )
    )

    Object.assign(
      promiseState,
      {
        status: "LOADING",
        promise: promiseStateDeferred,
        underlyingObservable: isObservable(newItem) ? newItem : undefined
      }
    )

    return promiseState
  }
}

export function refreshObservationOfUnderlyingObservable<V>(
  promiseState: PromiseState<V>,
  newItem: Promise<V> | Observable<V> | V,
  onError: (e: Error) => Possible<V> = () => undefined
) {
  if (promiseState.underlyingObservable) {
    return reuseMonitoredPromiseState(promiseState, promiseState.underlyingObservable, onError)
  } else {
    throw new Error("PromiseState has no underlying Observable to wait for")
  }
}

export function zeroPromiseState<T>(status = 'SUCCESS' as 'SUCCESS' | 'READY') {
  return reactive({
    promise: Promise.resolve(),
    result: undefined,
    status
  }) as PromiseState<Possible<T>>
}

export function getOption<T>(promiseState: PromiseState<T>): Option<T> {
  if (promiseState.status === "SUCCESS") {
    return some(promiseState.result as T)
  } else {
    return none
  }
}

export function flattenPromiseState<T>(promiseState: PromiseState<T>): Ref<Possible<T>> {
  return computed(
    () => promiseState.result
  )
}

export function simpleAsyncRef<T>(promise: Promise<T>, initialValue: T): Ref<T>
export function simpleAsyncRef<T>(promise: Promise<T>): Ref<Possible<T>>
export function simpleAsyncRef<T>(promise: Promise<T>, initialValue?: T) {
  const retRef: Ref<Possible<T>> = ref(initialValue) as any
  promise.then(t => retRef.value = t)
  return retRef
}

type AsyncRefs<T> = {
  result: Ref<T>,
  error: Ref<Possible<Error>>,
  success: Ref<T>,
  loading: ComputedRef<boolean>,
}

type AsyncRefsPossible<T> = {
  result: Ref<Possible<T>>,
  error: Ref<Possible<Error>>,
  success: Ref<boolean>,
  loading: ComputedRef<boolean>,
}

export function asyncRefs<T>(promise: Promise<T>, initialValue: T): AsyncRefs<T>
export function asyncRefs<T>(promise: Promise<T>): AsyncRefsPossible<T>
export function asyncRefs<T>(promise: Promise<T>, initialValue?: T) {
  const result = simpleAsyncRef(promise, initialValue)
  const error = simpleAsyncRef(siftError(promise))
  const success = simpleAsyncRef(promise.then(() => true).catch(() => false))
  const loading = computed(() => unref(success) === undefined)

  return {
    result,
    error,
    success,
    loading
  }
}

export function promiseRefToSimpleAsyncRef<T>(
  promiseRef: Ref<Possible<Promise<T>>>,
  preserveValueUntilUpdate = false as false | "preserveValueUntilUpdate"
) {
  const valueRef: Ref<Possible<T>> = ref(undefined)
  let currentPromise: Possible<Promise<T>> = undefined
  watch(
    promiseRef,
    newPossiblePromise => {
      currentPromise = newPossiblePromise

      if (!preserveValueUntilUpdate || newPossiblePromise === undefined) {
        valueRef.value = undefined
      }

      newPossiblePromise && newPossiblePromise.then(
        value => newPossiblePromise === currentPromise && (valueRef.value = value)
      )
    }
  )
}

export function promiseRefToAsyncRefs<T>(
  promiseRef: Ref<Possible<Promise<T>>>,
  preserveValueUntilUpdate = false as false | "preserveValueUntilUpdate"
) {
  const result: Ref<Possible<T>> = ref(undefined)
  let currentPromise: Possible<Promise<T>> = undefined

  const error: Ref<Possible<Error>> = ref(undefined)
  const success: Ref<Possible<boolean>> = ref(undefined)
  const loading = computed(() => success.value === undefined)

  const dispose = watch(
    promiseRef,
    newPossiblePromise => {
      currentPromise = newPossiblePromise

      if (!preserveValueUntilUpdate || newPossiblePromise === undefined) {
        result.value = undefined
      }
      error.value = undefined
      success.value = undefined

      newPossiblePromise && newPossiblePromise.then(
        value => {
          if (newPossiblePromise === currentPromise) {
            result.value = value
            success.value = true
          }
        }
      ).catch(
        e => {
          if (newPossiblePromise === currentPromise) {
            error.value = e
            success.value = false
          }
        }
      )
    },
    { immediate: true }
  )

  return {
    result,
    error,
    loading,
    dispose
  }
}
