import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8afe8c8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { name: "deckCheckboxGroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonSearchbar = _resolveComponent("IonSearchbar")!
  const _component_IonCheckbox = _resolveComponent("IonCheckbox")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonItem = _resolveComponent("IonItem")!
  const _component_IonList = _resolveComponent("IonList")!
  const _component_IonButton = _resolveComponent("IonButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", null, [
      _createTextVNode(_toDisplayString(_ctx.deckView) + " ", 1),
      _createVNode(_component_IonIcon, {
        icon: _ctx.arrowBackCircleOutline,
        class: "quick-back",
        onClick: _ctx.dismiss,
        alt: "back"
      }, null, 8, ["icon", "onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_IonSearchbar, {
        mode: "md",
        class: "max-width center",
        value: _ctx.deckFilter,
        onInput: _cache[0] || (_cache[0] = e => _ctx.updateDeckFilter(e.target.value)),
        placeholder: "Search for a card"
      }, null, 8, ["value"]),
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_IonList, { "radio-group": "" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredAllDecks, (deck) => {
              return (_openBlock(), _createElementBlock("div", {
                key: deck.id
              }, [
                _createVNode(_component_IonItem, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_IonCheckbox, {
                      onIonChange: ($event: any) => (_ctx.toggleDeck(deck.id)),
                      checked: _ctx.willBeChecked(deck.id),
                      class: "center-text center card-check"
                    }, null, 8, ["onIonChange", "checked"]),
                    _createVNode(_component_IonLabel, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Select: " + _toDisplayString(deck.title) + " - " + _toDisplayString(_ctx.visibleCardsInDeck.get(deck.id)?.length || 0) + " " + _toDisplayString(deck.cards?.length === 1 ? 'card' : 'cards'), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_IonButton, {
        class: "select-image--return",
        color: "secondary",
        fill: "outline",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dismiss()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IonIcon, { icon: _ctx.returnDownBackOutline }, null, 8, ["icon"]),
          _createTextVNode(" Done ")
        ]),
        _: 1
      })
    ])
  ], 64))
}