<template>
  <h3>
    Choose an audio file.
  </h3>
  <form name="audioForm">
    <div v-if="recordedFile">
      <div class="recorded--audio" @click="playAudio.$el.click()">
        <div class="recording--text">Recording:</div>
        <PlayAudio :rawFile="recordedFile" ref="playAudio" />
      </div>
      <IonButton @click="finalizeRecording" expand="block">
        <IonIcon :icon="cloudUpload"></IonIcon>
        <IonLabel>Upload Recording</IonLabel>
      </IonButton>
    </div>
    <IonButton @click="endAudioRecording" v-else-if="isRecording">
      <IonIcon :icon="micOffCircleOutline"></IonIcon>
      <IonLabel>Stop Recording Audio</IonLabel>
    </IonButton>
    <IonButton @click="beginAudioRecording" v-else-if="!isBrowser">
      <IonIcon :icon="micCircleOutline"></IonIcon>
      <IonLabel>Record Audio</IonLabel>
    </IonButton>
    <IonButton @click="audioInput.click()" :color="isBrowser ? 'primary' : 'secondary'">
      <IonIcon :icon="musicalNotesOutline"></IonIcon>
      <IonLabel>Upload {{isBrowser ? '' : 'Existing '}}Audio File</IonLabel>
    </IonButton>
    <input type="file" hidden @change="uploadAudio($event)" ref="audioInput"
    accept="audio/*" />
  </form>
  <IonButton class="select-image--return" color="secondary" fill="outline" @click="dismiss()">
    <IonIcon :icon="returnDownBackOutline" />&nbsp;Done
  </IonButton>
</template>
<script lang='ts'>
import { defineComponent, onBeforeMount, ref, computed, unref, onUnmounted } from 'vue'
import {
  IonLabel, IonButton, IonIcon
} from '@ionic/vue'
import {
  returnDownBackOutline,
  micCircleOutline,
  micOffCircleOutline,
  musicalNotesOutline,
  cloudUpload
} from 'ionicons/icons'
import { pipe, constFalse } from 'fp-ts/lib/function'
import { getFile } from '@/logic/patterns/fileEvents'
import { requiredType, refAssigner, refAssign } from '@/logic/patterns/vue'
import { putAudioFile } from '@/services/media.service'
import {
AudioCaptureError,
  AudioCaptureHook,
  RecordingMetadata,
  captureAudio
} from '@/services/audio.service'
import { zeroPromiseState, reuseMonitoredPromiseState } from '@/logic/patterns/async-vue-ref'
import { Possible } from '@/types/patterns'
import { isRight, Either, map, fold, fromNullable, flatten } from 'fp-ts/lib/Either'
import { getRight } from '@/logic/patterns/either'
import { sideEffect, mapPromise, ms } from '@/logic/patterns/async'
import { RecordingData } from 'capacitor-voice-recorder'
import { defined } from 'big-m/dist/types/utils'
import { bindSecondArg } from '@/logic/patterns/functions'
import PlayAudio from '@/components/component-patterns/PlayAudio.vue'
import { isBrowser } from '@/router/state'

export default defineComponent({
  components: {
    IonLabel, IonButton, IonIcon, PlayAudio
  },
  props: {
    dismiss: requiredType<() => void>(Function),
    notifyAudioUpload: requiredType<(promise: Promise<number>) => void>(Function)
  },
  setup(props) {
    async function uploadAudio(event: any) {
      pipe(
        event,
        getFile,
        putAudioFile,
        props.notifyAudioUpload
      )
    }

    const audioInput = ref<any>(null)
    onBeforeMount(
      () => audioInput.value = ""
    )

    const MAX_AUDIO_RECORDING_MS = 30 * 1000
    const audioCaptureState = zeroPromiseState<Either<
      AudioCaptureError,
      RecordingMetadata | AudioCaptureHook
    >>()


    const recordedFile = ref<Possible<File>>()
    const registerSuccessfulRecording = (output: RecordingData["value"]) => {
      pipe(
        output,
        async ({
          recordDataBase64,
          mimeType,
          msDuration
        }) => {
          const now = new Date()
          const title = `Recording ${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}, ${(msDuration / 1000).toFixed(1)} seconds`
          
          const res: Response = await fetch(`data:audio/aac;base64,${recordDataBase64}`)
          const blob: Blob = await res.blob()
          return new File(
            [
              blob
            ],
            title,
            {
              type: mimeType
            }
          )
        },
        mapPromise(
          refAssigner(recordedFile)
        )
      )
    }

    const finalizeRecording = () => {
      pipe(
        recordedFile,
        unref,
        bindSecondArg(
          defined,
          'Expected an existing recording to finalize'
        ),   
        file => sideEffect(
          putAudioFile(file),
          refAssign(recordedFile, undefined)
        ),
        props.notifyAudioUpload
      )
    }

    const endAudioRecording = () => {
      if (audioCaptureState.result && isRight(audioCaptureState.result)) {
        const rightResolution = getRight(audioCaptureState.result)

        if (typeof rightResolution === "function") {
          return reuseMonitoredPromiseState(
            audioCaptureState,
            sideEffect(
              rightResolution(),
              map(
                registerSuccessfulRecording
              )
            )
          )
        }
      }
      
      throw new Error("Cannot end audio recording because there is no audio recording taking place")
    }

    const isRecording = computed(
      () => pipe(
        audioCaptureState.result,
        fromNullable(undefined),
        flatten,
        fold(
          constFalse,
          right => typeof right === 'function'
        ),
      )
    )

    const recordingId = ref(0)
    
    const beginAudioRecording = () => {
      recordingId.value++
      reuseMonitoredPromiseState(
        audioCaptureState,
        sideEffect(
          captureAudio(),
          map(
            async () => {
              const currentRecordingId = unref(recordingId)
              await ms(MAX_AUDIO_RECORDING_MS)
              if (unref(isRecording) && currentRecordingId === unref(recordingId)) {
                return endAudioRecording()
              }
            }
          )
        )
      )
    }

    onUnmounted(
      () => unref(isRecording) && endAudioRecording()
    )

    const playAudio = ref<any>(null)
    return {
      playAudio,
      uploadAudio,
      returnDownBackOutline,
      audioInput,
      captureAudio,
      isBrowser: isBrowser(),
      finalizeRecording,
      beginAudioRecording,
      endAudioRecording,
      isRecording,
      recordedFile,
      micCircleOutline,
      micOffCircleOutline,
      musicalNotesOutline,
      cloudUpload
    }
  }
})
</script>
<style scoped>
  .select-image--return {
    clear: both;
  }

  .ion-item-image--wrapper {
    width: 100%;
  }

  ion-icon {
    color: inherit;
  }

  ion-button ion-con {
    padding-right: 0.25em;
  }

  .recorded--audio {
    display: flex;
    justify-content: center;
    padding: 0.75em 0;
    background-color: #00000008;
    margin: 0.25em 0;
  }

  .recorded--audio .recording--text {
    margin-right: 1em;
  }
</style>
<style unscoped>
  .recorded--audio .play--icon {
    transform: scale(1.5);
  }
</style>