import { lazy } from '@/logic/patterns/lazy'
import { injectWithSecondaries, provider } from '@/logic/patterns/provide'

import { some, none, Option, fold, filterMap, isSome } from 'fp-ts/lib/Option'
import 'firebase/auth'
import { flow, pipe } from 'fp-ts/lib/function'
import { Possible } from '@/types/patterns'
import { getSome } from '@/logic/patterns/option'
import { defer } from '@/logic/patterns/async'
import { Either, isLeft, left, right } from 'fp-ts/lib/Either'
import { getLeft, getRight } from '@/logic/patterns/either'
import { noop } from '@/logic/patterns/functions'
import { AppPreferences } from 'ionic-native'
import { isPlatform } from '@ionic/vue'

const kaskaFlashcardStorageKey = 'kaskaFlashcardStorageKey'

export const storageProvide = provider(
  () => {
    const storageImplementation = lazy(async () => {
      if (!isPlatform('cordova') && 'localStorage' in window) {
        return {
          async set({ key, value }: { key: string, value: string }) {
            window.localStorage.setItem(key, value)
          },
          async remove({ key }: { key: string }) {
            window.localStorage.removeItem(key)
          },
          async get({ key }: { key: string }): Promise<{ value: string | null }> {
            const value = window.localStorage.getItem(key)

            if (value === null) {
              return {
                value: null
              }
            } else {
              return {
                value
              }
            }
          }
        }
      }

      // AppPreferences is a Cordova plugin that allows for persistent storage
      // of small amounts of data.
      // Large amounts of data should be stored in IndexedDB or SQLite.
      const s = AppPreferences

      try {
        await s.store(kaskaFlashcardStorageKey, 'Test Key', 'Test Value')
        await s.fetch(kaskaFlashcardStorageKey, 'Test Key')
        await s.remove(kaskaFlashcardStorageKey, 'Test Key')

        return {
          async set({ key, value }: { key: string, value: string }) {
            await s.store(kaskaFlashcardStorageKey, key, value)
          },
          async remove({ key }: { key: string }) {
            await s.remove(kaskaFlashcardStorageKey, key)
          },
          async get({ key }: { key: string }): Promise<{ value: string | null }> {
            const value = await s.fetch(kaskaFlashcardStorageKey, key)

            if (value === null) {
              return {
                value: null
              }
            } else {
              return {
                value
              }
            }
          }
        }
      } catch (e) {
        console.log('Failed to access Storage', e)
        const cache = {} as Record<string, string>

        return {
          async set({ key, value }: { key: string, value: string }) {
            cache[key] = value
          },
          async remove({ key }: { key: string }) {
            delete cache[key]
          },
          async get({ key }: { key: string }): Promise<{ value: string | null }> {
            if (key in cache) {
              return {
                value: cache[key]
              }
            } else {
              return {
                value: null
              }
            }
          }
        }
      }
    })

    async function setJson(key: string, jsonObj: any) {
      const storage = await storageImplementation()

      return storage.set({
        key,
        value: JSON.stringify(jsonObj)
      })
    }

    async function remove(key: string) {
      const storage = await storageImplementation()

      return storage.remove({ key })
    }

    async function getJson<T>(key: string) {
      const storage = await storageImplementation()

      const { value } = await storage.get({ key })

      if (value === null) {
        return none
      } else {
        return some(JSON.parse(value) as T)
      }
    }

    return {
      getJson,
      setJson,
      remove
    }
  }
)

async function cacheValue<T>(
  writeValueToCache: (key: string, t: any) => Promise<void>,
  value: T,
  key: string,
  dateKey: string
) {
  await Promise.all([
    writeValueToCache(key, value),
    writeValueToCache(dateKey, new Date().valueOf())
  ])
}

async function retrieveValueFromCacheUnlessStale<T>(
  getValue: <T2>(key: string) => Promise<Option<T2>>,
  key: string,
  dateKey: string,
  lifespan: Possible<number>,
  expirationDate: Possible<Date>
) {
  const datePromise = getValue<number>(dateKey)
  const valuePromise = getValue<T>(key)

  const retDefer = defer<Either<Option<T>, T>>()
  const nowMs = Number(new Date())
  const isStaleByLifespan = (timestamp: number) => !(lifespan !== undefined && timestamp + lifespan > nowMs)
  const isStaleByExpirationDate = (timestamp: number) => !(expirationDate !== undefined && timestamp > Number(expirationDate))
  const isStale = (timestamp: number) => isStaleByLifespan(timestamp) || isStaleByExpirationDate(timestamp)
  datePromise.then(
    flow(
      filterMap(
        timestamp => isStale(timestamp) ? none : some(timestamp)
      ),
      fold(
        () => valuePromise.then(
          v => retDefer.resolve(
            left(v)
          )
        ),
        () => valuePromise.then(
          v => isSome(v) ? retDefer.resolve(right(getSome(v))) : retDefer.resolve(left(v))
        )
      )
    )
  )

  Promise.all([
    datePromise,
    valuePromise
  ]).catch(retDefer.reject)

  return retDefer.promise
}

export function memoizeInCache<T>(
  functionToMemoize: () => Promise<T>,
  key: string,
  {
    fallbackToCache = true,
    lifespan,
    expirationDate
  }: {
    lifespan?: number,
    expirationDate?: Date,
    fallbackToCache?: boolean,
  } = { fallbackToCache: true }
) {
  return injectWithSecondaries(storageProvide, { functionToMemoize }, async function ({
    getJson,
    setJson,
    functionToMemoize
  }) {
    const dateKey = `${key}__META__DATE-STORED`

    const retrievalResult = await retrieveValueFromCacheUnlessStale<T>(
      getJson,
      key,
      dateKey,
      lifespan,
      expirationDate
    )

    if (isLeft(retrievalResult)) {
      try {
        const generated = await functionToMemoize()
        cacheValue(
          setJson,
          generated,
          key,
          dateKey
        ).catch(noop)
        return generated
      } catch (e) {
        if (fallbackToCache) {
          const staleRetrieved = getLeft(retrievalResult)
          if (isSome(staleRetrieved)) {
            return getSome(staleRetrieved)
          }
        }

        throw e
      }
    } else {
      return pipe(
        retrievalResult,
        getRight
      )
    }
  })
}
