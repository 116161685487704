import { none, some } from "fp-ts/lib/Option"

type MediaCommon = {
  __meta__: {
    createdBy: string,
    createdDate: string,
  },
  contentType: string,
  generation: number,
  file: string,
  folderId: number,
  id: number,
}

type FileUnique = {
  type: "files",
}

type ImageUnique = {
  type: "images",
  sizes: {
    height: number,
    width: number,
    quality: string,
    uniqueKey: string,
    path: string,
  }[],
}

export type Image = MediaCommon & ImageUnique
export type File = MediaCommon & FileUnique
export type Media = Image | File
export type Audio = MediaCommon

export function asImage(media: Media) {
  if (media.type === "files") {
    throw new Error("Expected image type")
  } else {
    return media
  }
}

export function filterImage(media: Media) {
  return media.type === "files" ? none : some(
    media
  )
}
