import axios, { AxiosResponse } from 'axios'
import { Possible } from "@/types/patterns"
import { Either, isLeft, isRight, left, right } from 'fp-ts/lib/Either'
import { getRight } from './either'
import { storageProvide } from '@/services/storage.service'
import { isSome } from 'fp-ts/lib/Option'

export type AxiosRequest = {
  method: "GET" | "POST",
  url: string,
  path: string,
  queryParams?: Record<string, string>,
  body?: Possible<string>,
}

export async function executeAxiosRequest<T>({ path, url, method, body, queryParams }: AxiosRequest, token?: string): Promise<Either<number, T>> {
  let reply: AxiosResponse
  if (method === 'GET') {
    reply = await axios.get(
      `${url}${path}`,
      {
        params: queryParams,
        headers: {
          ...token === undefined ? undefined : {
            authorization: `Bearer ${token}`
          }
        }
      }
    )
  } else {
    reply = await axios.post(
      `${url}${path}`,
      body,
      {
        headers: {
          ...token === undefined ? undefined : {
            authorization: `Bearer ${token}`
          },
          'content-type': 'application/x-www-form-urlencoded'
        },
        params: queryParams
      }
    )
  }

  if (String(reply.status)[0] !== '2') {
    return left(reply.status)
  } else {
    return right(reply.data as any as T)
  }
}

const storage = storageProvide()

export function endpointWithTokenAccess(
  {
    getTokenRequest,
    getTokenFromResponse,
    tokenStoreKey,
    testTokenRequest
  }: {
    getTokenRequest: AxiosRequest,
    getTokenFromResponse: (response: unknown) => {
      token: string,
      lifespan?: number,
    },
    tokenStoreKey?: string,
    testTokenRequest?: AxiosRequest,
  }
) {
  let activeToken = undefined as Possible<string>
  let tokenGoodUntilMs = undefined as Possible<number>
  const refreshActiveToken = async () => {
    const tokenReply = await executeAxiosRequest<unknown>(getTokenRequest)
    const {
      token,
      lifespan
    } = getTokenFromResponse(
      getRight(
        tokenReply,
        l => `Failed to refresh auth token, endpoint responded with code ${l}`
      )
    )

    activeToken = token
    tokenGoodUntilMs = lifespan === undefined ? Infinity : (new Date().valueOf()) + lifespan

    if (tokenStoreKey !== undefined) {
      storage.setJson(tokenStoreKey, activeToken)
    }
  }

  (async () => {
    if (tokenStoreKey !== undefined) {
      const token = await storage.getJson<string>(tokenStoreKey)

      if (isSome(token)) {
        if (testTokenRequest) {
          const result = await executeAxiosRequest(testTokenRequest)

          if (isLeft(result)) {
            return refreshActiveToken()
          }
        }

        activeToken = token.value
        tokenGoodUntilMs = Infinity
        return
      }
    }

    return refreshActiveToken()
  })()

  return async <T>(r: AxiosRequest): Promise<T> => {
    const nowMs = Number(new Date())
    if (activeToken === undefined || (tokenGoodUntilMs !== undefined && nowMs > tokenGoodUntilMs)) {
      await refreshActiveToken()
    }

    const dataResponse = await executeAxiosRequest<T>(r, activeToken)

    if (isRight(dataResponse)) {
      return dataResponse.right
    } else if (dataResponse.left === 401) {
      await refreshActiveToken()

      const renewedDataResponse = await executeAxiosRequest<T>(r, activeToken)

      return getRight(renewedDataResponse, l => `Request failed even after token renewal, endpoint responded with code ${l}`)
    } else {
      throw new Error(`Request failed, code ${dataResponse.left}`)
    }
  }
}


